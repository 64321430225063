import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './loginActions';
import { postRequestAPI } from '../../global/api';
import { tokenkey, roleId, roleName, encryptStorage } from '../../utils/auth';
import { defaultErrorMsg } from '../../utils/conts';
import { decryptKey } from '../../utils/helpers';

/**
 * @author      Nandani.V.Patel
 * @date        09 Oct 2024
 * @description Login Saga and handling API response.
 * @param       login-user Request paylod
 * @response    user login  is success or fail.  
**/

function* loginUserSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'login', data: action.payload });
    if (resData?.status === 'success') {
      localStorage.setItem(tokenkey, resData?.access_token);
      encryptStorage.setItem(roleId, resData?.data?.user_role);
      encryptStorage.setItem(roleName, resData?.data?.user_role_name);
      yield put({ type: ActionTypes.LOGIN_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.LOGIN_FAILURE, error: resData });
    }
  } catch (err) {
    yield put({ type: ActionTypes.LOGIN_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* googleLoginSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'auth/google/callback', data: action.payload });
    if (response?.data?.status === 'success') {
      localStorage.setItem(tokenkey, response?.data?.access_token);
      yield put({ type: ActionTypes.GOOGLE_LOGIN_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GOOGLE_LOGIN_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GOOGLE_LOGIN_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* facebookLoginSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'auth/facebook/callback', data: action.payload });
    if (response?.data?.status === 'success') {
      localStorage.setItem(tokenkey, response?.data?.access_token);
      yield put({ type: ActionTypes.FACEBOOK_LOGIN_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.FACEBOOK_LOGIN_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.FACEBOOK_LOGIN_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* linkedinLoginSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'auth/linkendin/callback', data: action.payload });
    if (response?.data?.status === 'success') {
      localStorage.setItem(tokenkey, response?.data?.access_token);
      yield put({ type: ActionTypes.LINKEDIN_LOGIN_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.LINKEDIN_LOGIN_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.LINKEDIN_LOGIN_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getApiKeySaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'get-individual-key', data: action.payload });

    if (response?.data?.status === 'success') {
      const encryptedValue = response.data?.encrypted_value || "";
      // Decrypt the value
      const decryptedValue = decryptKey(encryptedValue);
      console.log("decryptedValue:", decryptedValue);
      yield put({ type: ActionTypes.GET_API_KEY_SUCCESS, data: decryptedValue });
    } else {
      yield put({ type: ActionTypes.GET_API_KEY_FAILURE, error: response.data });
    }
  } catch (err) {
    console.log("error:", err)
    yield put({ type: ActionTypes.GET_API_KEY_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchLoginRequest() {
  yield takeLatest(ActionTypes.LOGIN_REQUEST, loginUserSaga);
  yield takeLatest(ActionTypes.GOOGLE_LOGIN_REQUEST, googleLoginSaga);
  yield takeLatest(ActionTypes.FACEBOOK_LOGIN_REQUEST, facebookLoginSaga);
  yield takeLatest(ActionTypes.LINKEDIN_LOGIN_REQUEST, linkedinLoginSaga);
  yield takeLatest(ActionTypes.GET_API_KEY_REQUEST, getApiKeySaga);
}

function* signInSaga() {
  yield fork(watchLoginRequest);
}

export default signInSaga;