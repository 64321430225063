/**
 * @author      Nandani.V.Patel
 * @date        17 Oct 2024
 * @description ActionTypes and ActionCreator Function for Coampny Details Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  COMPANY_DETAILS_REQUEST: 'COMPANY_DETAILS_REQUEST',
  COMPANY_DETAILS_SUCCESS: 'COMPANY_DETAILS_SUCCESS',
  COMPANY_DETAILS_FAILURE: 'COMPANY_DETAILS_FAILURE',

  GET_USER_MAILED_SITES: 'GET_USER_MAILED_SITES',
  GET_USER_MAILED_SITES_SUCCESS: 'GET_USER_MAILED_SITES_SUCCESS',
  GET_USER_MAILED_SITES_FAILURE: 'GET_USER_MAILED_SITES_FAILURE',

  SAVE_USER_REVIEW: 'SAVE_USER_REVIEW',
  SAVE_USER_REVIEW_SUCCESS: 'SAVE_USER_REVIEW_SUCCESS',
  SAVE_USER_REVIEW_FAILURE: 'SAVE_USER_REVIEW_FAILURE',

  GENERATE_AI_REVIEW: 'GENERATE_AI_REVIEW',
  GENERATE_AI_REVIEW_SUCCESS: 'GENERATE_AI_REVIEW_SUCCESS',
  GENERATE_AI_REVIEW_FAILURE: 'GENERATE_AI_REVIEW_FAILURE',

  RESET_ERROR: 'RESET_ERROR',
};

export const companydetailsRequest = (company_id) => {
  return {
    type: ActionTypes.COMPANY_DETAILS_REQUEST,
    payload: company_id,
  };
};

export const getUserMailedSites = (payload) => {
  return {
    type: ActionTypes.GET_USER_MAILED_SITES,
    payload,
  };
}

export const saveUserReview = (payload) => {
  return {
    type: ActionTypes.SAVE_USER_REVIEW,
    payload,
  };
}

export const generateAIReview = (payload) => {
  return {
    type: ActionTypes.GENERATE_AI_REVIEW,
    payload,
  };
}