import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import TargetSitesTable from './targetSitesTable';
import { encryptStorage, roleId } from '../../utils/auth';
import { getCompanyReviewSites, getTLTargets } from './redux/actions';
import StatsBlock from '../dashboard/statsBlock';

const MyTargets = () => {
  const dispatch = useDispatch();
  const role_id = encryptStorage.getItem(roleId);

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const propState = useSelector((state) => ({
    targetList: state.teamLeaderTargets.targetList,
    targetSiteList: state.teamLeaderTargets.targetSiteList,
    loading: state.teamLeaderTargets.loading,
    siteLoad: state.teamLeaderTargets.siteLoad,
    mailSentLoad: state.teamLeaderTargets.mailSentLoad,
  }));

  // Fetch data on load and whenever pagination state changes
  useEffect(() => {
    dispatch(getTLTargets({ per_page: rowsPerPage, page: currentPage }));
  }, [dispatch, rowsPerPage, currentPage]);

  // custom style for order table
  const customStyles = {
    headCells: {
      style: {
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        fontSize: '18px',
        fontWeight: '600',
        paddingTop: '15px',
        paddingBottom: '15px',
      }
    },
    cells: {
      style: {
        verticalAlign: "middle",
        fontWeight: "500",
        fontSize: "16px",
        paddingTop: '10px',
        paddingBottom: '10px',
      }
    }
  };

  // company details listing table columns
  const companyColumns = [
    {
      id: 'col1',
      name: 'Company Name',
      selector: (row) => row?.company_name,
      sortable: true
    },
    {
      id: 'col2',
      name: 'Total Assigned Reviews',
      selector: (row) => row?.total_assigned_reviews || 0,
      cell: (row) => row?.total_assigned_reviews || 0,
      sortable: true
    },
    // {
    //   id: 'col3',
    //   name: 'Pending Reviews',
    //   selector: (row) => row?.total_pending_reviews || 0,
    //   cell: (row) => row?.total_pending_reviews || 0,
    //   sortable: true
    // },
    // {
    //   id: 'col3',
    //   name: 'Status',
    //   selector: (row) => row?.target_status || "",
    //   cell: (row) => (
    //     <div className="btn-area">
    //       <span
    //         className="status-btn"
    //         data-review-status={
    //           row?.target_status?.toLowerCase() === "completed"
    //             ? "approved"
    //             : row?.target_status?.toLowerCase()
    //         }
    //       >
    //         {row?.target_status}
    //       </span>
    //     </div>
    //   ),
    //   sortable: true,
    //   right: "yes",
    //   maxWidth: "200px"
    // },
  ];

  // get purchased sites for each company row which is expanded
  const getPurchasedSites = (expanded, row) => {
    if (expanded) {
      dispatch(getCompanyReviewSites({ company_id: row?.company_id, campaign_id: row?.review_campaign_tbl_id }));
    }
  };

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  return (
    <div>
      <div className="stats-fun-fact-box-wrapper">
        <div className="row justify-content-between">
          <StatsBlock
            icon="fa fa-bullseye"
            label="Total Assigned Targets"
            count={propState?.empTargets?.total_assigned_targets || 0}
            additionalClass='stats-box-blue'
          />
          <StatsBlock
            icon="fa-regular fa-thumbs-up"
            label="Completed Targets"
            count={propState?.empTargets?.total_completed_targets || 0}
            additionalClass='stats-box-green'
          />
          <StatsBlock
            icon="fa fa-chart-line"
            label="Overall Completion Rate"
            count={`${propState?.empTargets?.overall_completion_rate || 0}%`}
            additionalClass='stats-box-purple'
          />
        </div>
      </div>
      <div className={`p-3 mb-4 header-box`}>
        <h4 className="header-text">My Targets</h4>
      </div>
      <DataTable
        columns={companyColumns}
        data={propState.targetList?.data || []}
        customStyles={customStyles}
        defaultSortFieldId="col4"
        defaultSortAsc={false}
        fixedHeader
        expandableRows
        expandOnRowClicked
        onRowExpandToggled={getPurchasedSites}
        expandableRowsComponent={({ data }) => (
          <TargetSitesTable
            data={propState.targetSiteList?.data?.[data?.company_id] || []}
            propState={propState}
            role_id={role_id}
          />
        )}
        responsive
        pagination
        paginationServer
        paginationTotalRows={propState.targetList?.total || 0}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
    </div>
  )
}

export default MyTargets;