export const ActionTypes = {
  VERIFY_OTP_REQUEST: 'VERIFY_OTP_REQUEST',
  VERIFY_OTP_SUCCESS: 'VERIFY_OTP_SUCCESS',
  VERIFY_OTP_FAILURE: 'VERIFY_OTP_FAILURE',

  RESEND_OTP_REQUEST: 'RESEND_OTP_REQUEST',
  RESEND_OTP_SUCCESS: 'RESEND_OTP_SUCCESS',
  RESEND_OTP_FAILURE: 'RESEND_OTP_FAILURE',

  OTP_VERIFY_RESET: 'OTP_VERIFY_RESET',
};

export const verifyOtpRequest = (payload) => {
  return {
    type: ActionTypes.VERIFY_OTP_REQUEST,
    payload,
  };
};

export const resendOtpRequest = (payload) => {
  return {
    type: ActionTypes.RESEND_OTP_REQUEST,
    payload,
  };
};