import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AGREEMENTPAGE, FAQ_PAGE, HOME } from '../../global/routes';
import Preloader from '../Preloader';
import { fetchCmsPagesRequest } from './footerActions';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Dashboard Footer
 * @param
 * @response 
**/

const Footer = () => {
  const dispatch = useDispatch();
  const { cmsPages, isFetching } = useSelector((state) => state.footer);

  useEffect(() => {
    dispatch(fetchCmsPagesRequest()); // Fetch CMS pages from API on mount
  }, [dispatch]);

  return (
    <>
      {isFetching && <Preloader />}
      <div className="footer-area bg-color pt-100 pb-70">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="single-footer-widget logo-content">
                <div className="footer-logo">
                  <Link to={HOME}>
                    <img src="/assets/images/white-logo.png" alt="footer-logo" />
                  </Link>
                </div>
                <p>
                  Lorem ipsum dolor sit amet, consec tetur adipiscing elit eiusmod
                  tempor incididunt labore dolore magna aliqua consec tetur
                  adipiscing elite sed do labor.
                </p>
                <div className="social-content">
                  <ul>
                    <li>
                      <span>Follow Us:</span>
                    </li>
                    <li>
                      <Link to="https://www.facebook.com/" target="_blank">
                        <i className="fa-brands fa-facebook-f" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://www.twitter.com/" target="_blank">
                        <i className="fa-brands fa-twitter" />
                      </Link>
                    </li>
                    <li>
                      <Link to="https://instagram.com/?lang=en" target="_blank">
                        <i className="fa-brands fa-instagram" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-sm-6">
              <div className="single-footer-widget quick-link">
                <h3>Markfeeds</h3>
                <ul>
                  {/* <li>
                    <Link to={WORKFLOW}>How it Works</Link>
                  </li>
                  <li>
                    <Link to="">Contact Us</Link>
                  </li>
                  <li>
                    <Link to={TERMSCONDITIONS}>Terms &amp; Conditions</Link>
                  </li> */}
                  {cmsPages
                    ?.filter((cmsPage) => !cmsPage?.role_id)
                    ?.map((cmsPage) => (
                      <li key={cmsPage.id}>
                        <Link to={`/page/${cmsPage.slug}`}>
                          {cmsPage.title}
                        </Link>
                      </li>
                    )) || <></>}
                  <li>
                    <Link to={FAQ_PAGE}>FAQs</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget quick-link">
                <h3>Policies</h3>
                <ul>
                  {cmsPages
                    ?.filter((cmsPage) => cmsPage?.role_id)
                    ?.map((cmsPage) => (
                      <li key={cmsPage.id}>
                        <Link to={`${AGREEMENTPAGE}/${cmsPage.role_id}`}>
                          {cmsPage.title}
                        </Link>
                      </li>
                    )) || <></>}
                </ul>
              </div>
            </div>

            <div className="col-lg-3 col-sm-6">
              <div className="single-footer-widget info">
                <h3>Official Info</h3>
                <ul>
                  <li>
                    <i className="fa-solid fa-location-dot" />
                    <h4>Location:</h4>
                    <span>2976 sunrise road las vegas</span>
                  </li>
                  <li>
                    <i className="fa-solid fa-envelope" />
                    <h4>Email:</h4>
                    <Link to="mailto:support@markfeeds.com">
                      <span
                        className="__cf_email__"
                        data-cfemail="264e434a4a49664c4744470845494b"
                      >
                        support@markfeeds.com
                      </span>
                    </Link>
                  </li>
                  <li>
                    <i className="fa-solid fa-phone" />
                    <h4>Phone:</h4>
                    <Link to="tel:098765432150">098765432150</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copy-right">
        <div className="container">
          <p>
            Copyright all Rights Reserved ©
            <Link to="" target="_blank">
              {" Mark Feeds"}
            </Link>
          </p>
        </div>
      </div>
      <div className="go-top">
        <i className="fa-solid fa-arrow-up-long" />
        <i className="fa-solid fa-arrow-up-long" />
      </div>
    </>
  )
}
export default Footer;
