import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { PURCHASE_REVIEWS } from "../../../global/routes";
import { ActionTypes, calcCheckoutPrice, createOrderRequest, resetMessage } from "../redux/actions";
import Breadcrumb from '../../../component/breadcrumbs';
import { loadRazorpayScript } from "../../../utils/helpers";
import { notification } from "../../../component/notificationContainer/notification";
import { NotificationContainer } from "../../../component/notificationContainer";

const CheckoutPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state: purchasedDetails } = useLocation();
  // const purchasedDetails = location.state || [];

  const checkoutPrices = useSelector(({ companyPanel }) => companyPanel?.checkoutPrices || {});
  const propState = useSelector(({ companyPanel }) => ({
    orderLoader: companyPanel?.loading || false,
    order: companyPanel?.order,
    verification: companyPanel?.verification || "",
    errorMsg: companyPanel?.errorMsg || "",
  }));

  const breadcrumbs = [
    { name: 'Purchase Reviews', link: PURCHASE_REVIEWS },
    { name: 'Checkout', link: "" },
  ];

  useEffect(() => {
    if (purchasedDetails?.length) {
      dispatch(calcCheckoutPrice(purchasedDetails));
    }
  }, [dispatch, purchasedDetails]);

  useEffect(() => {
    if (!purchasedDetails?.length && !checkoutPrices) {
      navigate(PURCHASE_REVIEWS, { replace: true });
    }
  }, [checkoutPrices, navigate, purchasedDetails?.length]);

  useEffect(() => {
    return () => {
      // clear checkout price data
      dispatch({ type: ActionTypes.CALC_CHECKOUT_PRICE_SUCCESS, payload: "" })
    }
  }, []);

  const handlePayment = async () => {
    try {
      const isScriptLoaded = await loadRazorpayScript();

      if (!isScriptLoaded) {
        notification("Failed to load Razorpay SDK. Please check your internet connection.", "error");
        return;
      }

      // Create order
      dispatch(createOrderRequest({
        amount: checkoutPrices.discounted_price,
        currency_code: checkoutPrices?.country?.currency_code || "",
        currency_id : Number(checkoutPrices?.country?.currency_id) || "",
        companyData: checkoutPrices.company,
        purchasedDetails
      }));

      // The saga will handle Razorpay modal on successful order creation
    } catch (error) {
      notification("Something went wrong. Please try again.", "error");
    }
  };

  useEffect(() => {
    if (propState.errorMsg) {
      notification(propState.errorMsg, 'error');

      dispatch(resetMessage());
    }
    if (propState.verification?.status === 'success') {
      navigate(PURCHASE_REVIEWS, { replace: true });
    }
  }, [propState.verification, propState.errorMsg]);

  return (
    <div>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`p-3 mb-4 mt-2 header-box`}>
        <h4 className="header-text">Checkout</h4>
      </div>
      <div className="card shadow checkout-card">
        <div className="card-body">
          <div className="d-flex justify-content-between mb-2">
            <span>Subtotal Price</span>
            <span>
              {checkoutPrices?.country?.currency_symbol} {(checkoutPrices.total_original_price || 0)?.toFixed(2)}
            </span>
          </div>
          <div className="d-flex justify-content-between mb-2">
            <span>Discount</span>
            <span>{(checkoutPrices.discount_percentage || 0)?.toFixed(2)} %</span>
          </div>
          <hr />
          <div className="d-flex justify-content-between font-weight-bold">
            <span>Total Price</span>
            <span>
              {checkoutPrices?.country?.currency_symbol} {(checkoutPrices.discounted_price || 0)?.toFixed(2)}
            </span>
          </div>
        </div>
      </div>
      <button
        className="blue-btn rounded mt-4 fw-medium"
        onClick={handlePayment}
        disabled={propState.orderLoader}
      >
        {!propState.orderLoader ? (
          "Pay Now"
        ) : (
          <div className="spinner-border spinner-border-sm"></div>
        )}
      </button>
      <NotificationContainer />
    </div>
  )
}

export default CheckoutPage;