import { put, takeLatest, call, fork } from 'redux-saga/effects';
import { ActionTypes, verifyPaymentRequest } from './actions';
import { getRequestAPI, postFileRequestAPI, postRequestAPI } from '../../../global/api';
import { defaultErrorMsg } from '../../../utils/conts';
import { notification } from '../../../component/notificationContainer/notification';

function* getLatestOrderReq(action) {
  try {
    const { data: resData } = yield call(getRequestAPI, { url: "company/latest-order" });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_LATEST_ORDER_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_LATEST_ORDER_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_LATEST_ORDER_FAILURE, error: defaultErrorMsg });
  }
}

function* getReviewSitesReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: "company/per-review-listing", data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_REVIEW_SITES_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_REVIEW_SITES_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_REVIEW_SITES_FAILURE, error: defaultErrorMsg });
  }
}

// calculate total & discounted price
function* calcCheckoutPriceReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'company/calculate-price', data: action.payload });

    if (resData?.success) {
      yield put({ type: ActionTypes.CALC_CHECKOUT_PRICE_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.CALC_CHECKOUT_PRICE_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.CALC_CHECKOUT_PRICE_FAILURE, error: defaultErrorMsg });

  }
}

// get all transaction history of company
function* getAllTransactionHistoryReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'company/get-transaction-history', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_ALL_TRANSACTION_HISTORY_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_ALL_TRANSACTION_HISTORY_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ALL_TRANSACTION_HISTORY_FAILURE, error: defaultErrorMsg });
  }
}

function* getOrdersReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'company/get-order-history', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_ORDERS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_ORDERS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_ORDERS_FAILURE, error: defaultErrorMsg });
  }
}

function* getSitesByOrderReq(action) {
  try {
    const { data: resData } = yield call(getRequestAPI, {
      url: `company/get-order-details/${action.payload}`
    });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_SITES_BY_ORDER_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_SITES_BY_ORDER_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SITES_BY_ORDER_FAILURE, error: defaultErrorMsg });
  }
}

function* getPurchasedSiteUserReviewsReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, {
      url: `company/order-userreview-history`,
      data: action.payload
    });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS_FAILURE, error: defaultErrorMsg });
  }
}

function* UpdateCompanyProfileSaga(action) {
  try {
    const { data: resData } = yield call(postFileRequestAPI, {
      url: 'company/update-profile',
      data: action.payload
    });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.UPDATE_COMPANY_PROFILE_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.UPDATE_COMPANY_PROFILE_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.UPDATE_COMPANY_PROFILE_FAILURE, error: defaultErrorMsg });
  }
}

function* getCompanyDetailsSaga() {
  try {
    const { data: resData } = yield call(getRequestAPI, { url: 'company-profile' });

    if (resData.status === 'success') {
      yield put({ type: ActionTypes.GET_COMPANY_DETAILS_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.GET_COMPANY_DETAILS_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_COMPANY_DETAILS_FAILURE, error: defaultErrorMsg });
  }
}

function* paymentOrderReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'company/order', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.PAYMENT_ORDER_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.PAYMENT_ORDER_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.PAYMENT_ORDER_FAILURE, error: defaultErrorMsg });
  }
}

// RazorPay
// create order
function* createOrderSaga(action) {
  try {
    const { amount, currency_code, currency_id, purchasedDetails, companyData={} } = action.payload || {};

    const { data: resData } = yield call(postRequestAPI, {
      url: 'company/payment/order',
      data: { amount, currency_code }
    });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.CREATE_ORDER_SUCCESS, payload: resData });

      const paymentPromise = () =>
        new Promise((resolve, reject) => {
          const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY,
            amount: resData?.data.amount, // Amount in paisa
            currency: resData?.data.currency,
            name: "MarkFeeds",
            order_id: resData?.data.id, // Razorpay Order ID
            prefill: {
              name: companyData?.company_tbl_name || "",
              contact: companyData?.company_tbl_mob_no || "",
              email: companyData?.company_contact_email || ""
            },
            theme: { color: "#3399cc" },
            handler: function (response) {
              // Resolve the promise with the handler response
              resolve(response);
            },
            modal: {
              // If user closes the Razorpay modal
              ondismiss: function () {
                // reject(new Error("Payment popup closed by user"));
                notification("Payment popup closed by user", "error");
              },
            },
          };

          const razorpay = new window.Razorpay(options);
          razorpay.open();
        });

      // Wait for the payment response from Razorpay
      const paymentResponse = yield call(paymentPromise);

      // Dispatch verification action with payment response
      const paymentData = {
        razorpay_payment_id: paymentResponse.razorpay_payment_id,
        razorpay_order_id: paymentResponse.razorpay_order_id,
        razorpay_signature: paymentResponse.razorpay_signature,
        preReviewPriceData: purchasedDetails,
        total_discounted_price: amount,
        currency_id
      };
      yield put(verifyPaymentRequest(paymentData)); // Triggers verification saga

    } else {
      yield put({ type: ActionTypes.CREATE_ORDER_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_ORDER_FAILURE, error: defaultErrorMsg });
  }
}

// verify payment
function* verifyPaymentSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, {
      url: 'company/payment/verify',
      data: action.payload,
    });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.VERIFY_PAYMENT_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.VERIFY_PAYMENT_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.VERIFY_PAYMENT_FAILURE, error: { message: 'Payment verification failed!' } });
  }
}

// AI prompts
function* getCompanyPurchasedSitesReq(action) {
  try {
    const { data: resData } = yield call(getRequestAPI, { url: 'company/campaingn-sites', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_COMPANY_PURCHASED_SITES_SUCCESS, data: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_COMPANY_PURCHASED_SITES_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_COMPANY_PURCHASED_SITES_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* storeCampaignPromptsReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'company/store-ai-prompt', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.STORE_CAMPAIGN_PROMPTS_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.STORE_CAMPAIGN_PROMPTS_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.STORE_CAMPAIGN_PROMPTS_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getCampaignPromptsReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'company/get-ai-prompt', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_CAMPAIGN_PROMPTS_SUCCESS, data: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_CAMPAIGN_PROMPTS_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_CAMPAIGN_PROMPTS_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchCompanyPanelSaga() {
  yield takeLatest(ActionTypes.GET_LATEST_ORDER, getLatestOrderReq);
  yield takeLatest(ActionTypes.GET_REVIEW_SITES, getReviewSitesReq);
  yield takeLatest(ActionTypes.CALC_CHECKOUT_PRICE, calcCheckoutPriceReq);
  yield takeLatest(ActionTypes.GET_ALL_TRANSACTION_HISTORY, getAllTransactionHistoryReq);
  yield takeLatest(ActionTypes.GET_ORDERS, getOrdersReq);
  yield takeLatest(ActionTypes.GET_SITES_BY_ORDER, getSitesByOrderReq);
  yield takeLatest(ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS, getPurchasedSiteUserReviewsReq);
  yield takeLatest(ActionTypes.UPDATE_COMPANY_PROFILE_REQUEST, UpdateCompanyProfileSaga);
  yield takeLatest(ActionTypes.GET_COMPANY_DETAILS_REQUEST, getCompanyDetailsSaga);
  yield takeLatest(ActionTypes.PAYMENT_ORDER, paymentOrderReq);
  yield takeLatest(ActionTypes.CREATE_ORDER_REQUEST, createOrderSaga);
  yield takeLatest(ActionTypes.VERIFY_PAYMENT_REQUEST, verifyPaymentSaga);
  yield takeLatest(ActionTypes.GET_COMPANY_PURCHASED_SITES, getCompanyPurchasedSitesReq);
  yield takeLatest(ActionTypes.STORE_CAMPAIGN_PROMPTS, storeCampaignPromptsReq);
  yield takeLatest(ActionTypes.GET_CAMPAIGN_PROMPTS, getCampaignPromptsReq);
}

function* CompanyPanelSaga() {
  yield fork(watchCompanyPanelSaga);
}

export default CompanyPanelSaga;