import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { NotificationContainer } from "../../component/notificationContainer";
import { notification } from "../../component/notificationContainer/notification";
import { SIGNIN } from "../../global/routes";
import { ActionTypes, resendOtpRequest, verifyOtpRequest } from "./redux/actions";
import { encryptStorage, identifier } from "../../utils/auth";

const OTPVerification = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { handleSubmit, control, reset } = useForm();
  const otpLength = 6;
  const inputRefs = useRef([]);

  const propState = useSelector(({ otpVerify }) => ({
    data: otpVerify?.data,
    isUserVerified: otpVerify?.isUserVerified,
    loading: otpVerify.loading,
    isResent: otpVerify?.isResent,
    errorMsg: otpVerify?.error?.message || ""
  }));

  const handleFocus = (index, direction) => {
    const targetIndex = index + direction;
    if (targetIndex >= 0 && targetIndex < otpLength) {
      inputRefs.current[targetIndex].focus();
    }
  };

  // const handlePaste = (e) => {
  //   console.log(e.clipboardData.getData('text'));
  //   const pasteData = e.clipboardData.getData('text').slice(0, otpLength);
  //   console.log("pasteData:", pasteData);

  //   pasteData.split('').forEach((char, idx) => {
  //     if (inputRefs.current[idx]) {
  //       inputRefs.current[idx].value = char;
  //       inputRefs.current[idx].dispatchEvent(new Event('input', { bubbles: true }));
  //     }
  //   });
  //   e.preventDefault();
  // };

  const onSubmit = (data) => {
    const otp = Object.values(data).join('');
    if (otp.length !== otpLength) {
      notification("Please enter a valid OTP", 'warning');
    } else {
      dispatch(
        verifyOtpRequest({
          identifier: encryptStorage?.getItem(identifier) || "",
          otp
        }
      ));
    }
  };

  const handleResend = () => {
    reset();

    dispatch(
      resendOtpRequest({
        identifier: encryptStorage?.getItem(identifier) || "",
      })
    );
  };

  useEffect(() => {
    if (propState?.data?.status === 'success') {
      notification(propState.data.message, 'success');
      // if OTP is verified, redirect to login page
      propState?.isUserVerified && navigate(SIGNIN);
    } else if (propState.errorMsg) {
      notification(propState.errorMsg, 'error');
    }

    setTimeout(() => {
      dispatch({ type: ActionTypes.OTP_VERIFY_RESET });
    }, 5000);
  }, [propState.errorMsg, propState?.data, propState?.isUserVerified]);

  useEffect(() => {
    return () => {
      dispatch({ type: ActionTypes.OTP_VERIFY_RESET });
    }
  }, []);

  return (
    <div className="">
      <div className="container otp-container text-center ptb-50">
        <p className="mb-4">Enter the {otpLength}-digit code sent to your WhatsApp or Email</p>
        <div className="form-group d-flex justify-content-center gap-2 mb-3">
          {Array.from({ length: otpLength }).map((_, index) => (
            <Controller
              key={index}
              name={`digit${index}`}
              control={control}
              render={({ field }) => (
                <input
                  {...field}
                  ref={(el) => (inputRefs.current[index] = el)}
                  type="text"
                  className="otp-input form-control text-center fw-bold"
                  style={{ width: '50px' }}
                  maxLength={1}
                  autoComplete="off"
                  onChange={(e) => {
                    const value = e.target.value;
                    if (/^\d?$/.test(value)) {
                      field.onChange(value);
                      if (value) {
                        handleFocus(index, 1);
                      }
                    }
                  }}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace' && !field.value) {
                      handleFocus(index, -1);
                    }
                  }}
                // onPaste={handlePaste}
                />
              )}
            />
          ))}
        </div>
        <button
          type="submit"
          className="default-btn btn"
          onClick={handleSubmit(onSubmit)}
          disabled={propState?.loading}
        >
          {!propState?.loading
            ? "Verify"
            : <div className="spinner-border spinner-border-sm" />
          }
        </button>
        <p className="mt-3">
          Didn't receive the code?{" "}
          <span
            onClick={handleResend}
            className={`resend-link text-decoration-underline ${propState?.isResent ? "disabled" : ""}`}
          >
            Resend{propState?.isResent && <div className="spinner-border spinner-border-sm ms-1"></div>}
          </span>
        </p>
      </div>
      <NotificationContainer />
    </div>
  );
}

export default OTPVerification;