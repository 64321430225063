import { ActionTypes } from "./footerActions";

const defaultState = {
  cmsPages: [],
  isFetching: false,
  data: {},
  isPageLoaded: false,
  faqList: {},
  faqsLoader: false,
  error: null,
};

const footerReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.FETCH_CMS_PAGES_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ActionTypes.FETCH_CMS_PAGES_SUCCESS:
      return {
        ...state,
        cmsPages: action.data,
        isFetching: false,
      };
    case ActionTypes.FETCH_CMS_PAGES_FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case ActionTypes.GET_PAGE_BY_SLUG_REQUEST:
      return {
        ...state,
        isPageLoaded: true,
      };
    case ActionTypes.GET_PAGE_BY_SLUG_SUCCESS:
      return {
        ...state,
        data: action.data,
        isPageLoaded: false,
      };
    case ActionTypes.GET_PAGE_BY_SLUG_FAILURE:
      return {
        ...state,
        isPageLoaded: false,
        error: action.error,
      };
    case ActionTypes.FETCH_FAQS_REQUEST:
      return {
        ...state,
        faqsLoader: true,
      };
    case ActionTypes.FETCH_FAQS_SUCCESS:
      return {
        ...state,
        faqList: action.data,
        faqsLoader: false,
      };
    case ActionTypes.FETCH_FAQS_FAILURE:
      return {
        ...state,
        faqsLoader: false,
        error: action.error,
      };
    default:
      return state;
  }
};

export default footerReducer;