import React, { useState } from 'react'
import { useDispatch } from 'react-redux';
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import { ASSIGNTARGET_SUBTL } from '../../global/routes';
import { sendMailToUsers } from './redux/actions';

const TargetSitesTable = ({ data, propState, role_id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // State for pagination
  const [sitePage, setSitePage] = useState(1);
  const [sitesPerPage, setSitesPerPage] = useState(3);

  // custom style for review site list
  const siteTableStyles = {
    table: {
      style: {
        padding: '0 30px',
      }
    },
    headRow: {
      style: {
        borderLeft: "solid 1px rgba(0,0,0,.12)",
        borderRight: "solid 1px rgba(0,0,0,.12)",
        fontWeight: "500",
        fontSize: "14px"
      }
    },
    rows: {
      style: {
        borderLeft: "solid 1px rgba(0,0,0,.12)",
        borderRight: "solid 1px rgba(0,0,0,.12)",
        borderBottom: "solid 1px rgba(0,0,0,.12)",
        fontWeight: "400",
        fontSize: "14px",
        // paddingTop: "5px",
        // paddingBottom: "5px"
      }
    },
    pagination: {
      style: {
        padding: "0 30px",
        borderBottom: "solid 1px rgba(0,0,0,.12)",
        borderTop: "none"
      }
    }
  };

  const assignTarget = (row) => {
    const details = {
      review_campaign_id: row?.review_campaign_id,
      total_pending_reviews: row?.total_pending_reviews || 0,
      total_assign_reviews: row?.total_assign_reviews || 0,
    };

    navigate(ASSIGNTARGET_SUBTL, { state: details });
  };

  // Pagination event handlers
  const handlePageChange = (page) => setSitePage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setSitesPerPage(newRowsPerPage);
    setSitePage(1); // Reset to first page when rows per page changes
  };

  // company purchase based review site listing table columns
  const siteColumns = [
    {
      name: 'Review Site',
      selector: (row) => row?.review_site_name || "-",
      cell: (row) => (
        <>
          {/* <img
            src={row?.review_site_data?.site_logo || ""}
            alt={row?.review_site_data?.site_name}
            width="50px"
            height="50px"
          /> */}
          <span className='ps-3'>{row?.review_site_name || "-"}</span>
        </>
      ),
      sortable: true
    },
    {
      name: 'Assigned Reviews',
      selector: (row) => row?.total_assign_reviews || 0,
      sortable: true
    },
    {
      name: 'Pending Count',
      selector: (row) => row?.total_pending_reviews || 0,
      sortable: true
    },
    {
      name: 'Completion rate',
      selector: (row) => row?.remaining_reviews_count || 0,
      cell: (row) => `${row?.completion_rate || 0}%`,
      sortable: true
    },
    {
      id: 'col3',
      name: 'Status',
      selector: (row) => row?.status || "",
      cell: (row) => {
        return (
          <div className="btn-area">
            <span
              className="status-btn"
              data-review-status={row?.is_pending === 0 ? "approved" : "pending"}
            >
              {row?.is_pending === 0 ? "Completed" : "Pending"}
            </span>
          </div>
        )
      },
      sortable: true,
      maxWidth: "150px"
    },
    {
      name: 'Assigned Date',
      selector: (row) => row.assign_date,
      cell: (row) => new Date(row.assign_date).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      }),
      sortable: true
    },
    {
      name: 'Action',
      cell: (row) => (
        <div className="action-btn option-list d-flex gap-2">
          {role_id === 2 && (
            <button
              className="option-btn purple-action-btn d-inline-block"
              type="button"
              title='Assign Target'
              onClick={() => assignTarget(row)}
            >
              <i className="fa-solid fa-bullseye"></i>
            </button>
          )}
          <button
            className="option-btn blue-action-btn d-inline-block"
            type="button"
            title='Send Email to Users'
            onClick={() => dispatch(sendMailToUsers({ review_campaign_id: row?.review_campaign_id }))}
          >
            <i className="fa-solid fa-paper-plane"></i>
          </button>
        </div>
      ),
      width: '100px',
      right: true
    }
  ];

  return (
    <DataTable
      columns={siteColumns}
      data={data}
      customStyles={siteTableStyles}
      progressPending={propState.siteLoad}
      responsive
      pagination
      paginationServer
      paginationPerPage={sitesPerPage}
      paginationTotalRows={propState.targetSiteList?.total || 0}
      paginationRowsPerPageOptions={[3, 5, 10, 15]}
      paginationComponentOptions={{
        rowsPerPageText: "Orders per page:",
        style: {
          padding: '0 30px',
        }
      }}
      onChangePage={handlePageChange}
      onChangeRowsPerPage={handleRowsPerPageChange}
    />
  )
}

export default TargetSitesTable;