import { ActionTypes } from './companyDetailsActions';

/**
 * @author      Nandani.V.Patel
 * @date        17 Oct 2024
 * @description company details Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store company details api response  
**/

const defaultState = {
  isCompanyDetails: false,
  mailedSites: {},
  siteListLoader: false,
  aiReview: "",
  aiReviewSiteId: "",
  aiLoader: false,
  loading: false,
  userReview: "",
  isReviewSaved: false,
  error: "",
  aiReviewError: ""
};

const comapnyDetailsReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.COMPANY_DETAILS_REQUEST:
      return {
        ...state,
        isCompanyDetails: true,
      };
    case ActionTypes.COMPANY_DETAILS_SUCCESS:
      return {
        ...state,
        data: action.data,
        isCompanyDetails: false,
      };
    case ActionTypes.COMPANY_DETAILS_FAILURE:
      return {
        ...state,
        isCompanyDetails: false,
        error: action.error,
      };
    case ActionTypes.GET_USER_MAILED_SITES:
      return {
        ...state,
        siteListLoader: true,
      }
    case ActionTypes.GET_USER_MAILED_SITES_SUCCESS:
      return {
        ...state,
        mailedSites: action.data,
        siteListLoader: false,
      }
    case ActionTypes.GET_USER_MAILED_SITES_FAILURE:
      return {
        ...state,
        siteListLoader: false,
        error: action.error,
      }
    case ActionTypes.SAVE_USER_REVIEW:
      return {
        ...state,
        isReviewSaved: true
      }
    case ActionTypes.SAVE_USER_REVIEW_SUCCESS:
      return {
        ...state,
        userReview: action.data,
        isReviewSaved: false,
      }
    case ActionTypes.SAVE_USER_REVIEW_FAILURE:
      return {
        ...state,
        isReviewSaved: false,
        error: action.error,
      }
    case ActionTypes.GENERATE_AI_REVIEW:
      return {
        ...state,
        aiLoader: {
          ...state.aiLoader,
          [action.payload.site_id]: true
        },
        aiReviewSiteId: action.payload.site_id,
        aiReviewError: ""
      }
    case ActionTypes.GENERATE_AI_REVIEW_SUCCESS:
      return {
        ...state,
        aiReview: action.data,
        aiLoader: {
          ...state.aiLoader,
          [state.aiReviewSiteId]: false
        },
        aiReviewError: ""
      }
    case ActionTypes.GENERATE_AI_REVIEW_FAILURE:
      return {
        ...state,
        aiLoader: {
          ...state.aiLoader,
          [state.aiReviewSiteId]: false
        },
        aiReviewError: action.error,
      }
    case ActionTypes.RESET_ERROR:
      return {
        ...state,
        error: "",
        aiReviewError: "",
      }
    default:
      return state;
  }
}

export default comapnyDetailsReducer;