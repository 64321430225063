import { ActionTypes } from "./actions";

const defaultState = {
  subTlList: {},
  subTlListArr: [],
  loading: false,
  currencies: [],
  currencyLoad: false,
  commissionData: "",
  setAmtLoader: false,
  siteCurrencyAmount: [],
  getAmtLoader: false,
  successMsg: "",
  errorMsg: "",
};

const subTLReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.CREATE_SUB_TL:
    case ActionTypes.GET_SUB_TL:
      return {
        ...state,
        loading: true,
      };
    case ActionTypes.CREATE_SUB_TL_FAILURE:
    case ActionTypes.GET_SUB_TL_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.error,
      }
    case ActionTypes.CREATE_SUB_TL_SUCCESS:
      return {
        ...state,
        successMsg: action.payload.message,
        loading: false,
      };
    case ActionTypes.GET_SUB_TL_SUCCESS:
      return {
        ...state,
        subTlList: action.payload,
        subTlListArr: [...state.subTlListArr, ...action.payload.data],
        loading: false,
      };
    case ActionTypes.GET_CURRENCIES:
      return {
        ...state,
        currencyLoad: true,
      };
    case ActionTypes.GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        currencies: action.payload,
        currencyLoad: false,
      };
    case ActionTypes.GET_CURRENCIES_FAILURE:
      return {
        ...state,
        currencyLoad: false,
        errorMsg: action.error,
      };
    case ActionTypes.SET_SUBTL_COMMISSION:
      return {
        ...state,
        setAmtLoader: true
      }
    case ActionTypes.SET_SUBTL_COMMISSION_SUCCESS:
      return {
        ...state,
        commissionData: action.payload,
        successMsg: action.payload.message,
        setAmtLoader: false,
      };
    case ActionTypes.SET_SUBTL_COMMISSION_FAILURE:
      return {
        ...state,
        setAmtLoader: false,
        errorMsg: action.error
      };
    case ActionTypes.GET_SITE_CURRENCY_AMOUNT:
      return {
        ...state,
        getAmtLoader: true
      };
    case ActionTypes.GET_SITE_CURRENCY_AMOUNT_SUCCESS:
      return {
        ...state,
        siteCurrencyAmount: action.payload,
        getAmtLoader: false,
      };
    case ActionTypes.GET_SITE_CURRENCY_AMOUNT_FAILURE:
      return {
        ...state,
        getAmtLoader: false,
        errorMsg: action.error
      }
    case ActionTypes.CLEAR_STATE:
      return defaultState;
    default:
      return state;
  }
}

export default subTLReducer;