import { call, fork, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import { postRequestAPI } from "../../../global/api";
import { defaultErrorMsg } from "../../../utils/conts";

function* getEmpDashboardCountReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'employee/dashboard-count', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.EMP_DASHBOARD_COUNT_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.EMP_DASHBOARD_COUNT_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.EMP_DASHBOARD_COUNT_FAILURE, error: defaultErrorMsg });
  }
}

function* getEmpCompanyReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'employee/companies', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_EMP_COMPANIES_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_EMP_COMPANIES_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMP_COMPANIES_FAILURE, error: defaultErrorMsg });
  }
}

function* updateCompanyStatusReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: '', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.UPDATE_COMPANY_STATUS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.UPDATE_COMPANY_STATUS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.UPDATE_COMPANY_STATUS_FAILURE, error: defaultErrorMsg });
  }
}

function* assignCompanyTargetsReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'employee/store-company-target', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.ASSIGN_COMPANY_TARGETS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.ASSIGN_COMPANY_TARGETS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.ASSIGN_COMPANY_TARGETS_FAILURE, error: defaultErrorMsg });
  }
}

function* getCompanyTargetHistoryReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'employee/company-target', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_COMPANY_TARGET_HISTORY_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_COMPANY_TARGET_HISTORY_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPANY_TARGET_HISTORY_FAILURE, error: defaultErrorMsg });
  }
}

function* getEmpTargetHistoryReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'employee/target', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_EMP_TARGET_HISTORY_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_EMP_TARGET_HISTORY_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMP_TARGET_HISTORY_FAILURE, error: defaultErrorMsg });
  }
}

function* getEmpEarningsReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'employee/revenue', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_EMP_EARNINGS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_EMP_EARNINGS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_EMP_EARNINGS_FAILURE, error: defaultErrorMsg });
  }
}

function* watchEmployeePanelSaga() {
  yield takeLatest(ActionTypes.EMP_DASHBOARD_COUNT, getEmpDashboardCountReq);
  yield takeLatest(ActionTypes.GET_EMP_COMPANIES, getEmpCompanyReq);
  yield takeLatest(ActionTypes.UPDATE_COMPANY_STATUS, updateCompanyStatusReq);
  yield takeLatest(ActionTypes.ASSIGN_COMPANY_TARGETS, assignCompanyTargetsReq);
  yield takeLatest(ActionTypes.GET_COMPANY_TARGET_HISTORY, getCompanyTargetHistoryReq);
  yield takeLatest(ActionTypes.GET_EMP_TARGET_HISTORY, getEmpTargetHistoryReq);
  yield takeLatest(ActionTypes.GET_EMP_EARNINGS, getEmpEarningsReq);
}

function* EmployeePanelSaga() {
  yield fork(watchEmployeePanelSaga);
}

export default EmployeePanelSaga;