import React, { useState, useEffect } from 'react';
import { Link, useParams, useNavigate } from 'react-router-dom';
import { SIGNIN } from '../../global/routes';
import { useForm } from "react-hook-form";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import * as ResetPasswordActions from './resetpasswordActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import { tokenkey, rememberMeKey, passKey, identifier, encryptStorage, userId } from '../../utils/auth';
import { validationRegex } from '../../utils/conts';

import styles from './styles.module.scss'
import { pwdTooltipContent } from '../../utils/helpers';

/**
 * @author      Nandani.V.Patel
 * @date        11 Oct 2024
 * @description Reset Password Form and  handling resetpassword-api call.
 * @param
 * @response      
**/

const ResetPassword = (props) => {
  const { register, handleSubmit, getValues, resetField, formState: { errors }, } = useForm();
  const { actions, resetpassword } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isredirect, setIsRedirect] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const location = useParams();
  const token = location.token ? location.token : '';


  useEffect(() => {
    if (typeof resetpassword?.data !== 'undefined' && !resetpassword?.isreset && resetpassword?.data?.status === 'success') {
      notification(resetpassword?.data?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 2000);
      localStorage.removeItem(tokenkey);
      encryptStorage.removeItem(rememberMeKey);
      encryptStorage.removeItem(identifier);
      encryptStorage.removeItem(passKey);
      encryptStorage.removeItem(userId);
      dispatch({ type: ResetPasswordActions.ActionTypes.RESET_PASSWORD_SUCCESS, data: undefined });
      resetField('password');
      resetField('confirmpassword');
      resetField('password');
    }
    if (resetpassword?.error?.message && !resetpassword?.isreset) {
      notification(resetpassword?.error?.message, 'error');
      dispatch({ type: ResetPasswordActions.ActionTypes.RESET_PASSWORD_FAILURE, error: undefined });
    }
  }, [resetpassword?.data, resetpassword?.isreset]);

  useEffect(() => {
    if (isredirect) {
      navigate(SIGNIN);
    }
  }, [isredirect])

  const onSubmit = (data) => {
    const password = getValues('password');
    const confirmPassword = getValues('confirmpassword');

    if (password !== confirmPassword) {
      notification('Password and Confirm-Password must be same.', 'error');
    } else {
      const reqData = {
        reset_token: token,
        password: data.password,
        password_confirmation: data.confirmpassword,
      };
      actions.resetpasswordRequest(reqData);
    }
  }

  return (
    <>
      <div className="login-area ptb-50">
        <div className="container">
          <div className="login">
            <h3>Reset Password</h3>
            <div className="form-group">
              <div className='position-relative'>
                <input
                  type="password"
                  {...register("password", {
                    required: 'Password is required.',
                    validate: {
                      minLength: (value) => value.length >= 8 || 'Password should be at least 8 characters.',
                      maxLength: (value) => value.length <= 16 || 'Password should be at most 16 characters.',
                      complexity: (value) => validationRegex.password.test(value) || 'Password must meet complexity requirements.',
                    },
                  })}
                  className="form-control"
                  placeholder="Password"
                />
                <i className="fa fa-info-circle info-icon pwdInfoIcon" data-tooltip-id="pwd-validate-tooltip"></i>
              </div>
              {errors?.password?.type === 'required' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
              {errors?.password?.type === 'minLength' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
              {errors?.password?.type === 'maxLength' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
              {errors?.password?.type === 'complexity' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
            </div>
            <ReactTooltip id="pwd-validate-tooltip" place="right" content={pwdTooltipContent} />
            <div className="form-group">
              <input
                type="password"
                {...register("confirmpassword", {
                  required: 'Confirm Password is required.',
                  validate: {
                    minLength: (value) => value.length >= 8 || 'Password should be at least 8 characters.',
                    maxLength: (value) => value.length <= 16 || 'Password should be at most 16 characters.',
                    complexity: (value) => validationRegex.password.test(value) || 'Password must meet complexity requirements.',
                  },
                })}
                className="form-control"
                placeholder="Confirm Password"
              />
              {errors?.confirmpassword?.type === 'required' && (<span className={styles.errormsg}>{errors.confirmpassword.message}</span>)}
              {errors?.confirmpassword?.type === 'minLength' && (<span className={styles.errormsg}>{errors.confirmpassword.message}</span>)}
              {errors?.confirmpassword?.type === 'maxLength' && (<span className={styles.errormsg}>{errors.confirmpassword.message}</span>)}
              {errors?.confirmpassword?.type === 'complexity' && (<span className={styles.errormsg}>{errors.confirmpassword.message}</span>)}
            </div>
            <button
              type="submit"
              className="default-btn btn blue-btn"
              onClick={handleSubmit(onSubmit)}
              disabled={resetpassword.isreset && resetpassword.data !== 'undefiend'}
            >
              {(!resetpassword.isreset && resetpassword.data !== 'undefiend')
                ?
                <>Submit</>
                :
                <><div className="spinner-border " style={{ width: '1.2rem', height: '1.2rem' }} role="status" /></>
              }
            </button>
            <Link to={SIGNIN} className={styles.link}>{"<<"} Go Back to Login</Link>
          </div>
        </div>
      </div >
      <NotificationContainer />
    </>
  )
}

const mapStateToProps = (state) => ({
  resetpassword: state.resetpassword,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ResetPasswordActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ResetPassword);