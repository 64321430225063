import { isPossiblePhoneNumber } from "react-phone-number-input";
import CryptoJS from 'crypto-js';

export const pwdTooltipContent = () => (
  <div className="tooltiptext">
    <ul className='m-0 ps-2'>
      <li>Must be at least 8 characters long</li>
      <li>Cannot exceed 64 characters</li>
      <li>Must contain at least one uppercase letter (A-Z)</li>
      <li>Must contain at least one lowercase letter (a-z)</li>
      <li>Must contain at least one digit (0-9)</li>
      <li>Must contain at least one special character</li>
      <li>Cannot contain whitespace</li>
    </ul>
  </div>
);

export const validatePhoneNumber = (value) => {
  if (!value.startsWith('+')) {
    return "Phone Number must include a valid international code (e.g., +91 or +1).";
  }
  if (!isPossiblePhoneNumber(value)) {
    return "Invalid phone number format.";
  }
  return true;
};

// load razorpay script
export const loadRazorpayScript = () => {
  return new Promise((resolve) => {
    if (document.querySelector('script[src="https://checkout.razorpay.com/v1/checkout.js"]')) {
      resolve(true); // Script already exists
      return;
    }

    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

// Decrypt Function
export const decryptKey = (encryptedValue) => {
  try {
    const base64Key = "bURtnESq3uaD4nsa9NLk12jVoHdp+MKuhbj7xmQGwms="; // Exclude "base64:" prefix
    const sharedSecretKey = CryptoJS.enc.Base64.parse(base64Key); // Decode Base64
    const iv = CryptoJS.lib.WordArray.create(sharedSecretKey.words.slice(0, 4)); // First 16 bytes

    // const bytes = CryptoJS.AES.decrypt(encryptedValue, "base64:bURtnESq3uaD4nsa9NLk12jVoHdp+MKuhbj7xmQGwms=");
    // return bytes.toString(CryptoJS.enc.Utf8); // Convert decrypted bytes to string

    const decryptedBytes = CryptoJS.AES.decrypt(
      encryptedValue,
      sharedSecretKey,
      {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
      }
    );
  
    return decryptedBytes.toString(CryptoJS.enc.Utf8);
  } catch (error) {
    console.log("Decrypt Error:", error);
  }
};