export const ActionTypes = {
  FETCH_CMS_PAGES_REQUEST: "FETCH_CMS_PAGES_REQUEST",
  FETCH_CMS_PAGES_SUCCESS: "FETCH_CMS_PAGES_SUCCESS",
  FETCH_CMS_PAGES_FAILURE: "FETCH_CMS_PAGES_FAILURE",

  GET_PAGE_BY_SLUG_REQUEST: "GET_PAGE_BY_SLUG_REQUEST",
  GET_PAGE_BY_SLUG_SUCCESS: "GET_PAGE_BY_SLUG_SUCCESS",
  GET_PAGE_BY_SLUG_FAILURE: "GET_PAGE_BY_SLUG_FAILURE",

  FETCH_FAQS_REQUEST: "FETCH_FAQS_REQUEST",
  FETCH_FAQS_SUCCESS: "FETCH_FAQS_SUCCESS",
  FETCH_FAQS_FAILURE: "FETCH_FAQS_FAILURE",
};

export const fetchCmsPagesRequest = () => {
  return {
    type: ActionTypes.FETCH_CMS_PAGES_REQUEST,
  };
};

export const getPageBySlugRequest = (payload) => {
  return {
    type: ActionTypes.GET_PAGE_BY_SLUG_REQUEST,
    payload,
  };
};

export const fetchFaqRequest = (payload) => {
  return {
    type: ActionTypes.FETCH_FAQS_REQUEST,
    payload
  };
};