import { ActionTypes } from './registerActions';

/**
 * @author      Nandani.V.Patel
 * @date        09 Oct 2024
 * @description Registration Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Registartion api response  
**/

const defaultState = {
  isRegistration: false,
  socialLoad: false,
  socialPlatforms: [],
};

const signUpReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.REGISTRATION_REQUEST:
      return {
        ...state,
        isRegistration: true,
      };
    case ActionTypes.REGISTRATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        isRegistration: false,
      };
    case ActionTypes.REGISTRATION_FAILURE:
      return {
        ...state,
        isRegistration: false,
        error: action.error,
      };
    case ActionTypes.GET_SOCIAL_PLATFORMS:
      return {
        ...state,
        socialLoad: true,
      };
    case ActionTypes.GET_SOCIAL_PLATFORMS_SUCCESS:
      return {
        ...state,
        socialPlatforms: action.data,
        socialLoad: false,
      };
    case ActionTypes.GET_SOCIAL_PLATFORMS_FAILURE:
      return {
        ...state,
        socialLoad: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default signUpReducer;