/**
 * @author      Nandani.V.Patel
 * @date        08 Oct 2024
 * @description Defines the Routes for all React Components.
 * @param
 * @response 
**/

export const SIGNIN = '/login';
export const SIGNUP = '/registration';
export const CREATEACCOUNT = '/create-account';
export const COMPANYREGISTER = '/company-register';
export const FORGOTPASSWORD = '/forgotPassword';
export const RESETPASSWORD = '/resetpassword/:token';
export const LOGOUT = '/logout';
export const NOTFOUND = '/404';
export const UNAUTHORIZED = '/403';
export const TERMSCONDITIONS = '/terms-conditions';
export const OTPVERIFY = '/verify-otp';

export const HOME = '/home';
export const WORKFLOW = '/how-it-works';

// Footer page links
export const ROLEIDPARAM = '/:roleId';
export const CMS_PAGE = '/page/:slug';
export const FAQ_PAGE = '/faq';
export const AGREEMENTPAGE = '/agreement';

export const DASHBOARD = '/dashboard';
export const PROFILE = '/profile';
export const CHANGEPASSWORD = '/changepassword';
export const REVIEWS = '/reviews';

export const COMPANYDETAILS = '/company-details';
export const COMPANYID = '/:id';
export const COMPANYLIST = '/company-list';
// export const COMPANY_REVIEW_SITE = "/review-sites";
// export const REVIEW_SITE_ID = "/:id";
export const PURCHASE_REVIEWS = "/purchase-reviews";
export const AI_REVIEWS = "/ai-reviews";
export const CHECKOUT_PAGE = "/checkout";
export const TRANSACTION_HISTORY = "/transaction-history";
export const ORDER_DETAILS = "/orders";
export const USER_DETAILS = `/${ORDER_DETAILS}/:reviewSite/users`;

export const CATEGORYLIST = '/category-list';
export const CATEGORYID = '/:id';

export const TEAMLEADERUSERS = '/users';
export const TEAMLEADERUSERID = '/:id'
export const ADDTEAMLEADERUSERS = '/users/add';
export const CREATETEAMLEADERUSERS = '/users/create';
export const SUBTL_LIST = '/sub-tl';
export const ADD_SUBTL = '/sub-tl/create';
export const TEAMLEADER_COMPANYID = '/:id';
export const REVENUE = '/revenue';
export const WITHDRAWAL = '/withdrawal';
export const ASSIGNTARGET_SUBTL = '/targets/assign-target';

export const EMPCOMPANIES = '/company';
export const CREATEEMPCOMPANY = '/company/create';
export const EMPCOMPANYTARGETHISTORY = '/company/target-history';
export const MYTARGETS = '/targets';
export const EMPEARNINGS = '/earnings';