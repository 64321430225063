import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { ActionTypes, getPageBySlugRequest } from '../../component/footer/footerActions';
import Preloader from '../../component/Preloader';
import { notification } from '../../component/notificationContainer/notification';
import { NotificationContainer } from '../../component/notificationContainer';
import { siteMetaTitle } from '../../utils/conts';

const DynamicCMSPage = ({ slug }) => {
  const dispatch = useDispatch();

  const { data, isPageLoaded, error } = useSelector((state) => state.footer);

  useEffect(() => {
    return () => {
      // Reset page data
      dispatch({ type: ActionTypes.GET_PAGE_BY_SLUG_SUCCESS, data: {} });
      document.title = siteMetaTitle; // Reset document title
    }
  }, [dispatch]);

  useEffect(() => {
    if (slug) {
      dispatch(getPageBySlugRequest({ slug }));
    }
  }, [dispatch, slug]);

  useEffect(() => {
    if (data && Object.keys(data).length > 0) {
      // Update document title and meta description dynamically
      data?.meta_title && (document.title = data.meta_title);
      const metaDescr = document.querySelector('meta[name="description"]');
      if (metaDescr) metaDescr.content = data.meta_description;
    }
  }, [data, dispatch]);

  useEffect(() => {
    if (error?.message) {
      notification(error.message, "error");
    }
  }, [error]);

  return (
    <>
      {isPageLoaded && <Preloader />}
      <div className="dynamic-page-area pt-100 pb-70">
        <div className="container">
          <div dangerouslySetInnerHTML={{ __html: data?.content }} />
        </div>
      </div>
      <NotificationContainer />
    </>
  );
}

export default DynamicCMSPage;