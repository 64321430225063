import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notification } from "../../component/notificationContainer/notification";
import { generateAIReview } from "./companyDetailsActions";

/**
 * @author      Jigar Surati
 * @date        24 Dec 2024
 * @description Show AI Generated Review.
 * @param
 * @response 
**/

const SubmitAIReviewModal = ({ onClose, company_id = "", site_id = "" }) => {
  const dispatch = useDispatch();

  const propState = useSelector((state) => ({
    aiReview: state.companydetails?.aiReview?.ai_response || "",
    aiLoader: state.companydetails.aiLoader?.[site_id] || false,
  }));

  // re-generate text
  const generateText = () => {
    dispatch(generateAIReview({ company_id, site_id }));
  };

  // Copy review text to clipboard
  const copyText = async (text) => {
    if (!navigator.clipboard || !navigator.clipboard.writeText) {
      notification("Clipboard API is not supported in this browser", "error");
      return;
    }
    try {
      await navigator.clipboard.writeText(text);
      notification("Text copied to clipboard", "success");
    } catch (err) {
      notification('Failed to copy text', 'error');
    }
  };

  return (
    <>
      <div
        className="modal fade show d-block"
        id="addReviewModal"
        tabIndex="-1"
        aria-labelledby="addReviewModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg modal-dialog-centered" id="aiReviewModal">
          <div className="modal-content add-review">
            <div className="modal-header">
              <h3 className="text-light">Generate AI Review</h3>
              <button
                type="button"
                className="btn-close btn-close-white text-light"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body review-form">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="prompt-box">
                    <textarea
                      className="form-control"
                      rows="4"
                      readOnly
                      value={propState.aiReview}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary rounded me-2 generate-btn"
                onClick={generateText}
                disabled={propState.aiLoader}
              >
                {propState.aiLoader ? (
                  <div className="spinner-border spinner-border-sm"></div>
                ) : (
                  "Re-generate"
                )}
              </button>
              <button
                className="btn btn-success copy-btn"
                onClick={() => copyText(propState.aiReview)}
                disabled={!propState.aiReview || propState.aiLoader}
              >
                Copy
              </button>
            </div>

          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
    </>
  );
};

export default SubmitAIReviewModal;
