import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './dashboardcountActions';
import { getRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @author      Nandani.V.Patel
 * @date        17 Oct 2024
 * @description Dashboard count Saga and handling API response.
 * @param       
 * @response    Landing Page counts is success or fail.  
**/

function* dashboardcountSaga() {
  try {
    const response = yield call(getRequestAPI, { url: 'count' });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.GET_DASHBOARD_COUNT_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.GET_DASHBOARD_COUNT_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_DASHBOARD_COUNT_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchDashboardCountRequest() {
  yield takeLatest(ActionTypes.GET_DASHBOARD_COUNT_REQUEST, dashboardcountSaga);
}

function* dashboardCountSaga() {
  yield fork(watchDashboardCountRequest);
}

export default dashboardCountSaga;