import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import StatsBlock from '../../dashboard/statsBlock';
import { getEmpEarnings } from '../redux/actions';
import { getCurrencyDataRequest } from '../../Withdrawal/redux/actions';

const EarningsOverview = () => {
  const dispatch = useDispatch();
  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const propState = useSelector((state) => ({
    earningsData: state.employeePanel.earningsData,
    earningStatData: state.employeePanel.earningsData?.employeeStateEarnings?.[0] || {},
    currencyData: state.withdrawal.currencyData,
    loading: state.employeePanel.loading
  }));

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  useEffect(() => {
    const payload = {
      per_page: rowsPerPage,
      page: currentPage
    };

    dispatch(getEmpEarnings(payload));
    dispatch(getCurrencyDataRequest());
  }, [dispatch, rowsPerPage, currentPage]);

  const columns = [
    {
      name: "Company Name",
      selector: (row) => row?.company_name,
      cell: (row) => row?.company_name,
      sortable: true,
    },
    {
      name: "Target Completion Rate",
      selector: (row) => row?.target_completion_rate || 0,
      cell: (row) => `${parseInt(row?.target_completion_rate) || 0}%`,
      sortable: false,
    },
    {
      name: "Earned Amount",
      selector: (row) => row?.earned_amount || 0,
      cell: (row) => (
        <span className='text-success'>
          {propState.currencyData?.currency_symbol || ""}{" "}
          {row?.earned_amount || 0}
        </span>
      ),
      sortable: true,
    },
    // {
    //   name: "Target Status",
    //   selector: (row) => row?.target_status_label,
    //   cell: (row) => row?.target_status_label,
    //   sortable: false,
    // }
  ];

  return (
    <>
      <div className="stats-fun-fact-box-wrapper">
        <div className="row justify-content-between">
          <StatsBlock
            icon="flaticon-coin"
            label="Wallet Balance"
            count={`${propState.currencyData?.currency_symbol || ""} ${propState.earningStatData?.total_amount || 0}`}
            additionalClass='wallet-stats-box'
          />
          <StatsBlock
            icon="fa fa-check-circle"
            label="Completed Targets"
            count={propState.earningStatData?.total_completed_targets || 0}
            additionalClass='stats-box-green'
          />
          <StatsBlock
            icon="fa fa-hourglass-half"
            label="Pending Count of Ongoing Target"
            count={propState.earningStatData?.pending_count || 0}
            additionalClass='stats-box-blue'
          />
          <StatsBlock
            icon="fa fa-money-bill-transfer"
            label="Wthdrawable Amount"
            count={`${propState.currencyData?.currency_symbol || ""} ${propState.earningStatData?.withdrawable_amount || 0}`}
            additionalClass='stats-box-purple'
          />
        </div>
      </div>
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box`}>
        <h4 className={`header-text`}>
          Earnings
        </h4>
      </div>
      <DataTable
        columns={columns}
        data={propState.earningsData?.data || []}
        pagination
        paginationServer
        paginationPerPage={rowsPerPage}
        paginationTotalRows={propState.earningsData?.total || 0}
        paginationRowsPerPageOptions={[5, 15, 25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        // customStyles={customStyles}
        progressPending={propState.loading}
        fixedHeader
        responsive
      />
    </>
  )
}

export default EarningsOverview;