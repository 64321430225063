import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import StatsBlock from '../../dashboard/statsBlock';
import { ActionTypes, getEmpTargetHistory } from '../redux/actions';

const MyTargets = ({ userRoleId }) => {
  const dispatch = useDispatch();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const propState = useSelector(({ employeePanel }) => ({
    empTargets: employeePanel.empTargets,
    loading: employeePanel.loading
  }));

  useEffect(() => {
    const payload = {
      per_page: rowsPerPage,
      page: currentPage
    };

    dispatch(getEmpTargetHistory(payload));
  }, [dispatch, rowsPerPage, currentPage]);

  useEffect(() => {
    return () => {
      // clear messages when component unmounts
      dispatch({ type: ActionTypes.CLEAR_STATE });
    }
  }, []);

  const columns = [
    {
      name: "Target",
      selector: (row) => row?.id,
      cell: (row) => `Target ${row?.id || ""}`,
      sortable: true,
    },
    {
      name: "Assigned Target",
      selector: (row) => row?.employee_target_tbl_review_target,
      cell: (row) => row?.employee_target_tbl_review_target || 0,
      sortable: true,
    },
    {
      name: "Completed Count",
      selector: (row) => row?.employee_target_tbl_completed_review_target,
      cell: (row) => row?.employee_target_tbl_completed_review_target || 0,
      sortable: true,
    },
    {
      name: "Completion Rate",
      selector: (row) => row?.completion_rate,
      cell: (row) => `${row?.completion_rate || 0}%`,
      sortable: true,
    },
    {
      name: "Assigned Date",
      selector: (row) => row?.created_at,
      cell: (row) => new Date(row?.created_at).toLocaleDateString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric',
      }),
      sortable: true,
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      cell: (row) => (
        <div className="btn-area">
          <span
            className="status-btn"
            data-review-status={
              row?.status?.toLowerCase() === "completed"
                ? "approved"
                : row?.status?.toLowerCase()
            }
          >
            {row?.status}
          </span>
        </div>
      ),
      sortable: false,
      right: "yes",
      maxWidth: "150px"
    },
  ];

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  return (
    <>
      <div className="stats-fun-fact-box-wrapper">
        <div className="row justify-content-between">
          <StatsBlock
            icon="fa fa-bullseye"
            label="Total Assigned Targets"
            count={propState.empTargets?.total_assigned_targets || 0}
            additionalClass='stats-box-blue'
          />
          <StatsBlock
            icon="fa-regular fa-thumbs-up"
            label="Completed Targets"
            count={propState.empTargets?.total_completed_targets || 0}
            additionalClass='stats-box-green'
          />
          <StatsBlock
            icon="fa fa-chart-line"
            label="Overall Completion Rate"
            count={`${propState.empTargets?.overall_completion_rate || 0}%`}
            additionalClass='stats-box-purple'
          />
        </div>
      </div>
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box`}>
        <h4 className={`header-text`}>
          My Targets
        </h4>
      </div>
      <DataTable
        columns={columns}
        data={propState.empTargets?.data || []}
        pagination
        paginationServer
        paginationPerPage={rowsPerPage}
        paginationTotalRows={propState.empTargets?.total || 0}
        paginationRowsPerPageOptions={[5, 15, 25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
        // customStyles={customStyles}
        progressPending={propState.loading}
        fixedHeader
        responsive
      />
    </>
  )
}

export default MyTargets;