import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './registerActions';
import { postRequestAPI, postWithoutHeaderRequestAPI } from '../../global/api';
import { userId, encryptStorage } from '../../utils/auth';
import { defaultErrorMsg } from '../../utils/conts';
import { UNAUTHORIZED } from '../../global/routes'

/**
 * @author      Nandani.V.Patel
 * @date        09 Oct 2024
 * @description Registration  Saga and handling API response.
 * @param       register-user Request paylod
 * @response    user registration  is success or fail.  
**/

function* registerUserSaga(action) {
  try {
    const response = yield call(postWithoutHeaderRequestAPI, { url: 'register', data: action.payload });
    if (response?.data?.status === 'success') {
      encryptStorage.setItem(userId, response?.data?.user?.id);
      yield put({ type: ActionTypes.REGISTRATION_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.REGISTRATION_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.REGISTRATION_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getSocialPlatformsSaga() {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'get/social-platform', data: {} });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_SOCIAL_PLATFORMS_SUCCESS, data: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_SOCIAL_PLATFORMS_FAILURE, error: resData });
    }
  } catch (err) {
    if (err?.response?.status === 401) {
      window.location.href = `${UNAUTHORIZED}`;
    }
    yield put({ type: ActionTypes.GET_SOCIAL_PLATFORMS_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchRegistrationRequest() {
  yield takeLatest(ActionTypes.REGISTRATION_REQUEST, registerUserSaga);
  yield takeLatest(ActionTypes.GET_SOCIAL_PLATFORMS, getSocialPlatformsSaga);
}

function* signUpSaga() {
  yield fork(watchRegistrationRequest);
}

export default signUpSaga;