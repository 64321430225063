import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import StatsBlock from '../dashboard/statsBlock';
import { getEmpDashboardCount } from './redux/actions';
import { getCurrencyDataRequest } from '../Withdrawal/redux/actions';
import Preloader from '../../component/Preloader';

const EmployeeDashboard = () => {
  const dispatch = useDispatch();

  const { dashboardCounts, loading } = useSelector((state) => state?.employeePanel);
  const currencyData = useSelector((state) => state.withdrawal?.currencyData);

  useEffect(() => {
    dispatch(getEmpDashboardCount());
    dispatch(getCurrencyDataRequest());
  }, [dispatch]);

  return (
    <>
      {loading && <Preloader />}
      <div className="teamleader-dashboard dashboard-fun-fact-area">
        <div className="row justify-content-between">
          <StatsBlock
            icon="fa fa-bullseye"
            label="Ongoing Targets"
            count={dashboardCounts?.onGoingTargetCount || 0}
          />
          <StatsBlock
            icon="flaticon-company"
            label="Total Companies"
            count={dashboardCounts?.totalCompanies || 0}
          />
          <StatsBlock
            icon="flaticon-coin"
            label="Wallet balance"
            count={`${currencyData?.currency_symbol || ""} ${dashboardCounts?.employeeWalletBalance || 0}`}
            additionalClass="wallet-stats-box"
          />
          <StatsBlock
            icon="fa fa-copy"
            label="Reference Code"
            count={dashboardCounts?.referenceCode || "-"}
          />
        </div>
      </div>
    </>
  )
}

export default EmployeeDashboard;