import React from 'react';
import { Link } from 'react-router-dom';
import { HOME, SIGNIN } from '../../global/routes';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of NotFound Page
 * @param
 * @response 
**/

const NotFound = () => {
  return (
    <>
      <div className="error-area ptb-100">
        <div className="container">
          <div className="top-content">
            <ul>
              <li>4</li>
              <li>0</li>
              <li>4</li>
            </ul>
          </div>
          <h2>Error 404 : Page Not Found</h2>
          <p>
            The page you are looking for might have been removed had its name changed
            or is temporarily unavailable.
          </p>
          <Link to={HOME} className="btn default-btn">
            Go Back
          </Link>
        </div>
      </div>
    </>
  )
}
export default NotFound;
