export const ActionTypes = {
  CREATE_SUB_TL: 'CREATE_SUB_TL',
  CREATE_SUB_TL_SUCCESS: 'CREATE_SUB_TL_SUCCESS',
  CREATE_SUB_TL_FAILURE: 'CREATE_SUB_TL_FAILURE',

  GET_SUB_TL: 'GET_SUB_TL',
  GET_SUB_TL_SUCCESS: 'GET_SUB_TL_SUCCESS',
  GET_SUB_TL_FAILURE: 'GET_SUB_TL_FAILURE',

  GET_CURRENCIES: 'GET_CURRENCIES',
  GET_CURRENCIES_SUCCESS: 'GET_CURRENCIES_SUCCESS',
  GET_CURRENCIES_FAILURE: 'GET_CURRENCIES_FAILURE',

  SET_SUBTL_COMMISSION: 'SET_SUBTL_COMMISSION',
  SET_SUBTL_COMMISSION_SUCCESS: 'SET_SUBTL_COMMISSION_SUCCESS',
  SET_SUBTL_COMMISSION_FAILURE: 'SET_SUBTL_COMMISSION_FAILURE',

  GET_SITE_CURRENCY_AMOUNT: 'GET_SITE_CURRENCY_AMOUNT',
  GET_SITE_CURRENCY_AMOUNT_SUCCESS: 'GET_SITE_CURRENCY_AMOUNT_SUCCESS',
  GET_SITE_CURRENCY_AMOUNT_FAILURE: 'GET_SITE_CURRENCY_AMOUNT_FAILURE',

  CLEAR_STATE: 'CLEAR_STATE',
};

export const createSubTLReq = (payload) => {
  return {
    type: ActionTypes.CREATE_SUB_TL,
    payload
  };
};

export const getSubTL = (payload) => {
  return {
    type: ActionTypes.GET_SUB_TL,
    payload
  };
};

export const getCurrenciesReq = (payload) => {
  return {
    type: ActionTypes.GET_CURRENCIES,
    payload
  };
};

export const setSubTLCommissionReq = (payload) => {
  return {
    type: ActionTypes.SET_SUBTL_COMMISSION,
    payload
  };
};

export const getSiteCurrencyAmount = (payload) => {
  return {
    type: ActionTypes.GET_SITE_CURRENCY_AMOUNT,
    payload
  };
};

export const clearSubTlListStates = () => {
  return {
    type: ActionTypes.CLEAR_STATE,
  };
};