import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as TeamLeaderDashboardActions from '../dashboard/dashboardcountActions';
import StatsBlock from '../dashboard/statsBlock';
import { getCurrencyDataRequest } from '../Withdrawal/redux/actions';

/**
 * @author      Nandani.V.Patel
 * @date        24 th Oct 2024
 * @description UI Implementation of Team Leader Dashboard
 * @param
 * @response 
**/

const TeamLeaderDashboard = () => {
  const teamleaderscounts = useSelector((state) => state?.userdashboardcount?.dashboardteamleadercount);
  const [firstInit, setFirstInit] = useState(false);
  const dispatch = useDispatch();

  const currencyData = useSelector((state) => state.withdrawal?.currencyData);

  useEffect(() => {
    if (firstInit) {
      dispatch(TeamLeaderDashboardActions.getTeamleaderashboardcount());
      dispatch(getCurrencyDataRequest());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  return (
    <>
      <div className="teamleader-dashboard dashboard-fun-fact-area">
        <div className="row stats-fun-fact-box-wrapper">
          <StatsBlock
            icon="fa fa-bullseye"
            label="Active Targets"
            count={teamleaderscounts?.active_target || 0}
          />
          <StatsBlock
            icon="fa fa-user-tie"
            label="Sub-Team Leaders"
            count={teamleaderscounts?.sub_team_leaders || 0}
          />
          <StatsBlock
            icon="fa fa-users"
            label="Team Members"
            count={teamleaderscounts?.teamMembers || 0}
          />
          <StatsBlock
            icon="flaticon-coin"
            label="Wallet balance"
            count={`${currencyData?.currency_symbol || ""} ${teamleaderscounts?.wallet_balancer || 0}`}
            additionalClass="wallet-stats-box"
          />
        </div>
      </div>
    </>
  );
}
export default TeamLeaderDashboard;
