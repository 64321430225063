import { ActionTypes } from './dashboardcountActions';

/**
 * @author      Nandani.V.Patel
 * @date        17 Oct 2024
 * @description dashboard count Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store landing page counts api response  
**/

const defaultState = {
  isdashboardcount: false,
  dashboardcount: undefined
};

const dashboardcountReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_DASHBOARD_COUNT_REQUEST:
      return {
        ...state,
        isdashboardcount: true,
      };
    case ActionTypes.GET_DASHBOARD_COUNT_SUCCESS:
      return {
        ...state,
        dashboardcount: action.data,
        isdashboardcount: false,
      };
    case ActionTypes.GET_DASHBOARD_COUNT_FAILURE:
      return {
        ...state,
        isdashboardcount: false,
        error: action.error,
      };
    default:
      return state;
  }
}
export default dashboardcountReducer;