import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Controller } from 'react-hook-form';
import PhoneInput, { isPossiblePhoneNumber } from 'react-phone-number-input';
import { validationRegex } from '../../utils/conts';
import { pwdTooltipContent, validatePhoneNumber } from '../../utils/helpers';
import { getCountriesRequest } from '../../pages/comapny-Register/comapnyRegisterActions';

import 'react-phone-number-input/style.css';
import styles from './styles.module.scss';

const RegistrationForm = ({ register, errors, getValues, resetField, control }) => {
  const dispatch = useDispatch();
  const countries = useSelector(({ companySignup }) => companySignup?.countries?.data || []);
  const iscountries = useSelector(({ companySignup }) => companySignup?.iscountries || false);

  useEffect(() => {
    // API call for category and countries
    dispatch(getCountriesRequest());
  }, []);

  return (
    <div className="row">
      <div className="col-md-6">
        <div className="form-group">
          <input
            type="text"
            {...register("user_first_name", {
              required: 'First Name is required.',
              validate: {
                hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Name cannot contain numbers.",
                hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Name cannot contain special characters.",
              },
              minLength: {
                value: 2,
                message: 'First Name too short.',
              },
              maxLength: {
                value: 50,
                message: 'First Name too long.',
              },
            })}
            maxLength={50}
            className="form-control"
            placeholder="First Name"
          />
          {errors?.user_first_name && (
            <span className={`text-danger ${styles.errormsg}`}>{errors.user_first_name.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <input
            type="text"
            {...register("user_last_name", {
              required: 'Last Name is required.',
              validate: {
                hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Name cannot contain numbers.",
                hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Name cannot contain special characters.",
              },
              minLength: {
                value: 2,
                message: 'Last Name too short.',
              },
              maxLength: {
                value: 50,
                message: 'Last Name too long.',
              },
            })}
            maxLength={50}
            className="form-control"
            placeholder="Last Name"
          />
          {errors?.user_last_name && (
            <span className={`text-danger ${styles.errormsg}`}>{errors.user_last_name.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <input
            type="email"
            {...register("user_email", {
              required: 'Email is required.',
              pattern: {
                value: validationRegex.email,
                message: 'Please Enter a valid Email.',
              },
            })}
            className="form-control"
            placeholder="Email"
            autoComplete="off"
          />
          {errors?.user_email && (
            <span className={`text-danger ${styles.errormsg}`}>{errors.user_email.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <Controller
            name="user_phone_number"
            control={control}
            defaultValue=""
            rules={{
              required: 'Phone Number is required.',
              validate: validatePhoneNumber,
            }}
            render={({ field }) => (
              <div className={styles.phoneInputContainer}>
                <PhoneInput
                  {...field}
                  international
                  placeholder="Phone Number"
                  maxLength={20}
                  className="form-control"
                />
              </div>
            )}
          />
          {errors?.user_phone_number && (
            <span className={`text-danger ${styles.errormsg}`}>{errors.user_phone_number.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div className='position-relative'>
            <input
              type="password"
              {...register("password", {
                required: 'Password is required.',
                validate: {
                  minLength: (value) => value.length >= 8 || 'Password should be at least 8 characters.',
                  maxLength: (value) => value.length <= 16 || 'Password should be at most 16 characters.',
                  complexity: (value) => validationRegex.password.test(value) || 'Password does not meet the required complexity. See info icon for details.',
                  noCommonPasswords: (value) =>
                    !["password", "12345", "12345678"].includes(value.toLowerCase()) || 'Weak password, avoid common passwords like "password" or "12345".',
                },
              })}
              className="form-control"
              placeholder="Password"
              autoComplete="new-password"
            />
            <i
              className='fa fa-info-circle info-icon pwdInfoIcon'
              data-tooltip-id="pwd-validate-tooltip"
            />
          </div>
          {errors?.password && (
            <span className={`text-danger ${styles.errormsg}`}>{errors.password.message}</span>
          )}
        </div>
        <ReactTooltip id="pwd-validate-tooltip" place="right" content={pwdTooltipContent} />
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <div className='position-relative'>
            <input
              type="password"
              {...register("password_confirmation", {
                required: 'Confirmation Password is required.',
                validate: {
                  minLength: (value) => value.length >= 8 || 'Confirmation Password should be at least 8 characters.',
                  maxLength: (value) => value.length <= 16 || 'Confirmation Password should be at most 16 characters.',
                  complexity: (value) => validationRegex.password.test(value) || 'Confirmation Password does not meet the required complexity. See info for details.',
                  noCommonPasswords: (value) =>
                    !["password", "12345", "12345678"].includes(value.toLowerCase()) || 'Weak password, avoid common passwords like "password" or "12345".',
                },
              })}
              className="form-control"
              placeholder="Confirmation Password"
              autoComplete="new-password"
            />
            <i
              className='fa fa-info-circle info-icon pwdInfoIcon'
              data-tooltip-id="pwd-validate-tooltip"
            />
          </div>
          {errors?.password_confirmation && (
            <span className={`text-danger ${styles.errormsg}`}>{errors.password_confirmation.message}</span>
          )}
        </div>
        <ReactTooltip id="pwd-validate-tooltip" place="right" content={pwdTooltipContent} />
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <select
            {...register("user_country", {
              required: 'Country is required.',
            })}
            className={`form-select ${styles.dropdown}`}
          >
            <option value="" disabled selected>
              {!iscountries ? "Select Country" : "Loading..."}
            </option>
            {!iscountries && countries?.map((country) => (
              <option key={country?.id} value={country?.id}>
                {country?.nicename}
              </option>
            ))}
          </select>
          {errors?.user_country && (
            <span className={`text-danger ${styles.errormsg}`}>{errors.user_country.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <input
            type="text"
            {...register("user_aadhar_card", {
              minLength: {
                value: 12,
                message: 'Aadhar number must be exactly 12 digits',
              },
              maxLength: {
                value: 12,
                message: 'Aadhar number must be exactly 12 digits',
              },
              pattern: {
                value: validationRegex.aadharNum, // Pattern for exactly 12 numeric digits
                message: 'Aadhar number must be numeric and exactly 12 digits.',
              },
            })}
            className="form-control"
            placeholder="Aadhar Number"
          />
          {errors?.user_aadhar_card && (
            <span className={`text-danger ${styles.errormsg}`}>{errors.user_aadhar_card.message}</span>
          )}
        </div>
      </div>
      <div className="col-md-6">
        <div className="form-group">
          <input
            type="text"
            {...register("user_pan_card", {
              pattern: {
                value: validationRegex.panCardNum,  // PAN Card pattern
                message: 'Invalid PAN Card format. Must be 5 letters, 4 digits, followed by 1 letter.',
              },
            })}
            className="form-control"
            placeholder="PAN Card"
          />
          {errors?.user_pan_card && (
            <span className={`text-danger ${styles.errormsg}`}>{errors.user_pan_card.message}</span>
          )}
        </div>
      </div>
    </div>
  );
};

export default RegistrationForm;
