import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useFieldArray, useWatch } from 'react-hook-form';
import { validationRegex } from '../../utils/conts';
import { getSocialPlatformsReq } from '../../pages/registration/registerActions';

const AddSocialLinks = ({ errors, getValues, control }) => {
  const dispatch = useDispatch();

  // get social platforms
  const platforms = useSelector(({ signup }) =>
    signup.socialPlatforms?.length
      ? signup.socialPlatforms.filter(
        (obj) => obj.social_platform_tbl_status === 1
      )
      : []
  );

  useEffect(() => {
    dispatch(getSocialPlatformsReq());
  }, [dispatch]);

  // Use Field Array to handle dynamic fields
  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: "socialPlatform"
  });

  // check if a platform has already been selected
  const isPlatformSelected = (platformId, index) => {
    // Exclude the current index and check if the platform is selected elsewhere
    return watchedSocialLinks.some(
      (link, i) => i !== index && link.platform_id === platformId
    );
  };

  // Watch values to perform validation
  const watchedSocialLinks = useWatch({
    control,
    name: "socialPlatform",
  });

  useEffect(() => {
    const socialValues = getValues("socialPlatform");

    // If socialValues are different from the current form fields, update
    if (socialValues && socialValues?.length && JSON.stringify(socialValues) !== JSON.stringify(fields)) {
      replace(socialValues); // Sync with default values
    }
  }, [getValues, replace]);

  // Check if the last social link has a non-empty platform and URL
  const canAppend = () => {
    if (watchedSocialLinks?.length) {
      const lastLink = watchedSocialLinks[watchedSocialLinks?.length - 1];
      return lastLink && lastLink.platform_id && lastLink.link;
    } else {
      return false;
    }
  };

  return (
    <>
      {fields.map((item, index) => (
        <div key={item.id || index} className="row">
          {/* Platform Dropdown */}
          <div className="col-md-4 col-sm-12">
            <div className="form-group">
              <Controller
                name={`socialPlatform[${index}].platform_id`}
                control={control}
                defaultValue={item.platform_id || ""}
                render={({ field }) => (
                  <select
                    {...field}
                    className="form-select"
                    aria-label={`Select social platform for link ${index + 1}`}
                  >
                    <option value="">Choose a platform</option>
                    {platforms.map((platform) => (
                      <option
                        key={platform.id}
                        value={platform.id}
                        disabled={isPlatformSelected(platform.id, index)} // Disable previously selected platforms
                      >
                        {platform.social_platform_tbl_name}
                      </option>
                    ))}
                  </select>
                )}
              />
              {errors?.socialPlatform?.[index]?.platform_id && (
                <div className="text-danger">
                  {errors.socialPlatform[index].platform_id.message}
                </div>
              )}
            </div>
          </div>

          {/* URL Input */}
          <div className='col-md-6 col-sm-12'>
            <div className="form-group">
              <Controller
                name={`socialPlatform[${index}].link`}
                control={control}
                defaultValue={item.link || ""}
                rules={{
                  pattern: {
                    value: validationRegex.url,
                    message: "Invalid URL format"
                  }
                }}
                render={({ field }) => (
                  <input
                    {...field}
                    type="url"
                    className="form-control"
                    placeholder="Enter social URL"
                    aria-label={`Enter URL for link ${index + 1}`}
                  />
                )}
              />
              {errors?.socialPlatform?.[index]?.link && (
                <span className="text-danger">{errors.socialPlatform[index].link.message}</span>
              )}
            </div>
          </div>

          {/* Remove Button */}
          {index > 0 && (
            <div className='col-md-2 col-sm-12'>
              <button
                type="button"
                className="btn btn-danger mb-4"
                onClick={() => remove(index)}
                aria-label={`Remove link ${index + 1}`}
              >
                Remove
              </button>
            </div>
          )}
        </div>
      ))}

      {/* Add New Row Button */}
      <button
        type="button"
        className="btn btn-success"
        onClick={() => append({ platform_id: "", link: "" })}
        aria-label="Add new social link row"
        disabled={!canAppend()}
      >
        Add Social Link
      </button>
    </>
  )
}

export default AddSocialLinks;