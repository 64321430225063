import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getPurchasedSiteUserReviews, resetMessage } from '../redux/actions';
import { ORDER_DETAILS } from '../../../global/routes';
import Breadcrumb from '../../../component/breadcrumbs';
import { NotificationContainer } from '../../../component/notificationContainer';
import { notification } from '../../../component/notificationContainer/notification';
import PaginationComponent from './Pagination';

import styles from '../styles.module.scss';

const UserDetails = () => {
  const location = useLocation();
  let orderDetails = location.state || {};

  const breadcrumbs = [
    { name: 'Order Details', link: ORDER_DETAILS },
    { name: 'User Reviews', link: "" },
  ];

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const dispatch = useDispatch();
  const propState = useSelector(({ companyPanel }) => ({
    purchasedSiteReviews: companyPanel?.purchasedSiteReviews || [],
    loading: companyPanel.loading,
    errorMsg: companyPanel.errorMsg
  }));

  useEffect(() => {
    if (orderDetails) {
      orderDetails = {
        ...orderDetails,
        per_page: rowsPerPage,
        page: currentPage
      }
      dispatch(getPurchasedSiteUserReviews(orderDetails));
    }
  }, [dispatch, rowsPerPage, currentPage]);

  // foramt review date
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: 'long',
      year: 'numeric'
    });
  };

  useEffect(() => {
    // show error
    if (propState.errorMsg) {
      notification(propState.errorMsg, 'error');

      dispatch(resetMessage());
    }
  }, [dispatch, propState.errorMsg]);

  // Calculate total pages
  const totalPages = Math.ceil((propState.purchasedSiteReviews.total || 0) / rowsPerPage);

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`p-3 mt-2 mb-4 header-box`}>
        <h4 className="header-text">User Reviews</h4>
      </div>
      <div className="manage-applicants-area">
        {propState.loading ? (
          <div className="single-applicants-card">
            <div className='text-center text-dark fs-4 fw-bold'>Loading...</div>
          </div>
        ) : (
          propState.purchasedSiteReviews?.data?.length > 0 ? (
            propState.purchasedSiteReviews.data.map((userReviewData, index) => {
              const {
                user_image,
                user_first_name,
                user_last_name,
                user_email,
                date_of_review,
                user_reviews_ai_check_status_name,
                user_reviews_description
              } = userReviewData;
              return (
                <div key={`userReview${index}`} className="single-applicants-card">
                  <div className="row align-items-center">
                    <div className="col-lg-3 col-md-3">
                      <div className="applicants-left-content">
                        <div className="applicants-img">
                          <img
                            src={user_image}
                            alt="userImage"
                            width={90}
                            height={90}
                            onError={(e) => e.target.src = '/assets/images/team-leader.png'}
                          />
                        </div>
                        <div className="applicants-info">
                          <h3>{`${user_first_name} ${user_last_name}`}</h3>
                          <div className="info">
                            <ul>
                              <li>
                                {user_email && (
                                  <>
                                    <i className="fa-regular fa-envelope" /> <span>{user_email}</span>
                                  </>
                                )}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <div className="applicants-center-content">
                        <div className="review-content">
                          {user_reviews_description || ""}
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-3">
                      <div className="applicants-right-content">
                        <div className="rating-and-date">
                          <div className="date">
                            <i
                              className="fa-regular fa-calendar-days"
                              style={{
                                fontSize: '14px',
                                opacity: '.8',
                                position: 'relative',
                                top: '-1px',
                                paddingRight: '3px'
                              }}
                            /> {formatDate(date_of_review)}
                          </div>
                        </div>
                        <div className="btn-area">
                          <span
                            className="status-btn"
                            data-review-status={user_reviews_ai_check_status_name.toLowerCase()}
                          >
                            {user_reviews_ai_check_status_name}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
            })
          ) : (
            <div className="single-applicants-card">
              <p className="text-center">No User Reviews Available</p>
            </div>
          )
        )}
      </div>
      <PaginationComponent
        totalPages={totalPages}
        currentPage={currentPage}
        onPageChange={setCurrentPage}
      />
      <NotificationContainer />
    </>
  )
}
export default UserDetails;
