import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchFaqRequest } from '../../component/footer/footerActions';
import Preloader from '../../component/Preloader';
import { notification } from '../../component/notificationContainer/notification';
import { NotificationContainer } from '../../component/notificationContainer';
import PaginationComponent from '../companyPanel/orderDetails/Pagination';

const FAQPage = () => {
  const dispatch = useDispatch();

  const { faqList, faqsLoader, error } = useSelector((state) => state.footer);

  // Extract FAQ data or set an empty array if none
  const faqs = faqList?.data || [];

  const [activeIndex, setActiveIndex] = useState(null); // manage which accordion item is open
  const [searchTerm, setSearchTerm] = useState(''); // handling search term input
  const [page, setPage] = useState(1); // tracking the current page number
  const rowsPerPage = 10; // Number of FAQ items to display per page

  // Calculate the total number of pages based on FAQ data
  const totalPages = Math.ceil((faqList?.total || 0) / rowsPerPage);

  // Fetch FAQ data whenever page, search term, or rows per page change
  useEffect(() => {
    dispatch(fetchFaqRequest({ page, per_page: rowsPerPage, search: searchTerm }));
  }, [dispatch, page, rowsPerPage, searchTerm]);

  // Show notification if an error occurs during data fetch
  useEffect(() => {
    if (faqList?.status !== 'success' && error?.message) {
      notification(error.message, 'error');
    }
  }, [error, faqList?.status]);

  // Toggle accordion item
  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <>
      <div className="faq-area ptb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12">
              <div className="faq-accordion pl-15">

                {/* FAQ Title Section */}
                <div className="faq-title">
                  {/* <span>FAQ</span> */}
                  <h2>Frequently Asked Question</h2>
                </div>

                {/* Search input for filtering FAQs */}
                {/* <div className="form-group mb-4">
                <input
                  type="text"
                  className="faq-search form-control"
                  placeholder="Search FAQs"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
              </div> */}

                {/* Show preloader while data is loading */}
                {faqsLoader ? (
                  <Preloader />
                ) : (
                  <div className="accordion">
                    {faqs.length > 0 ? (
                      faqs.map((item, index) => (
                        <div className="accordion-item" key={index}>
                          <div
                            className={`accordion-title ${activeIndex === index ? "active" : ""}`}
                            onClick={() => toggleAccordion(index)}
                          >
                            <i className={`fa-solid fa-plus`} />
                            {item.question}
                          </div>
                          <div className={`accordion-content ${activeIndex === index ? "show" : ""}`}>
                            <p>{item.answer}</p>
                          </div>
                        </div>
                      ))
                    ) : (
                      <p>No FAQs available.</p>
                    )}
                  </div>
                )}

                {/* Pagination section */}
                <div className='mt-5 d-flex justify-content-center'>
                  <PaginationComponent
                    totalPages={totalPages}
                    currentPage={page}
                    onPageChange={setPage}
                  />
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
};

export default FAQPage;
