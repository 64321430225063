import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Breadcrumb from '../../component/breadcrumbs';
import { MYTARGETS } from '../../global/routes';
import { clearSubTlListStates, getSubTL } from '../team-leader-subtl-list/redux/actions';
import { notification } from '../../component/notificationContainer/notification';
import { NotificationContainer } from '../../component/notificationContainer';
import { useLocation, useNavigate } from 'react-router-dom';
import { assignSubTLTargets, ActionTypes, sendMailToUsers } from './redux/actions';

const AssignSubTLTarget = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();
  const { register, formState: { errors }, setValue, getValues, handleSubmit } = useForm({ mode: "onChange" });
  const [currentPage, setCurrentPage] = useState(1);

  const limit = 10;
  const targetTableRef = useRef(null);

  const propState = useSelector((state) => ({
    subTlList: state.teamleaderSubTL.subTlList || {},
    subTlListArr: state.teamleaderSubTL.subTlListArr || [],
    assignedTarget: state.teamLeaderTargets.assignedTarget,
    loading: state.teamleaderSubTL.loading,
    assignLoad: state.teamLeaderTargets.loading,
    mailSentLoad: state.teamLeaderTargets.mailSentLoad,
  }));

  const breadcrumbs = [
    { name: 'My Targets', link: MYTARGETS },
    { name: 'Assign Target', link: "" },
  ];

  useEffect(() => {
    return () => {
      dispatch(clearSubTlListStates());
    }
  }, []);

  useEffect(() => {
    const payload = {
      review_campaign_id: state?.review_campaign_id,
      per_page: limit,
      page: currentPage
    };

    dispatch(getSubTL(payload));
    // dispatch(companylistingRequest(payload));
  }, [dispatch, limit, currentPage]);

  useEffect(() => {
    const handleScroll = () => {
      if (targetTableRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = targetTableRef.current;

        if (
          currentPage < propState.subTlList?.last_page &&
          scrollTop + clientHeight >= scrollHeight - 5 &&
          !propState.loading
        ) {
          setCurrentPage((prevPage) => prevPage + 1);
        }
      }
    };

    const scrollableElement = targetTableRef.current;
    if (scrollableElement) {
      scrollableElement.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollableElement) {
        scrollableElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [propState.subTlList]);

  const submitTargets = (data) => {
    const targetData = Object.entries(data.assign_target)
      .map(([id, values]) => ({
        team_leader_id: parseInt(id),
        assign_review_count: parseInt(values.count),
      }))
      .filter((item) => item.assign_review_count > 0); // check if user has fill out all required details

    // If no valid data, show error and exit the function
    if (targetData.length === 0) {
      notification("You haven't assigned any targets to Sub-TL", 'warning');
      return true;
    }

    const payload = {
      review_campaign_id: state?.review_campaign_id,
      total_pending_reviews: Number(state?.total_pending_reviews || 0),
      total_assign_reviews: Number(state?.total_assign_reviews || 0),
      assignments: targetData,
    };
    // API to assign target to sub-TL
    dispatch(assignSubTLTargets(payload));
  };

  useEffect(() => {
    if (propState.assignedTarget?.status === "success") {
      notification(propState.assignedTarget?.message, 'success');
      navigate(MYTARGETS, { replace: true });

      dispatch({ type: ActionTypes.RESET_STATE });
    }
    if (propState.errorMsg) {
      notification(propState.errorMsg, 'error');
    }
  }, [propState.assignedTarget, propState.errorMsg]);

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box`}>
        <h4 className={`header-text`}>
          Assign Target to Sub-TL
        </h4>
        <button
          className={`btn btn-primary add-btn`}
          onClick={() => dispatch(sendMailToUsers({ review_campaign_id: state?.review_campaign_id }))}
        >
          <i className="fa-regular fa-paper-plane"></i> Send Mail to Users
        </button>
      </div>
      <div className="assign-target-area">
        {propState.subTlListArr?.length > 0 ? (
          <div id='assign-target-table' ref={targetTableRef} className="assign-target-table table-responsive">
            <table className='w-100'>
              <thead>
                <tr>
                  <th>Sub-TL Name</th>
                  <th>Email</th>
                  {/* <th>Last Assigned Reviews</th> */}
                  <th>Overall Target Completion Rate</th>
                  <th>Assign target</th>
                </tr>
              </thead>
              <tbody>
                {propState.subTlListArr?.map((item, index) => (
                  <tr key={index}>
                    <td>{item?.user_first_name || "-"} {item?.user_last_name || ""}</td>
                    <td>{item?.user_email || "-"}</td>
                    {/* <td>{item?.assigned_reviews || 0}</td> */}
                    <td>{item?.completion_rate || 0}%</td>
                    <td>
                      <div className="form-group">
                        <input
                          // id="assign_target"
                          type="number"
                          {...register(`assign_target.${item.team_leader_id}.count`, {
                            pattern: {
                              value: /^[0-9]*$/,
                              message: "Only numbers are allowed"
                            },
                          })}
                          min={0}
                          className="form-control"
                          placeholder="0"
                          defaultValue={0}
                          onChange={(e) => setValue(`assign_target.${item.team_leader_id}.count`, parseInt(e.target.value))}
                        />
                        {errors.assign_target?.[item.team_leader_id]?.count && (
                          <span className={`text-danger`}>
                            {errors.assign_target[item.team_leader_id].count.message}
                          </span>
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {propState.loading && (
              <div className="text-center p-1">
                <div className="spinner-border spinner-border-sm"></div>
              </div>
            )}
          </div>
        ) : (
          <div style={{ padding: "30px" }}>
            {propState.loading ? (
              <div className='text-center text-dark fs-4 fw-bold'>Loading...</div>
            ) : (
              <p className="text-center">No Sub-TL Available</p>
            )}
          </div>
        )}
      </div>

      <div className="text-end">
        <button
          type="submit"
          className="btn blue-btn mb-3"
          onClick={handleSubmit(submitTargets)}
        >
          {propState.assignLoad ? <i className="fa fa-spinner fa-spin"></i> : "Assign"}
        </button>
      </div>
      <NotificationContainer />
    </>
  )
}

export default AssignSubTLTarget;