export const ActionTypes = {
  STORE_BANK_DETAILS_REQUEST: 'STORE_BANK_DETAILS_REQUEST',
  STORE_BANK_DETAILS_SUCCESS: 'STORE_BANK_DETAILS_SUCCESS',
  STORE_BANK_DETAILS_FAILURE: 'STORE_BANK_DETAILS_FAILURE',

  GET_USER_BANK_DETAILS_REQUEST: 'GET_USER_BANK_DETAILS_REQUEST',
  GET_USER_BANK_DETAILS_SUCCESS: 'GET_USER_BANK_DETAILS_SUCCESS',
  GET_USER_BANK_DETAILS_FAILURE: 'GET_USER_BANK_DETAILS_FAILURE',

  WITHDRAWAL_REQUEST: 'WITHDRAWAL_REQUEST',
  WITHDRAWAL_REQUEST_SUCCESS: 'WITHDRAWAL_REQUEST_SUCCESS',
  WITHDRAWAL_REQUEST_FAILURE: 'WITHDRAWAL_REQUEST_FAILURE',

  WITHDRAWAL_HISTORY_REQUEST: 'WITHDRAWAL_HISTORY_REQUEST',
  WITHDRAWAL_HISTORY_SUCCESS: 'WITHDRAWAL_HISTORY_SUCCESS',
  WITHDRAWAL_HISTORY_FAILURE: 'WITHDRAWAL_HISTORY_FAILURE',

  GET_CURRENCY_DATA_REQUEST: 'GET_CURRENCY_DATA_REQUEST',
  GET_CURRENCY_DATA_SUCCESS: 'GET_CURRENCY_DATA_SUCCESS',
  GET_CURRENCY_DATA_FAILURE: 'GET_CURRENCY_DATA_FAILURE',
};

export const storeBankDetailsRequest = (payload) => {
  return {
    type: ActionTypes.STORE_BANK_DETAILS_REQUEST,
    payload,
  };
};

export const getUserBankDetailsRequest = (payload) => {
  return {
    type: ActionTypes.GET_USER_BANK_DETAILS_REQUEST,
    payload,
  };
};

export const withdrawalRequest = (payload) => {
  return {
    type: ActionTypes.WITHDRAWAL_REQUEST,
    payload,
  };
};

export const getWithdrawalHistoryRequest = (payload) => {
  return {
    type: ActionTypes.WITHDRAWAL_HISTORY_REQUEST,
    payload,
  };
};

export const getCurrencyDataRequest = () => {
  return {
    type: ActionTypes.GET_CURRENCY_DATA_REQUEST
  };
};