import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as TeamLeaderDashboardActions from '../dashboard/dashboardcountActions';
import StatsBlock from '../dashboard/statsBlock';
import { getCurrencyDataRequest } from '../Withdrawal/redux/actions';

const SubTLDashboard = () => {
  const teamleaderscounts = useSelector((state) => state?.userdashboardcount?.dashboardteamleadercount);
  const currencyData = useSelector((state) => state.withdrawal?.currencyData);
  const [firstInit, setFirstInit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstInit) {
      dispatch(TeamLeaderDashboardActions.getTeamleaderashboardcount());
      dispatch(getCurrencyDataRequest());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  return (
    <>
      <div className="teamleader-dashboard dashboard-fun-fact-area">
        <div className="row">
          <StatsBlock
            icon="fa-regular fa-hourglass-half"
            label="Ongoing Targets"
            count={teamleaderscounts?.active_target || 0}
          />
          <StatsBlock
            icon="fa fa-user-tie"
            label="Team Leader"
            count={`${teamleaderscounts?.parent_tl_name || ""}`}
          />
          <StatsBlock
            icon="fa fa-users"
            label="Team Members"
            count={teamleaderscounts?.teamMembers || 0}
          />
          <StatsBlock
            icon="flaticon-coin"
            label="Wallet Balance"
            count={`${currencyData?.currency_symbol || ""} ${teamleaderscounts?.wallet_balancer || 0}`}
            additionalClass="wallet-stats-box"
          />
        </div>
      </div>
    </>
  );
}
export default SubTLDashboard;
