// list of regex used for validation
export const validationRegex = {
  email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  nameHasNoNum: /^[^\d]*$/,
  nameHasNoSpclChar: /^(?=.*[A-Za-z])[A-Za-z\s.]*$/,
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&]).{8,16}$/,
  phoneNum: /^\+?[1-9]\d{1,14}$/,
  aadharNum: /^\d{12}$/,
  panCardNum: /^[A-Z]{5}\d{4}[A-Z]{1}$/,
  url: /^(https?:\/\/|www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)$/,
  reviewLink: /^(https?:\/\/(www\.)?|www\.)[a-zA-Z0-9.-]+\.[a-z]{2,6}.*$/,
};

export const defaultErrorMsg = 'Something went wrong. Please try again.';

// list of necessary API keys in frontend
export const settingKeys = [
  "google_client_id",
  "linkedin_client_id",
  "linkedin_client_secret",
  "facebook_client_id",
  "razorpay_key_id"
];

export const siteMetaTitle = "Review Management Platform";