import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import { TEAMLEADERUSERS, ADDTEAMLEADERUSERS, CREATETEAMLEADERUSERS, SUBTL_LIST } from '../../global/routes';
import * as TeamLeaderUsersActions from './teamleaderuserActions';
import styles from './styles.module.scss';
import Breadcrumb from '../../component/breadcrumbs';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description UI Implementation of Team-leaders Dashboard Users
 * @param
 * @response 
**/

const TeamLeaderUsers = (props) => {
  const { teamleaderusers, actions } = props;
  const navigate = useNavigate();
  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [teamleaderuser, setteamleaderuser] = useState('');
  const { state } = useLocation();

  const breadcrumbs = [
    { name: 'Sub-Team Leaders', link: SUBTL_LIST },
    { name: 'Users', link: "" },
  ];

  useEffect(() => {
    if (firstInit) {
      actions.getTeamleaderuserRequest({
        team_leader_id: state?.subTlId || "",
        page,
        per_page: perPage
      });
      setFirstInit(false);
    }
  }, [actions, firstInit, page, perPage, state?.subTlId]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof teamleaderusers?.data !== 'undefined' && !teamleaderusers?.isteamleaderuser) {
      setteamleaderuser(teamleaderusers?.data?.data);
    }
  }, [teamleaderusers?.data, teamleaderusers?.isteamleaderuser]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page when rows per page changes
  };

  const columns = [
    {
      name: 'Member',
      selector: row => `${row?.user_first_name || '-'} ${row?.user_last_name || '-'}`,
      cell: row => (
        <>
          <div className="title-logo">
            {row.user_image ? (
              <img
                src={row.user_image}
                alt="Profile"
                className="user-image"
                width={50}
                height={50}
                style={{ margin: '10px' }}
              />
            ) : (
              <i
                className="fa-solid fa-user-tie"
                style={{ margin: '10px', fontSize: '50px', height: '50px', width: '50px', textAlign: 'center' }}
              />
            )}
          </div>
          <div className={styles.tableCell}>
            {`${row?.user_first_name || '-'} ${row?.user_last_name || '-'}`}
          </div>
        </>
      ),
      sortable: true,
      minWidth: '170px',
    },
    // {
    //   name: 'Full Name',
    //   selector: row => `${row?.user_first_name || '-'} ${row?.user_last_name || '-'}`,
    //   sortable: true,
    //   cell: row => (
    //     <div className={styles.tableCell}>
    //       {`${row?.user_first_name || '-'} ${row?.user_last_name || '-'}`}
    //     </div>
    //   ),
    // },
    {
      name: 'Email',
      selector: row => row?.user_email || '-',
      cell: row => (
        <div className={styles.tableCell}>
          {row?.user_email || '-'}
        </div>
      ),
    },
    {
      name: 'Phone Number',
      // minWidth: '50px',
      // maxWidth: '134px',
      selector: row => row?.user_phone_number || '-',
      cell: row => (
        <div className={styles.tableCell}>
          {row?.user_phone_number || '-'}
        </div>
      ),
    },
    // {
    //   name: 'Approved Reviews',
    //   // selector: row => row?.user_email || '-',
    //   cell: (row) => (
    //     <div className={styles.tableCell}>
    //       0
    //     </div>
    //   ),
    //   sortable: true,
    // },
    {
      name: 'Created Date',
      selector: row => row?.created_at || '-',
      cell: row => new Date(row?.created_at).toLocaleString('en-GB', {
        day: '2-digit',
        month: 'short',
        year: 'numeric'
      })
    },
    {
      name: "Action",
      center: 'yes',
      cell: row => (
        <div className="action-btn">
          <button
            className={`${styles.iconStyle} option-btn d-inline-block`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View Company list"
            type="button"
            onClick={() =>
              navigate(`${TEAMLEADERUSERS}?userid=${row.id}`)
            }
          >
            <i className="fa-solid fa-eye" />
          </button>
        </div>
      ),
      width: '15%',
      omit: state?.subTlId
    },
  ];

  return (
    <>
      {state?.subTlId && (
        <Breadcrumb breadcrumbs={breadcrumbs} />
      )}
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box ${styles.tableheader}`}>
        <h4 className={`col-md-8 header-text`}>
          Team Members
        </h4>
        <div className='col-md-4 d-flex justify-content-end'>
          {/* <button
            className={`btn btn-primary add-btn ${styles.addbtn}`}
            onClick={() => navigate(ADDTEAMLEADERUSERS)}
          >
            Add User
          </button> */}
          {!state?.subTlId && (
            <button
              className={`btn btn-primary ms-3 add-btn ${styles.addbtn}`}
              onClick={() => navigate(CREATETEAMLEADERUSERS)}
            >
              Create User
            </button>
          )}
        </div>
      </div>
      <DataTable
        columns={columns}
        data={teamleaderuser}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationTotalRows={teamleaderusers?.data?.total}
        paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
        conditionalRowClassName={styles.tableCell}
        progressPending={teamleaderusers?.isteamleaderuser}
      />
    </>
  )
}
const mapStateToProps = (state) => ({
  teamleaderusers: state.teamleaderusers,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(TeamLeaderUsersActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TeamLeaderUsers);
