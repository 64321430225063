import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes, saveUserReviews } from './companyDetailsActions';
import { postFileRequestAPI, postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

function* CompanydetailsSaga(action) {
  try {
    const response = yield call(postRequestAPI, { url: 'company-details', data: action.payload });
    if (response?.data?.status === 'success') {
      yield put({ type: ActionTypes.COMPANY_DETAILS_SUCCESS, data: response.data });
    } else {
      yield put({ type: ActionTypes.COMPANY_DETAILS_FAILURE, error: response.data });
    }
  } catch (err) {
    yield put({ type: ActionTypes.COMPANY_DETAILS_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getUserMailedSitesReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'reviewsite-list', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_USER_MAILED_SITES_SUCCESS, data: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_USER_MAILED_SITES_FAILURE, error: resData });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_USER_MAILED_SITES_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* saveUserReviewReq(action) {
  try {
    const { data: resData } = yield call(postFileRequestAPI, { url: 'store-user-review', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.SAVE_USER_REVIEW_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.SAVE_USER_REVIEW_FAILURE, error: resData });
    }
  } catch (error) {
    yield put({ type: ActionTypes.SAVE_USER_REVIEW_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* generateAIReviewReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'generate-ai-prompt', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GENERATE_AI_REVIEW_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.GENERATE_AI_REVIEW_FAILURE, error: resData });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GENERATE_AI_REVIEW_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchCompanyDetailsSagaRequest() {
  yield takeLatest(ActionTypes.COMPANY_DETAILS_REQUEST, CompanydetailsSaga);
  yield takeLatest(ActionTypes.GET_USER_MAILED_SITES, getUserMailedSitesReq);
  yield takeLatest(ActionTypes.SAVE_USER_REVIEW, saveUserReviewReq);
  yield takeLatest(ActionTypes.GENERATE_AI_REVIEW, generateAIReviewReq);
}

function* companyDetailsSaga() {
  yield fork(watchCompanyDetailsSagaRequest);
}

export default companyDetailsSaga;