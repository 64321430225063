import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './actions';
import { getRequestAPI, postRequestAPI } from '../../../global/api';
import { defaultErrorMsg } from '../../../utils/conts';

function* storeBankDetailsSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'store-bank-details', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.STORE_BANK_DETAILS_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.STORE_BANK_DETAILS_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.STORE_BANK_DETAILS_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getBankDetailsSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'get-bank-details', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_USER_BANK_DETAILS_SUCCESS, data: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_USER_BANK_DETAILS_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_USER_BANK_DETAILS_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* withdrawalRequestSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'store-withdrawal-request', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.WITHDRAWAL_REQUEST_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.WITHDRAWAL_REQUEST_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.WITHDRAWAL_REQUEST_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* withdrawalHistorySaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'admin/get-withdrawal-requests', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.WITHDRAWAL_HISTORY_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.WITHDRAWAL_HISTORY_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.WITHDRAWAL_HISTORY_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getCurrencyDataSaga() {
  try {
    const { data: resData } = yield call(getRequestAPI, { url: 'currency/list' });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_CURRENCY_DATA_SUCCESS, data: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_CURRENCY_DATA_FAILURE, error: resData.message });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_CURRENCY_DATA_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchWithdrawalSaga() {
  yield takeLatest(ActionTypes.STORE_BANK_DETAILS_REQUEST, storeBankDetailsSaga);
  yield takeLatest(ActionTypes.GET_USER_BANK_DETAILS_REQUEST, getBankDetailsSaga);
  yield takeLatest(ActionTypes.WITHDRAWAL_REQUEST, withdrawalRequestSaga);
  yield takeLatest(ActionTypes.WITHDRAWAL_HISTORY_REQUEST, withdrawalHistorySaga);
  yield takeLatest(ActionTypes.GET_CURRENCY_DATA_REQUEST, getCurrencyDataSaga);
}

function* WithdrawalSaga() {
  yield fork(watchWithdrawalSaga);
}

export default WithdrawalSaga;