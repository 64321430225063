import React, { useState, useEffect } from 'react';
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from 'react-redux';
import * as companyprofileActions from './redux/actions';
import * as CompanRegistrationActions from '../comapny-Register/comapnyRegisterActions';
import * as CategoryListActions from '../categorylist/categorylistActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import styles from './styles.module.scss';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';
import { validationRegex } from '../../utils/conts';

import 'react-phone-number-input/style.css';

/**
 * @author      Nandani.V.Patel
 * @date        28th Oct 2024
 * @description UI Implementation of Company Profile
 * @param
 * @response 
**/

const CompanyProfile = (props) => {
  const { register, handleSubmit, setValue, formState: { errors }, watch } = useForm();
  const dispatch = useDispatch();
  const companyProfile = useSelector((state) => state.companyPanel);
  const companySignup = useSelector((state) => state?.companySignup);
  const categories = useSelector((state) => state?.categorylist);

  const [firstInit, setFirstInit] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState(null);
  const [countries, setCountries] = useState([]);
  const [category, setCategories] = useState([]);
  const [country, setCountry] = useState('');
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const iscountries = companySignup?.iscountries || false;

  const phoneNum = watch("phone_number");

  useEffect(() => {
    if (firstInit) {
      dispatch(companyprofileActions.getCompanyDetailsRequest());
      dispatch(CompanRegistrationActions.getCountriesRequest());
      dispatch(CategoryListActions.getCategorylistRequest());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  useEffect(() => {
    if (phoneNum) {
      const parsedPhone = parsePhoneNumber(phoneNum);
      const countryShortName = parsedPhone?.country || "";
      setCountry(countryShortName);
    }
  }, [phoneNum]);

  useEffect(() => {
    if (typeof companySignup?.countries?.data !== 'undefined') {
      setCountries(companySignup.countries.data);
    }
  }, [companySignup]);

  useEffect(() => {
    if (typeof categories?.data?.data !== 'undefined') {
      setCategories(categories?.data?.data);
    }
  }, [categories]);

  useEffect(() => {
    if (
      typeof companyProfile?.data !== 'undefined' &&
      !companyProfile?.iscompanyprofile &&
      companyProfile?.data?.status === 'success'
    ) {
      notification(companyProfile?.data?.message, 'success');
      dispatch(companyprofileActions.getCompanyDetailsRequest());
      dispatch({ type: companyprofileActions.ActionTypes.UPDATE_COMPANY_PROFILE_SUCCESS, data: undefined });
    }
    if (
      typeof companyProfile?.data === 'undefined' &&
      companyProfile?.errorMsg &&
      !companyProfile?.iscompanyprofile
    ) {
      notification(companyProfile?.errorMsg, 'error');
      dispatch({ type: companyprofileActions.ActionTypes.UPDATE_COMPANY_PROFILE_FAILURE, error: undefined });
    }
  }, [companyProfile?.data, companyProfile?.iscompanyprofile]);

  useEffect(() => {
    if (companyProfile?.companydata?.data && !companyProfile?.isCompanyDetails) {
      const companydata = companyProfile?.companydata?.data;
      setValue("category", companydata?.company_tbl_category_id);
      setValue("company_name", companydata?.company_tbl_name);
      setValue("company_established_date", companydata?.company_tbl_established_date);
      setValue("company_website_url", companydata?.company_tbl_website);
      setValue("company_registration_number", companydata?.company_registration_number);
      setValue("company_description", companydata?.company_tbl_description);
      setValue("company_address", companydata?.company_tbl_addr);
      setValue("company_country", companydata?.company_tbl_country);
      setValue("pincode", companydata?.company_pincode);
      setValue("email", companydata?.user_email);
      setValue("phone_number", companydata?.company_tbl_mob_no);
      setValue("contact_person_name", companydata?.company_contact_person_name);
      setValue("contact_person_email", companydata?.company_contact_email);
      setValue("company_logo", companydata?.company_tbl_logo);

      setSelectedCountry(companydata?.company_tbl_country);
      setSelectedCategory(companydata?.company_tbl_category_id);

      if (companydata?.company_tbl_logo) {
        setImageUrl(companydata?.company_tbl_logo);
      } else {
        setImageUrl('/assets/images/dashboard/dashboard-img-5.jpg'); // Reset if no user image
      }
    }
  }, [companyProfile?.companydata, companyProfile?.isCompanyDetails, setValue]);

  useEffect(() => {
    setValue("company_country", selectedCountry);
    setValue("category", selectedCategory);
  }, [countries, selectedCountry, selectedCategory, category]);

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      const fileType = file?.type;
      const fileSize = file?.size; // Get file size in bytes
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'];

      // Check if the file is a supported format
      if (!supportedFormats.includes(fileType)) {
        const errorMessage = 'Unsupported file format. Please upload JPEG or PNG.';
        setFileError(errorMessage);
        setImageUrl(companyProfile?.companydata?.data?.user_image || '/assets/images/dashboard/dashboard-img-5.jpg'); // Reset to previous image or default
        setFileName('');
        return;
      }

      // Check if the file exceeds the maximum size
      if (fileSize > maxSize) {
        const errorMessage = 'File size exceeds 2MB. Please upload a smaller image.';
        setFileError(errorMessage);
        setImageUrl(companyProfile?.companydata?.data?.user_image || '/assets/images/dashboard/dashboard-img-5.jpg'); // Reset to previous image or default
        setFileName('');
        return;
      }
      // Clear any file error if the file is valid
      setFileError(null);

      // Set the new image URL and file name
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      setFileName(file?.name);
    }
  };

  const onSubmit = (data) => {
    if (fileError) {
      return;
    }

    // Create FormData to send image and other form data
    const formData = new FormData();
    formData.append('category', data.category);
    formData.append('company_tbl_name', data.company_name);
    formData.append('company_established_date', data.company_established_date);
    formData.append('company_tbl_website', data.company_website_url);
    formData.append('company_registration_number', data.company_registration_number);
    formData.append("company_tbl_description", data?.company_description);
    formData.append("company_tbl_addr", data?.company_address);
    formData.append("company_tbl_country", data?.company_country);
    formData.append("pincode", data?.pincode);
    formData.append("user_email", data?.email);
    formData.append("company_tbl_mob_no", data?.phone_number);
    formData.append("contact_person_name", data?.contact_person_name);
    formData.append("contact_person_email", data?.contact_person_email);
    // Append the image file if selected
    const fileInput = document?.querySelector('input[type="file"]');
    if (fileInput?.files[0]) {
      formData.append('company_logo', fileInput.files[0]);
    }
    dispatch(companyprofileActions.updateCompanyProfile(formData));
  };


  return (
    <>
      <div className={`p-3 mb-4 header-box`}>
        <h4 className="header-text">My profile</h4>
      </div>
      <div className="my-profile-area">
        <div className="profile-form-content">
          <div className="profile-form">
            <div className="row">
              <div className="col-lg-2">
                <div className="my-profile-img" style={{ textAlign: 'center', position: 'relative' }}>
                  <img
                    src={imageUrl}
                    alt="Company Profile"
                    className="img-fluid"
                    style={{ width: '100px', height: '100px' }}
                  />
                  <input
                    type="file"
                    {...register("file")}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <button
                    type="button"
                    className={styles.profileImgBtn}
                    onClick={() => document?.querySelector('input[type="file"]')?.click()} // Trigger file input
                  >
                    Choose File
                  </button>
                  {fileError && <span style={{ marginTop: '5px', display: 'block', color: 'red' }}>{fileError}</span>}
                </div>
              </div>
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Category</label>
                      <select
                        {...register("category", {
                          required: 'Category is required.',
                        })}
                        className={`form-select ${styles.dropdown}`}
                      >
                        <option selected value="">Select Category</option>
                        {category?.map((category) => (
                          <option key={category?.id} value={category?.id}>
                            {category?.category_name}
                          </option>
                        ))}
                      </select>
                      {errors?.category?.type === 'required' && <span className="text-danger">{errors.category.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>Company Name</label>
                      <input
                        type="text"
                        {...register("company_name", {
                          required: 'Company Name is required.',
                          validate: {
                            hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Company Name cannot contain numbers.",
                            hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Company Name cannot contain special characters.",
                          },
                          minLength: {
                            value: 2,
                            message: 'Company Name too short.',
                          },
                          maxLength: {
                            value: 50,
                            message: 'Company Name too long.',
                          },
                        })}
                        className="form-control"
                        placeholder="Company Name"
                      />
                      {errors?.company_name?.type === 'required' && <span className="text-danger">{errors.company_name.message}</span>}
                      {errors?.company_name?.type === 'hasNoNumbers' && (<span className="text-danger">{errors.company_name.message}</span>)}
                      {errors?.company_name?.type === 'hasNoSpecialChars' && (<span className="text-danger">{errors.company_name.message}</span>)}
                      {errors?.company_name?.type === 'minLength' && (<span className="text-danger">{errors.company_name.message}</span>)}
                      {errors?.company_name?.type === 'maxLength' && (<span className="text-danger">{errors.company_name.message}</span>)}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>Company Established date</label>
                      <input
                        type="date"
                        max={new Date().toISOString().split('T')[0]}
                        {...register("company_established_date", {
                          validate: (value) => {
                            if (value) {
                              const today = new Date().toISOString().split('T')[0];
                              return value <= today || 'Company Established date cannot be in the future.';
                            }
                            return true;
                          },
                        })}
                        className="form-control"
                        placeholder="Company Established Date"
                      />
                      {errors?.company_established_date?.type === 'notInFuture' && (<span className="text-danger">{errors.company_established_date.message}</span>)}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>Company Website Url</label>
                      <input
                        type="text"
                        {...register("company_website_url", {
                          required: 'Company Website URL is required.',
                          pattern: {
                            value: /^(https?:\/\/)([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/,
                            message: 'Enter a valid URL starting with http:// or https:// (e.g., https://www.example.com).'
                          }
                        })}
                        className="form-control"
                        placeholder="Company Website URL"
                      />
                      {errors?.company_website_url?.type === 'required' && (
                        <span className="text-danger">{errors.company_website_url.message}</span>
                      )}
                      {errors?.company_website_url?.type === 'pattern' && (
                        <span className="text-danger">{errors.company_website_url.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Company Description</label>
                      <textarea
                        {...register("company_description")}
                        className="form-control"
                        placeholder="Company Description"
                        rows="4"
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Company Address</label>
                      <textarea
                        {...register("company_address", {
                          required: 'Company Address is required.'
                        })}
                        className="form-control"
                        placeholder="Company Address"
                        rows="4"
                      />
                      {errors?.company_address?.type === 'required' && (<span className="text-danger">{errors.company_address.message}</span>)}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>Company Registration Number</label>
                      <input
                        type="text"
                        {...register("company_registration_number", {
                          required: 'Company Registration Number is required.',
                          pattern: {
                            value: /^[A-Z0-9]{10,15}$/,
                            message: 'Registration Number must be 10 to 15 characters long, containing only uppercase letters and numbers.',
                          },
                        })}
                        className="form-control"
                        placeholder="Company Registration Number"
                      />
                      {errors?.company_registration_number?.type === 'required' && <span className="text-danger">{errors.company_registration_number.message}</span>}
                      {errors?.company_registration_number?.type === 'pattern' && <span className="text-danger">{errors.company_registration_number.message}</span>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Company Country</label>
                      <select
                        {...register("company_country", {
                          required: 'Company Country is required.',
                        })}
                        className={`form-select ${styles.dropdown}`}
                      >
                        <option value="" disabled selected>
                          {!iscountries ? "Select Country" : "Loading..."}
                        </option>
                        {!iscountries && countries?.map((country) => (
                          <option key={country?.id} value={country?.id}>
                            {country?.nicename}
                          </option>
                        ))}
                      </select>
                      {errors?.company_country && (
                        <span className="text-danger">{errors.company_country.message}</span>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Pincode</label>
                      <input
                        type="text"
                        {...register("pincode", {
                          required: 'Pincode is required.',
                          pattern: {
                            value: /^[1-9][0-9]{5}$/,
                            message: 'Pincode must be 6 digits.'
                          }
                        })}
                        className="form-control"
                        placeholder="Pincode"
                      />
                      {errors?.pincode?.type === 'required' && (<span className="text-danger">{errors.pincode.message}</span>)}
                      {errors?.pincode?.type === 'pattern' && (<span className="text-danger">{errors.pincode.message}</span>)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        type="email"
                        {...register("email", {
                          required: 'Email is required.',
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Please Enter a valid Email.',
                          },
                        })}
                        className="form-control"
                        placeholder="Email Address"
                        disabled
                      />
                      {errors?.email?.type === 'required' && <span className="text-danger">{errors.email.message}</span>}
                      {errors?.email?.type === 'pattern' && <span className="text-danger">{errors.email.message}</span>}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <PhoneInput
                        international
                        placeholder="Phone Number"
                        maxLength={20}
                        className="form-control"
                        country={country}
                        value={phoneNum || ''}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Contact Person Name</label>
                      <input
                        type="text"
                        {...register("contact_person_name", {
                          required: 'Contact Person Name is required.',
                          validate: {
                            hasNoNumbers: (value) => /^[^\d]*$/.test(value) || "Name can not contain numbers.",
                            hasNoSpecialChars: (value) => /^[a-zA-Z\s]*$/.test(value) || "Name can not contain special characters.",
                          },
                          minLength: {
                            value: 2,
                            message: "Contact Person Name too short."
                          },
                        })}
                        className="form-control"
                        placeholder="Contact Person Name"
                      />
                      {errors?.contact_person_name?.type === 'required' && (<span className="text-danger">{errors.contact_person_name.message}</span>)}
                      {errors?.contact_person_name?.type === 'hasNoNumbers' && (<span className="text-danger">{errors.contact_person_name.message}</span>)}
                      {errors?.contact_person_name?.type === 'hasNoSpecialChars' && (<span className="text-danger">{errors.contact_person_name.message}</span>)}
                      {errors?.contact_person_name?.type === 'minLength' && (<span className="text-danger">{errors.contact_person_name.message}</span>)}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group">
                      <label>Contact Person Email</label>
                      <input
                        type="email"
                        {...register("contact_person_email", {
                          required: 'Contact Person Email is required.',
                          pattern: {
                            value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                            message: 'Please Enter a valid Email.',
                          },
                        })}
                        className="form-control"
                        placeholder="Contact Person Email"
                      />
                      {errors?.contact_person_email?.type === 'required' && (<span className="text-danger">{errors.contact_person_email.message}</span>)}
                      {errors?.contact_person_email?.type === 'pattern' && <span className="text-danger">{errors.contact_person_email.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      className="blue-btn rounded"
                      onClick={handleSubmit(onSubmit)}
                      disabled={companyProfile.iscompanyprofile && companyProfile.data !== 'undefiend'}
                    >
                      {(!companyProfile.iscompanyprofile && companyProfile.data !== 'undefiend')
                        ?
                        <>Save</>
                        :
                        <><div className="spinner-border " style={{ width: '1.2rem', height: '1.2rem' }} role="status" /></>
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </>
  );
};

// const mapStateToProps = (state) => ({
//   companyProfile: state.companyPanel,
// });

// const mapDispatchToProps = (dispatch) => ({
//   actions: bindActionCreators(companyprofileActions, dispatch),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(CompanyProfile);
export default CompanyProfile;