import React, { useState, useEffect } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as CompanydetailsActions from './companyDetailsActions';
import Preloader from '../../component/Preloader';
import { NotificationContainer } from "../../component/notificationContainer";
import SubmitReviewModal from "./submitReviewModal";
import SubmitAIReviewModal from "./submitAIReviewModal";
import { notification } from '../../component/notificationContainer/notification';


/**
 * @author      Nandani.V.Patel
 * @date        10th Oct 2024
 * @description UI Implementation of Company Details
 * @param
 * @response 
**/

const ComapnyDeatils = (props) => {
  const { companydetails, mailedSites, actions } = props;
  const dispatch = useDispatch();
  const [comapnyInfo, setCompanyInfo] = useState('');
  const [firstInit, setFirstInit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showAIReviewModal, setShowAIReviewModal] = useState(false);
  const [selectedSiteId, setSelectedSiteId] = useState('');

  const { state } = useLocation();
  const location = useParams();
  const company_id = location.id ? location.id : '';

  useEffect(() => {
    if (firstInit) {
      actions.companydetailsRequest({ company_id: company_id });
      actions.getUserMailedSites({ company_id });
      setFirstInit(false);
      setTimeout(() => {
        setLoading(false);
      }, 1000);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
    return () => {
      setShowAIReviewModal(false);
      setShowReviewModal(false);
      setSelectedSiteId('');
    }
  }, []);

  useEffect(() => {
    if (typeof companydetails?.data !== 'undefined' && companydetails?.data?.status === 'success') {
      setCompanyInfo(companydetails?.data?.data);
    }
  }, [companydetails?.data]);

  // open AI Review modal if review is generated
  useEffect(() => {
    if (companydetails?.aiReview?.ai_response) {
      notification("AI Review generated successfully", "success");
      setShowAIReviewModal(true);
    }
  }, [companydetails?.aiReview]);

  useEffect(() => {
    if (companydetails?.error && companydetails?.error?.message) {
      notification(companydetails.error.message, 'error');
      dispatch({ type: CompanydetailsActions.ActionTypes.RESET_ERROR });
    }
  }, [companydetails.error, dispatch]);

  useEffect(() => {
    if (companydetails?.aiReviewError && companydetails?.aiReviewError?.message) {
      notification(companydetails.aiReviewError.message, 'error');
      dispatch({ type: CompanydetailsActions.ActionTypes.RESET_ERROR }); // reset error
    }
  }, [companydetails.aiReviewError, dispatch]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          <div
            className="candidates-details-banner-area bg-f0f4fc"
            style={{ paddingTop: "190px" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-lg-7 col-md-8">
                  <div className="candidates-details-left-content">
                    <div className="candidates-img">
                      <img
                        src={comapnyInfo?.company_tbl_logo}
                        alt="Image"
                        style={{ maxWidth: "100%", maxHeight: "100%" }}
                      />
                    </div>
                    <div className="candidates-content">
                      <h3>{comapnyInfo?.company_tbl_name}</h3>
                      <div className="info">
                        <ul>
                          <li>
                            <i className="flaticon-location" />
                            {comapnyInfo?.country_name}
                          </li>
                          <li>
                            <i className="fa-solid fa-phone" />
                            <span href="tel:+567908234875">
                              {comapnyInfo?.company_tbl_mob_no}
                            </span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="candidates-details-area pt-100 pb-70">
            <div className="container">
              <div className="row">
                <div className="col-lg-8">
                  <div className="candidates-details-content">
                    <div className="about-content">
                      <h3>About Company</h3>
                      <p>{comapnyInfo?.company_tbl_description}</p>
                    </div>
                    {mailedSites?.length && (
                      <div className="review-sites">
                        <h3>Review Sites</h3>
                        {mailedSites.map((site, index) => {
                          const { review_site_logo, review_site_tbl_name } = site;
                          return (
                            <div
                              key={`site${index}`}
                              className="single-review-site"
                            >
                              <div className="review-site-content d-flex justify-content-between align-items-center">
                                <div className="img">
                                  <img
                                    src={review_site_logo}
                                    alt="site-logo"
                                    width={60}
                                    height={60}
                                  />
                                </div>
                                <h4>{review_site_tbl_name}</h4>
                                <div className="add-review-btn text-end">
                                  <button
                                    type="button"
                                    className="default-btn btn rounded mr-20"
                                    onClick={() => setShowReviewModal(true)}
                                  >
                                    Add Review
                                  </button>
                                  <button
                                    type="button"
                                    className="default-btn btn rounded"
                                    onClick={() => {
                                      dispatch(CompanydetailsActions.generateAIReview({
                                        site_id: site?.id || "",
                                        company_id
                                      }));

                                      setSelectedSiteId(site?.id);
                                    }}
                                    disabled={companydetails?.aiLoader?.[site?.id] || false}
                                  >
                                    {companydetails?.aiLoader?.[site?.id] ? (
                                      <div className='spinner-border spinner-border-sm'></div>
                                    ) : "AI Generate"}
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="sidebar">
                    <div className="single-sidebar-widget job-overview">
                      <h3>Company Overview</h3>
                      <ul>
                        <li>
                          <span>Category:</span>
                          {comapnyInfo?.category_name || "-"}
                        </li>
                        <li>
                          <span>Established:</span>
                          {new Date(
                            comapnyInfo?.company_tbl_established_date
                          ).toLocaleDateString("en-GB", {
                            day: "2-digit",
                            month: "long",
                            year: "numeric",
                          }) || "-"}
                        </li>
                        <li>
                          <span>Location:</span>
                          {comapnyInfo?.country_name || "-"}
                        </li>
                        <li>
                          <span>Phone Number:</span>
                          {comapnyInfo?.company_tbl_mob_no || "-"}
                        </li>
                        <li>
                          <span>Email:</span>
                          {comapnyInfo?.company_email || "-"}
                        </li>
                      </ul>
                      <Link
                        to={comapnyInfo?.company_tbl_website || "#"}
                        target="_blank"
                        className="d-block text-center mt-4"
                      >
                        {comapnyInfo?.company_tbl_website}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {showReviewModal && (
            <SubmitReviewModal onClose={() => setShowReviewModal(false)} campaign_id={state?.campaign_id || ""} />
          )}
          {showAIReviewModal && (
            <SubmitAIReviewModal
              onClose={() => {
                setSelectedSiteId('');
                setShowAIReviewModal(false);
              }}
              company_id={company_id || ""}
              site_id={selectedSiteId}
            />
          )}
        </>
      )}
      <NotificationContainer />
    </>
  );
}
const mapStateToProps = (state) => ({
  companydetails: state.companydetails,
  mailedSites: state.companydetails.mailedSites,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(CompanydetailsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComapnyDeatils);
