import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { getCampaignPrompts, getCompanyPurchasedSites, resetMessage, storeCampaignPrompts } from "../redux/actions";
import { notification } from "../../../component/notificationContainer/notification";
import { NotificationContainer } from "../../../component/notificationContainer";

import styles from "../styles.module.scss";

const AiReviews = (props) => {
  const dispatch = useDispatch();
  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
    setValue,
  } = useForm({
    defaultValues: {
      site: "",
      prompts: ["Promt 1"], // The first prompt is preloaded
    },
  });

  useEffect(() => {
    dispatch(getCompanyPurchasedSites());
  }, []);

  const propState = useSelector((state) => ({
    purchasedSites: state.companyPanel?.purchasedSites,
    prompts: state.companyPanel?.prompts,
    isPromptsStored: state.companyPanel?.isPromptsStored,
    storedPrompts: state.companyPanel?.storedPrompts,
    errorMsg: state.companyPanel?.errorMsg,
  }));

  useEffect(() => {
    if (propState?.prompts?.length) {
      // Extract the prompts from the response and set them
      const storedPrompts = propState.prompts.map((item) => item.prompts);
      setValue("prompts", storedPrompts);
    } else {
      setValue("prompts", [""]);
    }
  }, [propState.prompts]);

  const { fields, append, remove } = useFieldArray({
    control,
    name: "prompts",
  });

  // store prompts
  const onSubmit = (data) => {
    const selectedSite = propState.purchasedSites.find(
      (site) => site.site_id === parseInt(data.site)
    );
  
    if (!selectedSite) {
      notification("Invalid site selected", "error");
      return;
    }

    const payload = {
      site_id: selectedSite.site_id,
      campaign_id: selectedSite.id,
      prompts: JSON.stringify(data.prompts),
    };

    dispatch(storeCampaignPrompts(payload));
  };

  // Watch values to perform validation
  const watchedPrompts = useWatch({
    control,
    name: "prompts",
  });

  const canAppend = (index) => {
    if (index === 0 || watchedPrompts[index - 1]?.trim()) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (propState.storedPrompts?.status === "success") {
      notification(propState.storedPrompts?.message, "success");

      dispatch(resetMessage());
    }
    if (propState.errorMsg) {
      notification(propState.errorMsg, "error");

      dispatch(resetMessage());
    }
  }, [dispatch, propState.errorMsg, propState.storedPrompts]);

  return (
    <Fragment>
      <div className={`p-3 mb-4 header-box`}>
        <h4 className="header-text">AI Reviews</h4>
      </div>
      <div className="my-profile-area">
        <div className="profile-form-content">
          <div className="profile-form">
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="row">
                    <div className="col-lg-10 col-md-10">
                      <div className="form-group">
                        <label htmlFor="site">Review Sites</label>
                        <select
                          {...register("site", {
                            required: "Site is required.",
                          })}
                          className={`form-select ${styles.dropdown}`}
                          id="site"
                          onChange={(e) => dispatch(getCampaignPrompts({ site_id: e.target.value }))}
                        >
                          <option selected value="">
                            Select Site
                          </option>
                          {propState.purchasedSites?.map((site) => (
                            <option key={site?.site_id} value={site?.site_id}>
                              {site?.review_site_tbl_name}
                            </option>
                          ))}
                        </select>
                        {errors?.site?.type === "required" && (
                          <span className="text-danger">
                            {errors.site.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-lg-12 col-md-12">
                      <div className="form-group">
                        <label>Prompts</label>
                        {fields.map((field, index) => (
                          <div key={field.id} className="row">
                            {index > 0 && <div className="mt-4"></div>}
                            <div className="col-lg-10 col-md-10">
                              <textarea
                                {...register(`prompts.${index}`, {
                                  required: "Prompt is required",
                                })}
                                className="form-control"
                                placeholder={`Enter prompt ${index + 1}`}
                                rows={4}
                              />
                              {errors?.prompts?.[index] && (
                                <span className="text-danger">
                                  {errors.prompts?.[index]?.message}
                                </span>
                              )}
                            </div>
                            <div className="col-lg-2 col-md-2">
                              {/* Only allow removing prompts beyond the first one */}
                              {index > 0 && (
                                <button
                                  type="button"
                                  onClick={() => remove(index)}
                                  className="btn btn-danger rounded fw-semibold"
                                >
                                  Remove
                                </button>
                              )}
                            </div>
                          </div>
                        ))}
                      </div>
                      <div className="col-lg-2 col-md-2">
                        {/* Add More Button */}
                        <button
                          type="button"
                          onClick={() => append("")}
                          className="btn rounded green-btn"
                          disabled={!canAppend(fields.length)} // Disable button if validation fails
                        >
                          Add More
                        </button>
                      </div>
                    </div>
                  </div>
                  {/* Submit Button */}
                  <button
                    type="submit"
                    className="blue-btn rounded mt-5"
                    disabled={propState.isPromptsStored}
                  >
                    {!propState.isPromptsStored ? (
                      "Save Prompts"
                    ) : (
                      <div className="spinner-border spinner-border-sm"></div>
                    )}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </Fragment>
  );
};

export default AiReviews;
