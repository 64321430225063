import { call, fork, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./actions";
import { postRequestAPI } from "../../../global/api";
import { defaultErrorMsg } from "../../../utils/conts";

function* otpVerificationSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'otp/verification', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.VERIFY_OTP_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.VERIFY_OTP_FAILURE, error: resData });
    }
  } catch (err) {
    yield put({ type: ActionTypes.VERIFY_OTP_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* resendOtpSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'otp/resend', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.RESEND_OTP_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.RESEND_OTP_FAILURE, error: resData });
    }
  } catch (err) {
    yield put({ type: ActionTypes.RESEND_OTP_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchOtpVerification() {
  yield takeLatest(ActionTypes.VERIFY_OTP_REQUEST, otpVerificationSaga);
  yield takeLatest(ActionTypes.RESEND_OTP_REQUEST, resendOtpSaga);
}

function* OtpVerificationSaga() {
  yield fork(watchOtpVerification);
}

export default OtpVerificationSaga;