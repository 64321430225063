export const ActionTypes = {
  GET_TL_TARGETS: "GET_TL_TARGETS",
  GET_TL_TARGETS_SUCCESS: "GET_TL_TARGETS_SUCCESS",
  GET_TL_TARGETS_FAILURE: "GET_TL_TARGETS_FAILURE",

  GET_COMPANY_REVIEW_SITES: "GET_COMPANY_REVIEW_SITES",
  GET_COMPANY_REVIEW_SITES_SUCCESS: "GET_COMPANY_REVIEW_SITES_SUCCESS",
  GET_COMPANY_REVIEW_SITES_FAILURE: "GET_COMPANY_REVIEW_SITES_FAILURE",

  ASSIGN_SUBTL_TARGETS: "ASSIGN_SUBTL_TARGETS",
  ASSIGN_SUBTL_TARGETS_SUCCESS: "ASSIGN_SUBTL_TARGETS_SUCCESS",
  ASSIGN_SUBTL_TARGETS_FAILURE: "ASSIGN_SUBTL_TARGETS_FAILURE",

  SEND_MAIL_TO_USERS: "SEND_MAIL_TO_USERS",
  SEND_MAIL_TO_USERS_SUCCESS: "SEND_MAIL_TO_USERS_SUCCESS",
  SEND_MAIL_TO_USERS_FAILURE: "SEND_MAIL_TO_USERS_FAILURE",

  RESET_STATE: "RESET_STATE",
};

export const getTLTargets = (payload) => ({
  type: ActionTypes.GET_TL_TARGETS,
  payload
});

export const getCompanyReviewSites = (payload) => ({
  type: ActionTypes.GET_COMPANY_REVIEW_SITES,
  payload
});

export const assignSubTLTargets = (payload) => ({
  type: ActionTypes.ASSIGN_SUBTL_TARGETS,
  payload
});

export const sendMailToUsers = (payload) => ({
  type: ActionTypes.SEND_MAIL_TO_USERS,
  payload
});