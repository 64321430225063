import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import { ADD_SUBTL, SUBTL_LIST } from '../../global/routes';
import { ActionTypes, getSubTL, updateUserStatus } from './redux/actions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import SetCommission from './setCommission';

import styles from './styles.module.scss';

const SubTLList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // State for pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openModal, setOpenModal] = useState(false);
  const [subTlData, setSubTlData] = useState({});

  const propState = useSelector((state) => ({
    subTlList: state.teamleaderSubTL.subTlList,
    commissionData: state.teamleaderSubTL?.commissionData,
    loading: state.teamleaderSubTL.loading,
    successMsg: state.teamleaderSubTL?.successMsg,
    errorMsg: state.teamleaderSubTL?.errorMsg
  }));

  useEffect(() => {
    const payload = {
      per_page: rowsPerPage,
      page: currentPage
    };
    dispatch(getSubTL(payload));
  }, [dispatch, rowsPerPage, currentPage]);

  const customStyles = {
    headCells: {
      style: {
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        padding: '18px 15px',
        fontSize: '18px',
        fontWeight: '600'
      }
    },
    cells: {
      style: {
        verticalAlign: "middle",
        padding: "20px 15px",
        fontWeight: "500",
        fontSize: "16px"
      }
    }
  };

  const columns = [
    {
      name: 'Name',
      sortable: true,
      selector: (row) => row?.user_name || "-",
      cell: (row) => (
        <>
          {row?.user_image ? (
            <img
              src={row.user_image}
              alt="subTL"
              width="50px"
              height="50px"
            />
          ) : (
            <i
              className="fa-solid fa-user-tie"
              style={{ fontSize: '50px', height: '50px', width: '50px', textAlign: 'center' }}
            />
          )}
          <span className='ps-3'>{row?.user_first_name || "-"} {row?.user_last_name || ""}</span>
        </>
      ),
      minWidth: '170px',
    },
    {
      name: 'Email',
      selector: (row) => row?.user_email,
      cell: (row) => row?.user_email || "-",
      sortable: false,
      minWidth: "130px"
    },
    {
      name: 'Phone No.',
      selector: (row) => row.user_phone_number,
      cell: (row) => row?.user_phone_number || "-",
      sortable: false,
      minWidth: "110px"
    },
    {
      name: 'Team Members',
      selector: (row) => row?.sub_team_leader_users_count,
      cell: (row) => row?.sub_team_leader_users_count || 0,
      sortable: true,
      minWidth: "150px",
    },
    {
      name: "Target Completion Rate",
      selector: (row) => row?.completion_rate,
      cell: (row) => `${row?.completion_rate || 0}%`,
      sortable: true,
    },
    // {
    //   name: "Status",
    //   cell: (row) => (
    //     <div className="form-check form-switch">
    //       <input
    //         className={`form-check-input`}
    //         type="checkbox"
    //         title='Update Status'
    //         id={`flexSwitchCheckDefault-${row.id}`}
    //         checked={userStatus[row.id] !== undefined ? userStatus[row.id] : row.user_status}
    //         onChange={() => handleUpdateStatus(row)}
    //         style={{ cursor: 'pointer', height: '20px', width: '40px' }}
    //       />
    //     </div>
    //   ),
    //   width: "100px",
    //   right: "yes"
    // },
    {
      name: "Action",
      maxWidth: '150px',
      right: 'yes',
      cell: (row) => (
        <div className="action-btn option-list d-flex gap-3 align-items-center">
          <button
            className="option-btn blue-action-btn d-inline-block"
            type="button"
            title='View members'
            onClick={() => {
              navigate(`${SUBTL_LIST}/users`, { state: { subTlId: row.id } });
            }}
          >
            <i className="fa-solid fa-eye"></i>
          </button>
          <button
            className="option-btn blue-action-btn d-inline-block"
            type="button"
            title='Set Commission'
            onClick={() => {
              setSubTlData(row);
              setOpenModal(true);
            }}
          >
            <i className="fa-solid fa-hand-holding-dollar"></i>
          </button>
        </div>
      ),
    },
  ];

  // Pagination event handlers
  const handlePageChange = (page) => setCurrentPage(page);
  const handleRowsPerPageChange = (newRowsPerPage) => {
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  useEffect(() => {
    if (propState.successMsg && propState.commissionData) {
      notification(propState.successMsg, "success");
      setOpenModal(false);
      dispatch({ type: ActionTypes.SET_SUBTL_COMMISSION_SUCCESS, payload: { message: "" } });
    }
    if (propState.errorMsg) {
      notification(propState.errorMsg, "error");
    }
  }, [propState.successMsg, propState.errorMsg]);

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box ${styles.tableheader}`}>
        <h4 className={`col-md-10 header-text`}>
          Sub-Team Leaders
        </h4>
        <button
          className={`btn btn-primary col-md-2 add-btn ${styles.addbtn}`}
          onClick={() => navigate(ADD_SUBTL)}
        >
          Create
        </button>
      </div>
      <DataTable
        columns={columns}
        data={propState.subTlList?.data || []}
        customStyles={customStyles}
        progressPending={propState.loading}
        fixedHeader
        responsive
        pagination
        paginationServer
        paginationTotalRows={propState.subTlList?.total || 0}
        paginationRowsPerPageOptions={[10, 25, 50, 100]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPageChange}
      />
      <NotificationContainer />
      {openModal && (
        <SetCommission
          onClose={() => setOpenModal(false)}
          subTlData={subTlData}
        />
      )}
    </>
  );
}

export default SubTLList;