import { ActionTypes } from "./actions";

const defaultState = {
  loading: false,
  data: {},
  error: { message: "" },
  isUserVerified: false,
  isResent: false,
};

const otpVerificationReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.VERIFY_OTP_REQUEST:
      return {
        ...state,
        isUserVerified: false,
        loading: true,
      };
    case ActionTypes.VERIFY_OTP_SUCCESS:
      return {
        ...state,
        data: action.data,
        isUserVerified: true,
        loading: false,
      };
    case ActionTypes.VERIFY_OTP_FAILURE:
      return {
        ...state,
        isUserVerified: false,
        loading: false,
        error: action.error,
      };
    case ActionTypes.RESEND_OTP_REQUEST:
      return {
        ...state,
        isResent: true,
      };
    case ActionTypes.RESEND_OTP_SUCCESS:
      return {
        ...state,
        data: action.data,
        isResent: false,
      };
    case ActionTypes.RESEND_OTP_FAILURE:
      return {
        ...state,
        isResent: false,
        error: action.error,
      };
    case ActionTypes.OTP_VERIFY_RESET:
      return defaultState;
    default:
      return state;
  }
};

export default otpVerificationReducer;