import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as CompanyDashboardActions from '../../dashboard/dashboardcountActions';
import StatsBlock from '../../dashboard/statsBlock';
import { getLatestOrder } from '../../companyPanel/redux/actions';
import LatestOrderBlock from './latestOrderBlock';

import styles from './styles.module.scss';

const CompanyDashboard = () => {
  const companyStatCounts = useSelector((state) => state?.userdashboardcount?.dashboardcompanycount);

  const [firstInit, setFirstInit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstInit) {
      dispatch(CompanyDashboardActions.getcompanydashboardcount());
      dispatch(getLatestOrder());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  return (
    <>
      <div className="company-dashboard dashboard-fun-fact-area">
        <div className="row">
          <StatsBlock
            icon="fa-regular fa-hourglass-half"
            label="Pending reviews from last purchase"
            count={companyStatCounts?.pending_reviews_from_last_purchase || 0}
          />
          <StatsBlock
            icon="fa-regular fa-thumbs-up"
            label="Completed Targets"
            count={companyStatCounts?.completed_targets || 0}
          />
          <StatsBlock
            icon="fa-regular fa-star"
            label="User Reviews"
            count={companyStatCounts?.user_reviews || 0}
          />
          <StatsBlock
            icon="fa fa-box-open"
            label="Total Orders"
            count={companyStatCounts?.total_orders || 0}
          />
        </div>
      </div>

      <LatestOrderBlock styles={styles} />
    </>
  )
}
export default CompanyDashboard;
