import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import WithdrawalForm from './withdrawalForm';
import WithdrawHistory from './withdrawHistory';
import { getCurrencyDataRequest } from './redux/actions';

import styles from './styles.module.scss';

const Index = () => {
  const dispatch = useDispatch();
  const propState = useSelector((state) => ({
    successMsg: state.withdrawal.successMsg,
    errorMsg: state.withdrawal.errorMsg
  }));

  useEffect(() => {
    dispatch(getCurrencyDataRequest());
  }, [dispatch]);

  useEffect(() => {
    if (propState.successMsg) {
      notification(propState.successMsg, 'success');
    }
    if (propState.errorMsg) {
      notification(propState.errorMsg, 'error');
    }
  }, [propState.successMsg, propState.errorMsg]);

  return (
    <>
      <div className={`d-flex p-3 mb-4 header-box`}>
        <h4 className={`col-md-12 header-text`}>
          Withdrawal
        </h4>
      </div>
      <WithdrawalForm />

      <div className={`d-flex p-3 mb-4 header-box`}>
        <h4 className={`col-md-12 header-text`}>
          History
        </h4>
      </div>
      <WithdrawHistory />
      
      <NotificationContainer />
      <div className="mb-4"></div>
    </>
  )
}

export default Index;
