import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import AddSocialLinks from '../../component/socialLinks';

const ManageSocialAcc = ({ propState = {}, updateSocialLinks }) => {
  const { register, handleSubmit, getValues, formState: { errors }, control, setValue } = useForm({
    mode: 'onChange',
    defaultValues: {
      socialPlatform: [{ platform_id: "", link: "" }], // Default row
    },
  });

  // Update form default values with fetched data
  useEffect(() => {
    const socialLinks = propState?.userdata?.data?.social_platform || [];

    if (socialLinks?.length) {
      const mappedLinks = socialLinks.map((platform) => ({
        platform_id: platform?.social_platform_links_tbl_social_platform_id || "",
        link: platform?.social_platform_links_tbl_social_link || "",
      }));

      setValue("socialPlatform", mappedLinks); // Update form state
    }
  }, [setValue, propState?.userdata?.data]);

  // update social links
  const onSubmit = (data) => {
    updateSocialLinks(data);
  };

  return (
    <>
      <div className='socialLinks'>
        <AddSocialLinks
          register={register}
          errors={errors}
          getValues={getValues}
          control={control}
        />
      </div>
      <button
        type="submit"
        className="default-btn btn mt-4"
        onClick={handleSubmit(onSubmit)}
        disabled={propState?.isprofile}
      >
        {propState?.isprofile ? (
          <div className='spinner-border spinner-border-sm'></div>
        ) : (
          "Update"
        )}
      </button>
    </>
  )
}

export default ManageSocialAcc;