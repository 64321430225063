import { put, takeLatest, fork, call } from 'redux-saga/effects';
import { ActionTypes } from './revenueActions';
import { postRequestAPI } from '../../global/api';
import { defaultErrorMsg } from '../../utils/conts';

/**
 * @description Revenue Saga and handling API response.
 * @param       
 * @response    Revenue is success or fail.  
**/

function* getRevenueSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'team-leader/revenue', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_REVENUE_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.GET_REVENUE_FAILURE, error: resData });
    }
  } catch (err) {
    yield put({ type: ActionTypes.GET_REVENUE_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchRevenueSummaryRequest() {
  yield takeLatest(ActionTypes.GET_REVENUE_REQUEST, getRevenueSaga);
}

function* RevenueSummarySaga() {
  yield fork(watchRevenueSummaryRequest);
}

export default RevenueSummarySaga;