import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { connect, useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as profileActions from './profileActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import { validationRegex } from '../../utils/conts';
import ManageSocialAcc from './manageSocialAcc';
import { encryptStorage, roleId } from '../../utils/auth';
import { getCountriesRequest } from '../comapny-Register/comapnyRegisterActions';
import PhoneInput, { parsePhoneNumber } from 'react-phone-number-input';

import 'react-phone-number-input/style.css';
import styles from "./styles.module.scss";

/**
 * @author      Nandani.V.Patel
 * @date        10th Oct 2024
 * @description UI Implementation of User Profile and Team-leader Profile
 * @param
 * @response 
**/

const Profile = (props) => {
  const userRoleId = encryptStorage.getItem(roleId);
  const { actions, profile, countries, iscountries } = props;
  const { register, handleSubmit, setValue, formState: { errors }, control, watch, getValues } = useForm();
  const dispatch = useDispatch();

  const [firstInit, setFirstInit] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [fileName, setFileName] = useState('');
  const [fileError, setFileError] = useState(null);
  const [country, setCountry] = useState('');

  const phoneNum = watch("user_phone_number");

  useEffect(() => {
    if (firstInit) {
      actions.getUserDetailsRequest();
      dispatch(getCountriesRequest());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  useEffect(() => {
    if (phoneNum) {
      const parsedPhone = parsePhoneNumber(phoneNum);
      const countryShortName = parsedPhone?.country || "";
      setCountry(countryShortName);
    }
  }, [phoneNum]);

  useEffect(() => {
    if (typeof profile?.data !== 'undefined' && !profile?.isprofile && profile?.data?.status === 'success') {
      notification(profile?.data?.message, 'success');
      actions.getUserDetailsRequest();
      dispatch({ type: profileActions.ActionTypes.UPDATE_USER_PROFILE_SUCCESS, data: undefined });
    }
    if (profile?.error?.message && !profile?.isprofile) {
      notification(profile?.error?.message, 'error');
      dispatch({ type: profileActions.ActionTypes.UPDATE_USER_PROFILE_FAILURE, error: undefined });
    }
  }, [profile?.data, profile?.isprofile]);

  useEffect(() => {
    if (profile?.userdata?.data && !profile?.isUserDetails) {
      const userData = profile?.userdata?.data;
      setValue("user_first_name", userData?.user_first_name);
      setValue("user_last_name", userData?.user_last_name);
      setValue("user_email", userData?.user_email);
      setValue("user_phone_number", userData?.user_phone_number);
      setValue("user_aadhar_card", userData?.user_aadhar_card);
      setValue("user_pan_card", userData?.user_pan_card);
      setValue("profile_image", userData?.user_image);
      setValue("user_country", userData?.user_country_id);
      setValue("employee_type", userData?.employee_type?.toLowerCase());
      if (userData?.user_image) {
        setImageUrl(userData?.user_image);
      } else {
        setImageUrl('/assets/images/svg-icon/icon-8.svg'); // Reset if no user image
      }
    }
  }, [profile?.userdata, profile?.isUserDetails, setValue]);

  const handleFileChange = (event) => {
    const file = event?.target?.files[0];
    if (file) {
      const fileType = file?.type;
      const fileSize = file?.size; // Get file size in bytes
      const maxSize = 2 * 1024 * 1024; // 2MB in bytes
      const supportedFormats = ['image/jpeg', 'image/png', 'image/jpg', 'image/webp'];

      // Check if the file is a supported format
      if (!supportedFormats.includes(fileType)) {
        const errorMessage = 'Unsupported file format. Please upload JPEG or PNG.';
        setFileError(errorMessage);
        setImageUrl(profile?.userdata?.data?.user_image || '/assets/images/svg-icon/icon-8.svg'); // Reset to previous image or default
        setFileName('');
        return;
      }

      // Check if the file exceeds the maximum size
      if (fileSize > maxSize) {
        const errorMessage = 'File size exceeds 2MB. Please upload a smaller image.';
        setFileError(errorMessage);
        setImageUrl(profile?.userdata?.data?.user_image || '/assets/images/svg-icon/icon-8.svg'); // Reset to previous image or default
        setFileName('');
        return;
      }
      // Clear any file error if the file is valid
      setFileError(null);

      // Set the new image URL and file name
      const imageUrl = URL.createObjectURL(file);
      setImageUrl(imageUrl);
      setFileName(file?.name);
    }
  };

  // social platforms data
  const updateSocialLinks = (socialData) => {
    handleSubmit((formData) => onSubmit({ ...formData, ...socialData }))();
  };

  const onSubmit = (data) => {
    if (fileError) {
      return;
    }

    // Create FormData to send image and other form data
    const formData = new FormData();
    // Append all user fields from data
    for (const key in data) {
      if (data.hasOwnProperty(key) && key !== "socialPlatform") {
        formData.append(key, data[key]);
      }
    }

    // Append the image file if selected
    const fileInput = document?.querySelector('input[type="file"]');
    if (fileInput?.files[0]) {
      formData.append('user_image', fileInput.files[0]);
    }

    // Append social platform data if present
    if (data?.socialPlatform) {
      data.socialPlatform?.forEach((platform, index) => {
        for (const key in platform) {
          if (platform.hasOwnProperty(key)) {
            formData.append(`socialPlatform[${index}][${key}]`, platform[key]);
          }
        }
      });
    }

    actions.userProfileRequest(formData);
  };

  return (
    <>
      <div className={`py-3 px-4 mb-4 header-box`}>
        <h4 className={`col-md-12 header-text`}>My profile</h4>
      </div>
      <div className="my-profile-area">
        {/* user details */}
        <div className="profile-form-content">
          <div className="profile-form">
            <div className="row">
              <div className="col-lg-2">
                <div className="my-profile-img" style={{ textAlign: 'center', position: 'relative' }}>
                  <img
                    src={imageUrl}
                    alt="Profile"
                    className="img-fluid border border-light-subtle"
                    width={100}
                    height={100}
                  />
                  <input
                    type="file"
                    {...register("file")}
                    style={{ display: 'none' }}
                    onChange={handleFileChange}
                  />
                  <button
                    type="button"
                    className={styles.profileImgBtn}
                    onClick={() => document?.querySelector('input[type="file"]')?.click()} // Trigger file input
                  >
                    Choose File
                  </button>
                  {fileError && <span style={{ marginTop: '5px', display: 'block', color: 'red' }}>{fileError}</span>}
                </div>
              </div>
              <div className="col-lg-10">
                <div className="row">
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>First Name</label>
                      <input
                        type="text"
                        {...register("user_first_name", {
                          required: 'First Name is required.',
                          validate: {
                            hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Name cannot contain numbers.",
                            hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Name cannot contain special characters.",
                          },
                          minLength: {
                            value: 2,
                            message: 'First name should be at least 2 characters.',
                          },
                        })}
                        className="form-control"
                        placeholder="First name"
                      />
                      {errors?.user_first_name?.type === 'required' && <span className="text-danger">{errors.user_first_name.message}</span>}
                      {errors?.user_first_name?.type === 'hasNoNumbers' && (<span className="text-danger">{errors.user_first_name.message}</span>)}
                      {errors?.user_first_name?.type === 'hasNoSpecialChars' && (<span className="text-danger">{errors.user_first_name.message}</span>)}
                      {errors?.user_first_name?.type === 'minLength' && (<span className="text-danger">{errors.user_first_name.message}</span>)}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>Last Name</label>
                      <input
                        type="text"
                        {...register("user_last_name", {
                          required: 'Last name is required.',
                          validate: {
                            hasNoNumbers: (value) => validationRegex.nameHasNoNum.test(value) || "Name cannot contain numbers.",
                            hasNoSpecialChars: (value) => validationRegex.nameHasNoSpclChar.test(value) || "Name cannot contain special characters.",
                          },
                          minLength: {
                            value: 2,
                            message: 'Last name should be at least 2 characters.',
                          },
                        })}
                        className="form-control"
                        placeholder="Last name"
                      />
                      {errors?.user_last_name?.type === 'required' && <span className="text-danger">{errors.user_last_name.message}</span>}
                      {errors?.user_last_name?.type === 'hasNoNumbers' && <span className="text-danger">{errors.user_last_name.message}</span>}
                      {errors?.user_last_name?.type === 'hasNoSpecialChars' && <span className="text-danger">{errors.user_last_name.message}</span>}
                      {errors?.user_last_name?.type === 'minLength' && <span className="text-danger">{errors.user_last_name.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        className="form-control"
                        {...register("user_email")}
                        type="email"
                        placeholder="Email"
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>Phone Number</label>
                      <PhoneInput
                        international
                        placeholder="Phone Number"
                        maxLength={20}
                        className="form-control"
                        country={country}
                        value={phoneNum || ''}
                        disabled
                      />
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label htmlFor='user_country'>Country</label>
                      <select
                        {...register("user_country", {
                          required: 'Country is required.',
                        })}
                        className={`form-select ${styles.dropdown}`}
                        id='user_country'
                        value={watch('user_country')}
                        onChange={(e) => setValue('user_country', e.target.value, { shouldValidate: true })}
                      >
                        <option value="" disabled selected>
                          {!iscountries ? "Select Country" : "Loading..."}
                        </option>
                        {!iscountries && countries?.map((country) => (
                          <option key={country?.id} value={country?.id}>
                            {country?.nicename}
                          </option>
                        ))}
                      </select>
                      {errors?.user_country && (
                        <span className={`text-danger ${styles.errormsg}`}>{errors.user_country.message}</span>
                      )}
                    </div>
                  </div>
                  {userRoleId === 4 && (
                    <div className="col-lg-6 col-md-6">
                      <div className="form-group">
                        <label htmlFor='employee_type'>Employee Type</label>
                        <select
                          {...register("employee_type", {
                            required: 'Employee Type is required.',
                          })}
                          className={`form-select ${styles.dropdown}`}
                          id='employee_type'
                          value={watch('employee_type')}
                          onChange={(e) => setValue('employee_type', e.target.value, { shouldValidate: true })}
                        >
                          <option selected value="">Select Type</option>
                          <option value="salary">Salary</option>
                          <option value="target">Target</option>
                        </select>
                        {errors?.employee_type && (
                          <span className="text-danger">{errors.employee_type.message}</span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>Aadhar Number</label>
                      <input
                        type="text"
                        {...register("user_aadhar_card", {
                          minLength: {
                            value: 12,
                            message: 'Aadhar number must be exactly 12 digits',
                          },
                          maxLength: {
                            value: 12,
                            message: 'Aadhar number must be exactly 12 digits',
                          },
                          pattern: {
                            value: validationRegex.aadharNum, // Pattern for exactly 12 numeric digits
                            message: 'Aadhar number must be numeric and exactly 12 digits.',
                          },
                        })}
                        className="form-control"
                        placeholder="Aadhar Number"
                      />
                      {errors?.user_aadhar_card?.type === 'minLength' && <span className="text-danger">{errors.user_aadhar_card.message}</span>}
                      {errors?.user_aadhar_card?.type === 'maxLength' && <span className="text-danger">{errors.user_aadhar_card.message}</span>}
                      {errors?.user_aadhar_card?.type === 'pattern' && <span className="text-danger">{errors.user_aadhar_card.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="form-group">
                      <label>PAN Card</label>
                      <input
                        type="text"
                        {...register("user_pan_card", {
                          pattern: {
                            value: validationRegex.panCardNum,  // PAN Card pattern
                            message: 'Invalid PAN Card format. Must be 5 letters, 4 digits, followed by 1 letter.',
                          },
                        })}
                        className="form-control"
                        placeholder="PAN Card"
                      />
                      {errors?.user_pan_card && <span className="text-danger">{errors.user_pan_card.message}</span>}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <button
                      type="submit"
                      className="blue-btn rounded"
                      onClick={handleSubmit(onSubmit)}
                      disabled={profile.isprofile && profile.data !== 'undefiend'}
                    >
                      {(!profile.isprofile && profile.data !== 'undefiend')
                        ?
                        <>Save</>
                        :
                        <><div className="spinner-border " style={{ width: '1.2rem', height: '1.2rem' }} role="status" /></>
                      }
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* social accounts for TL and users only */}
        {(userRoleId === 1 || userRoleId === 2) && (
          <div className="profile-form-content pt-0">
            <div className="header-box py-3 px-4">
              <h3 className='p-0 header-text'>Social Accounts</h3>
            </div>
            <div className="profile-form">
              <ManageSocialAcc
                propState={profile}
                updateSocialLinks={updateSocialLinks}
              />
            </div>
          </div>
        )}

      </div>
      <NotificationContainer />
    </>
  );
};

const mapStateToProps = (state) => ({
  profile: state.profile,
  countries: state.companySignup?.countries?.data || [],
  iscountries: state.companySignup?.iscountries || false,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(profileActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
