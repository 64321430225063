/**
 * @author      Nandani.V.Patel
 * @date        17 Oct 2024
 * @description ActionTypes and ActionCreator Function for count of user, companies and reviews on landing page
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_DASHBOARD_COUNT_REQUEST: 'GET_DASHBOARD_COUNT_REQUEST',
  GET_DASHBOARD_COUNT_SUCCESS: 'GET_DASHBOARD_COUNT_SUCCESS',
  GET_DASHBOARD_COUNT_FAILURE: 'GET_DASHBOARD_COUNT_FAILURE',
};

export const getdashboardcount = () => {
  return {
    type: ActionTypes.GET_DASHBOARD_COUNT_REQUEST,
  };
};