import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { bindActionCreators } from 'redux';
import { connect, useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { CREATEACCOUNT, FORGOTPASSWORD, DASHBOARD, OTPVERIFY } from '../../global/routes';
import { rememberMeKey, passKey, identifier, encryptStorage, isAuth } from '../../utils/auth';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import * as LoginActions from './loginActions';
import { GoogleLoginButton, LinkedInLoginButton, FacebookLoginButton } from 'react-social-login-buttons';
import { LoginSocialGoogle, LoginSocialLinkedin, LoginSocialFacebook } from 'reactjs-social-login';
import * as LogoutActions from '../logout/logoutActions';
import styles from './styles.module.scss'
import { settingKeys, validationRegex } from '../../utils/conts';
import { BACKEND_URL } from '../../global/url';

/**
 * @author      Nandani.V.Patel
 * @date        8th Oct 2024
 * @description User Login Form and handling login-api call.
 * @param
 * @response 
**/

const Login = (props) => {
  const { signin, actions } = props;
  const { register, handleSubmit, resetField, setValue, formState: { errors } } = useForm();
  const [checked, setchecked] = useState(false);
  const [isredirect, setIsRedirect] = useState(false);
  const [firstInit, setfirstInit] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const userlogout = useSelector((state) => state?.logout);

  const auth = isAuth();
  useEffect(() => {
    if (auth) {
      navigate(DASHBOARD);
    }
  }, [auth, navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setfirstInit(true);
  }, []);

  useEffect(() => {
    if (firstInit) {
      // for (let i = 0; i < settingKeys.length; i++) {
      //   dispatch(LoginActions.getApiKeyRequest({ key: settingKeys[i] }));
      // }
      // dispatch(LoginActions.getApiKeyRequest({ key: "razorpay_key_id" }));
      setfirstInit(false);
    }
  }, [dispatch, firstInit]);

  useEffect(() => {
    if (userlogout?.data !== undefined && userlogout?.data !== null && userlogout?.data !== '') {
      if (userlogout?.data?.status === 'success') {
        notification(userlogout?.data?.message, 'success');
      }
      dispatch({ type: LogoutActions.ActionTypes.LOGOUT_SUCCESS, data: undefined });
    }
  }, [userlogout?.data, userlogout?.status]);

  const handleChange = () => {
    setchecked(!checked);
  };

  const remkey = encryptStorage.getItem(rememberMeKey) === true;
  useEffect(() => {
    if (remkey) {
      setValue('identifier', encryptStorage.getItem(identifier) || '');
      setValue('password', encryptStorage.getItem(passKey) || '');
      setValue('checked', true);
      setchecked(true);
    }
  }, [remkey]);

  useEffect(() => {
    if (signin?.data !== undefined && signin?.data?.status === 'success' && !signin?.isLogin) {
      notification(signin?.data?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 1000);
      dispatch({ type: LoginActions.ActionTypes.LOGIN_SUCCESS, data: undefined });
      resetField('identifier');
      resetField('password');
    }
    if (signin?.facebookLogindata !== undefined && signin?.facebookLogindata?.status === 'success' && !signin.isfacebookLogin) {
      notification(signin?.facebookLogindata?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 1000);
      dispatch({ type: LoginActions.ActionTypes.FACEBOOK_LOGIN_SUCCESS, facebookLogindata: undefined });
    }
    if (signin?.googleLogindata !== undefined && signin?.googleLogindata?.status === 'success' && !signin.isGoogleLogin) {
      notification(signin?.googleLogindata?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 1000);
      dispatch({ type: LoginActions.ActionTypes.GOOGLE_LOGIN_SUCCESS, googleLogindata: undefined });
    }
    if (signin?.linkedinLogindata !== undefined && signin?.linkedinLogindata?.status === 'success' && !signin.isLinkedinLogin) {
      notification(signin?.linkedinLogindata?.message, 'success');
      setTimeout(() => {
        setIsRedirect(true);
      }, 1000);
      dispatch({ type: LoginActions.ActionTypes.LINKEDIN_LOGIN_SUCCESS, linkedinLogindata: undefined });
    }
    if ((signin?.data === undefined || signin?.facebookLogindata === undefined || signin?.googleLogindata === undefined || signin?.linkedinLogindata === undefined) && signin?.error?.message) {
      setIsRedirect(false);
      notification(signin?.error?.message, 'error');
    }
    if (signin?.error?.status === "unverified") {
      navigate(OTPVERIFY);
    }
    if (signin?.data !== undefined && signin?.data?.status !== 'success' && !signin?.isLogin) {
      dispatch({ type: LoginActions.ActionTypes.LOGIN_FAILURE, error: undefined });
    }
    dispatch({ type: LoginActions.ActionTypes.FACEBOOK_LOGIN_FAILURE, error: undefined });
    dispatch({ type: LoginActions.ActionTypes.GOOGLE_LOGIN_FAILURE, error: undefined });
    dispatch({ type: LoginActions.ActionTypes.LINKEDIN_LOGIN_FAILURE, error: undefined });
  }, [signin?.data, signin.isLogin, signin?.facebookLogindata, signin?.googleLogindata, signin?.linkedinLogindata, signin?.error]);

  useEffect(() => {
    if (isredirect) {
      navigate(DASHBOARD);
    }
  }, [isredirect])

  const onSubmit = (data) => {
    const identifierString = String(data.identifier);
    encryptStorage.setItem(identifier, identifierString);
    if (checked) {
      encryptStorage.setItem(rememberMeKey, checked);
      // encryptStorage.setItem(identifier, identifierString);
      encryptStorage.setItem(passKey, data.password);
    } else {
      encryptStorage.removeItem(rememberMeKey);
      // encryptStorage.removeItem(identifier);
      encryptStorage.removeItem(passKey);
    }
    const logindata = {
      identifier: identifierString,
      password: data.password,
    };
    actions.loginRequest(logindata);
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);
    return () => document.removeEventListener('keyup', handleKeyPress);
  }, [])

  return (
    <>
      <div className="login-area ptb-50">
        <div className="container">
          <div className="login">
            <h3>Log In</h3>
            <div className="form-group">
              <input
                type="text"
                id="identifier"
                {...register("identifier", {
                  required: 'Email Address or Phone No is required.',
                })}
                className="form-control"
                placeholder="Email Address or Phone No"
              />
              {errors?.identifier && (<span className={styles.errormsg}>{errors.identifier.message}</span>)}
            </div>
            <div className="form-group">
              <input
                type="password"
                {...register("password", {
                  required: 'Password is required.'
                })}
                className="form-control"
                placeholder="Password"
              />
              {errors?.password?.type === 'required' && (<span className={styles.errormsg}>{errors.password.message}</span>)}
            </div>
            <div className="form-check">
              <input
                className="form-check-input"
                defaultValue=""
                id="flexCheckDefault"
                type="checkbox"
                onChange={handleChange}
                checked={checked}
              />
              <label className="form-check-label" htmlFor="flexCheckDefault">
                Remember Me
              </label>
            </div>
            <button
              type="submit"
              className="default-btn btn"
              onClick={handleSubmit(onSubmit)}
              disabled={signin.isLogin}
            >
              {!signin.isLogin ? (
                <>Login</>
              ) : (
                <>
                  <div
                    className="spinner-border spinner-border-sm"
                    role="status"
                  />
                </>
              )}
            </button>

            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <Link to={FORGOTPASSWORD} className={styles.link}>Lost your password?</Link>
              <Link to={CREATEACCOUNT} className={styles.link}>Don’t have an account?</Link>
            </div>

            <div className="single-footer-widget quick-link" />
            <div style={{ display: 'flex', alignItems: 'center', margin: '20px 0', width: '100%' }}>
              <hr style={{ flex: 1, border: '1px solid #ccc' }} />
              <span style={{ margin: '0 10px', color: '#999', fontWeight: '500' }}>or Login with</span>
              <hr style={{ flex: 1, border: '1px solid #ccc' }} />
            </div>

            <div className="social-login-buttons" style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
              <div style={{ width: '100%' }}>
                <LoginSocialGoogle
                  client_id={process.env.REACT_APP_GOOGLE_CLIENT_ID}
                  scope='openid profile email'
                  discoveryDocs='claims_supported'
                  access_type='offline'
                  typeResponse='accessToken'
                  onResolve={(response) => {
                    console.log('google login', response);
                    if (response?.data?.code !== undefined && response?.data?.code !== null && response?.data?.code !== '') {
                      const data = {
                        code: response?.data?.code,
                      };
                      actions.googleLoginRequest(data)
                    }
                  }}
                  onReject={(err) => {
                    console.log('google login err:', err);
                    notification('Somthing want to wrong', 'error');
                  }}
                >
                  <GoogleLoginButton
                    className={`${styles.socialbtn}`}
                    style={{
                      boxShadow: 'rgba(0, 0, 0, 0.2) -1px -1px 1px, rgba(0, 0, 0, 0.2) 1px 1px 1px',
                    }}
                  >
                    Google
                  </GoogleLoginButton>
                </LoginSocialGoogle>
              </div>
              <div style={{ width: '100%' }}>
                <LoginSocialLinkedin
                  client_id={process.env.REACT_APP_LINLEDIN_CLIENT_ID}
                  client_secret={process.env.REACT_APP_LINKEDIN_CLIENT_SECRET_KEY}
                  onResolve={(response) => {
                    console.log('onResolve triggered', response);
                    if (response?.data?.access_token) {
                      const data = {
                        accessToken: response?.data?.access_token,
                      };
                      actions.linkedinLoginRequest(data);
                    } else {
                      notification('Somthing want to wrong', 'error');
                    }
                  }}
                  scope='profile%20email%20openid'
                  onReject={(err) => {
                    console.error('Login error:', err);
                    notification(err?.message, 'error');
                  }}
                  redirect_uri={`${BACKEND_URL}login`}
                >
                  <LinkedInLoginButton className={styles.socialbtn}>LinkedIn</LinkedInLoginButton>
                </LoginSocialLinkedin>

              </div>
              <div style={{ width: '100%' }}>
                <LoginSocialFacebook
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID}
                  onResolve={(response) => {
                    console.log('facebook login', response);
                    if (response?.data?.accessToken !== undefined && response?.data?.accessToken !== null && response?.data?.accessToken !== '') {
                      const data = {
                        accessToken: response?.data?.accessToken
                      };
                      actions.facebookLoginRequest(data);
                    }
                  }}
                  onReject={(err) => {
                    console.log('facebook login err', err);
                    notification('Somthing want to wrong', 'error');
                  }}
                  version='v21.0'
                  language='en_EN'
                >
                  <FacebookLoginButton className={`${styles.socialbtn}`}>Facebook</FacebookLoginButton>
                </LoginSocialFacebook>
              </div>
            </div>
          </div>
        </div>
      </div >
      <NotificationContainer />
    </>
  )
}
const mapStateToProps = (state) => ({
  signin: state.signin,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(LoginActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
