export const ActionTypes = {
  EMP_DASHBOARD_COUNT: 'EMP_DASHBOARD_COUNT',
  EMP_DASHBOARD_COUNT_SUCCESS: 'EMP_DASHBOARD_COUNT_SUCCESS',
  EMP_DASHBOARD_COUNT_FAILURE: 'EMP_DASHBOARD_COUNT_FAILURE',

  CREATE_COMPANY : 'CREATE_COMPANY',
  CREATE_COMPANY_SUCCESS : 'CREATE_COMPANY_SUCCESS',
  CREATE_COMPANY_FAILURE : 'CREATE_COMPANY_FAILURE',

  GET_EMP_COMPANIES: 'GET_EMP_COMPANIES',
  GET_EMP_COMPANIES_SUCCESS: 'GET_EMP_COMPANIES_SUCCESS',
  GET_EMP_COMPANIES_FAILURE: 'GET_EMP_COMPANIES_FAILURE',

  UPDATE_COMPANY_STATUS: 'UPDATE_COMPANY_STATUS',
  UPDATE_COMPANY_STATUS_SUCCESS: 'UPDATE_COMPANY_STATUS_SUCCESS',
  UPDATE_COMPANY_STATUS_FAILURE: 'UPDATE_COMPANY_STATUS_FAILURE',
  
  ASSIGN_COMPANY_TARGETS: 'ASSIGN_COMPANY_TARGETS',
  ASSIGN_COMPANY_TARGETS_SUCCESS: 'ASSIGN_COMPANY_TARGETS_SUCCESS',
  ASSIGN_COMPANY_TARGETS_FAILURE: 'ASSIGN_COMPANY_TARGETS_FAILURE',

  GET_COMPANY_TARGET_HISTORY: 'GET_COMPANY_TARGET_HISTORY',
  GET_COMPANY_TARGET_HISTORY_SUCCESS: 'GET_COMPANY_TARGET_HISTORY_SUCCESS',
  GET_COMPANY_TARGET_HISTORY_FAILURE: 'GET_COMPANY_TARGET_HISTORY_FAILURE',

  GET_EMP_TARGET_HISTORY: 'GET_EMP_TARGET_HISTORY',
  GET_EMP_TARGET_HISTORY_SUCCESS: 'GET_EMP_TARGET_HISTORY_SUCCESS',
  GET_EMP_TARGET_HISTORY_FAILURE: 'GET_EMP_TARGET_HISTORY_FAILURE',

  GET_EMP_EARNINGS: 'GET_EMP_EARNINGS',
  GET_EMP_EARNINGS_SUCCESS: 'GET_EMP_EARNINGS_SUCCESS',
  GET_EMP_EARNINGS_FAILURE: 'GET_EMP_EARNINGS_FAILURE',

  CLEAR_STATE: 'CLEAR_STATE'
};

export const getEmpDashboardCount = (payload) => {
  return {
    type: ActionTypes.EMP_DASHBOARD_COUNT,
    payload
  };
};

export const getEmpCompanies = (payload) => {
  return {
    type: ActionTypes.GET_EMP_COMPANIES,
    payload
  };
};

export const updateCompanyStatus = (payload) => {
  return {
    type: ActionTypes.UPDATE_COMPANY_STATUS,
    payload
  };
};

export const assignCompanyTargets = (payload) => {
  return {
    type: ActionTypes.ASSIGN_COMPANY_TARGETS,
    payload
  };
};

export const getCompanyTargetHistory = (payload) => {
  return {
    type: ActionTypes.GET_COMPANY_TARGET_HISTORY,
    payload
  };
};

export const getEmpTargetHistory = (payload) => {
  return {
    type: ActionTypes.GET_EMP_TARGET_HISTORY,
    payload
  };
};

export const getEmpEarnings = (payload) => {
  return {
    type: ActionTypes.GET_EMP_EARNINGS,
    payload
  };
};

export const clearStates = () => {
  return {
    type: ActionTypes.CLEAR_STATE
  };
};