import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import StatsBlock from '../dashboard/statsBlock';
import { getRevenueRequest } from './revenueActions';
import { encryptStorage, roleId } from '../../utils/auth';

import styles from './styles.module.scss';
import { getCurrencyDataRequest } from '../Withdrawal/redux/actions';

const RevenueSummary = (props) => {
  const dispatch = useDispatch();
  const role_id = encryptStorage.getItem(roleId);

  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(5);
  const [page, setPage] = useState(1);

  const propState = useSelector((state) => ({
    revenueData: state.revenueSummary?.data || {},
    currencyData: state.withdrawal?.currencyData || {},
    loading: state.revenueSummary?.isuserRevenue,
    errorMsg: state.revenueSummary?.error?.message || "",
  }));

  useEffect(() => {
    if (firstInit) {
      dispatch(getRevenueRequest({ page, per_page: perPage }));
      dispatch(getCurrencyDataRequest());
      setFirstInit(false);
    }
  }, [dispatch, firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page when rows per page changes
  };

  const columns = [
    {
      name: 'User',
      selector: row => `${row?.user_first_name || '-'} ${row?.user_last_name || '-'}`,
      cell: (row) => (
        <p>{row?.user_first_name || '-'} {row?.user_last_name || '-'}</p>
      ),
      sortable: true,
      maxWidth: '150px',
    },
    {
      name: 'Company',
      selector: row => row?.company_name || '-',
      cell: (row) => (
        <p>{row?.company_name || '-'}</p>
      ),
      maxWidth: '150px',
    },
    {
      name: 'Review Site',
      selector: row => row?.review_site_name || '-',
      cell: (row) => (
        <p>{row?.review_site_name || '-'}</p>
      ),
      maxWidth: '150px',
    },
    {
      name: 'Review Content',
      selector: (row) => row?.review_content || "",
      cell: (row) => (
        <div
          style={{ paddingBottom: '10px', paddingTop: '10px' }}
          className={styles["custom-cell"]}
        >
          {row?.review_content || ""}
        </div>
      ),
      minWidth: '330px',
    },
    {
      name: 'Status',
      maxWidth: '130px',
      cell: (row) => {
        const statusStr = row?.earnings_tbl_amount_status;
        return (
        <div className="btn-area">
          <span
            className="status-btn"
            data-review-status={statusStr}
          >
            {statusStr ? statusStr.charAt(0).toUpperCase() + statusStr.slice(1) : ""}
          </span>
        </div>
      )}
    },
    {
      name: 'User Earning',
      maxWidth: '130px',
      selector: (row) =>
        row?.earnings_tbl_amount_status?.toLowerCase() === "approved"
          ? row?.earnings_tbl_earn_amount
          : row?.earnings_tbl_deducted_amount,
      cell: (row) => {
        const isApproved = row?.earnings_tbl_amount_status?.toLowerCase() === "approved";
        return (
          <p className={isApproved ? "text-success" : "text-danger"}>
            {propState.currencyData?.currency_symbol || "$"}{" "}
            {isApproved
              ? row?.earnings_tbl_earn_amount || 0
              : row?.earnings_tbl_deducted_amount || 0}
          </p>
        );
      },
    },
    {
      name: 'Commision',
      maxWidth: '130px',
      selector: (row) => (row?.commission_amount || 0),
      cell: (row) => (
        <p
          className={
            row?.earnings_tbl_amount_status?.toLowerCase() === "approved"
              ? "text-success"
              : "text-danger"
          }
        >
          {propState.currencyData?.currency_symbol}{" "}
          {row?.tl_subtl_amount || 0}
        </p>
      ),
      omit: !(role_id === 2 || role_id === 5)
    }
  ];

  return (
    <>
      <div className='teamleader-dashboard dashboard-fun-fact-area'>
        <div className="row">
          <StatsBlock
            icon="fa-regular fa-hourglass-half"
            label="Pending Reviews"
            count={propState.revenueData?.pending || 0}
          />
          <StatsBlock
            icon="fa-regular fa-thumbs-up"
            label="Approved Reviews"
            count={propState.revenueData?.approved || 0}
          />
          <StatsBlock
            icon="fa fa-xmark"
            label="Rejected Reviews"
            count={propState.revenueData?.rejected || 0}
          />
          <StatsBlock
            icon="flaticon-coin"
            label="Wallet Balance"
            count={`${propState.currencyData?.currency_symbol || ""} ${propState.revenueData?.totalAmount || 0}`}
            additionalClass="wallet-stats-box"
          />
        </div>
      </div>
      <div className={`d-flex p-3 mb-4 header-box`}>
        <h4 className={`col-md-12 header-text`}>
          Revenue Summary
        </h4>
      </div>
      <DataTable
        columns={columns}
        data={propState.revenueData?.data || []}
        theme="solarized"
        fixedHeader
        pagination
        paginationServer
        paginationPerPage={perPage}
        paginationTotalRows={propState.revenueData?.total}
        paginationRowsPerPageOptions={[5, 15, 25, 50, 100]}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        responsive
      />
    </>
  )
}

export default RevenueSummary;