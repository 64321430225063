let baseUrl = "";

switch (window.location.hostname) {
  // LOCAL
  case "localhost":
  case "192.168.2.45":
    baseUrl = process.env.REACT_APP_API_BASE_URL || "http://192.168.2.44:8003/";
    break;

  // STAGE
  case "rmp.invobilling.com":
  case "rmp-admin.invobilling.com":
    baseUrl = "https://rmp-api.invobilling.com/";
    break;

  // TESTING STAGE
  case "test.markfeeds.com":
  case "test-admin.markfeeds.com":
    baseUrl = "https://test-api.markfeeds.com/";
    break;

  // Live
  case "markfeeds.com":
  case "admin.markfeeds.com":
    baseUrl = "https://test-api.markfeeds.com/";
    break;

  default:
    baseUrl = process.env.REACT_APP_API_BASE_URL;
    break;
}

export const BACKEND_URL = baseUrl;
export const API_BASE_URL = `${baseUrl}api/`;
