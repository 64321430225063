import { call, fork, put, takeLatest } from "redux-saga/effects";
import { postRequestAPI } from "../../../global/api";
import { ActionTypes } from "./actions";
import { defaultErrorMsg } from "../../../utils/conts";

function* getTLTargetsReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'team-leader/get-pending-company-list', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_TL_TARGETS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_TL_TARGETS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_TL_TARGETS_FAILURE, error: defaultErrorMsg });
  }
}

function* getCompanyReviewSitesReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'team-leader/get-pending-reviewsite-list', data: action.payload });

    if (resData?.status === 'success') {
      yield put({
        type: ActionTypes.GET_COMPANY_REVIEW_SITES_SUCCESS,
        payload: {
          ...resData,
          company_id: action.payload?.company_id
        }
      });
    } else {
      yield put({ type: ActionTypes.GET_COMPANY_REVIEW_SITES_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_COMPANY_REVIEW_SITES_FAILURE, error: defaultErrorMsg });
  }
}

function* assignSubTLTargetsReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'admin/teamleader-assign-review', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.ASSIGN_SUBTL_TARGETS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.ASSIGN_SUBTL_TARGETS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.ASSIGN_SUBTL_TARGETS_FAILURE, error: defaultErrorMsg });
  }
}

function* sendMailToUsersReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'team-leader/user-review-mail', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.SEND_MAIL_TO_USERS_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.SEND_MAIL_TO_USERS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.SEND_MAIL_TO_USERS_FAILURE, error: defaultErrorMsg });
  }
}

function* watchTeamLeaderTargetsSaga() {
  yield takeLatest(ActionTypes.GET_TL_TARGETS, getTLTargetsReq);
  yield takeLatest(ActionTypes.GET_COMPANY_REVIEW_SITES, getCompanyReviewSitesReq);
  yield takeLatest(ActionTypes.ASSIGN_SUBTL_TARGETS, assignSubTLTargetsReq);
  yield takeLatest(ActionTypes.SEND_MAIL_TO_USERS, sendMailToUsersReq);
}

function* TeamLeaderTargetsSaga() {
  yield fork(watchTeamLeaderTargetsSaga);
}

export default TeamLeaderTargetsSaga;