import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getwebsiteRequest } from '../companylist/companylistActions';
import { getCurrenciesReq, getSiteCurrencyAmount, setSubTLCommissionReq } from './redux/actions';

const SetCommission = ({ subTlData, onClose }) => {
  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getwebsiteRequest());
    dispatch(getCurrenciesReq());
  }, []);

  const selectedSite = watch('review_site_id');
  const selectedCurrency = watch('currency_id');

  const propState = useSelector((state) => ({
    reviewSites: state.companylist?.websites || [],
    currencyArr: state.teamleaderSubTL?.currencies,
    setAmtLoader: state.teamleaderSubTL?.setAmtLoader,
    siteCurrencyAmount: state.teamleaderSubTL?.siteCurrencyAmount?.find(
      (item) =>
        Number(item?.per_review_price_tbl_review_site_id) === Number(selectedSite) &&
        Number(item?.per_review_price_tbl_currency_id) === Number(selectedCurrency) &&
        Number(item?.per_review_price_tbl_role_id) === 5
    )?.per_review_price_tbl_amount,
  }));

  useEffect(() => {
    // set amount
    if (propState.siteCurrencyAmount) {
      setValue('commission_amount', propState.siteCurrencyAmount);
    }
  }, [propState.siteCurrencyAmount]);

  // get amount of site based on currency
  useEffect(() => {
    if (selectedSite && selectedCurrency) {
      dispatch(getSiteCurrencyAmount({
        currency_id: selectedCurrency,
        review_site_id: selectedSite
      }));
    }
  }, [selectedSite, selectedCurrency]);

  // set sub-TL commission
  const onSubmit = (data) => {
    for (const key in data) {
      if (data.hasOwnProperty(key)) {
        data[key] = Number(data[key]);
        data["role_id"] = 5;
      }
    }

    dispatch(setSubTLCommissionReq(data));
  };

  // set notification message
  useEffect(() => {

  }, [propState]);

  return (
    <>
      <div
        className="modal fade show d-block"
        id="setCommissionModal"
        tabIndex="-1"
        aria-labelledby="setCommissionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className='mb-0 text-light'>
                {subTlData?.user_first_name || ""} {subTlData?.user_last_name || ""} Commision
              </h4>
              <button
                type="button"
                className="btn-close btn-close-white text-light"
                onClick={onClose}
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <div className="mb-3">
                <div className="form-floating">
                  <select
                    className="form-select"
                    {...register("review_site_id", {
                      required: 'Review site is required.'
                    })}
                    id="site"
                    aria-label="Select Review Site"
                  >
                    <option value="">Select Review Site</option>
                    {propState.reviewSites?.map((site) => (
                      <option key={site?.id} value={site?.id}>
                        {site?.review_site_tbl_name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="site">Review Site</label>
                </div>
                {errors?.review_site_id && <span className="text-danger">{errors.review_site_id.message}</span>}
              </div>
              <div className="mb-3">
                <div className="form-floating">
                  <select
                    className="form-select"
                    {...register("currency_id", {
                      required: "Currency is required."
                    })}
                    id="currency"
                    disabled={!selectedSite}
                  >
                    <option value="">Select Currency</option>
                    {propState.currencyArr?.map((currency) => (
                      <option key={currency?.id} value={currency?.id}>
                        {currency?.currency_name}
                      </option>
                    ))}
                  </select>
                  <label htmlFor="currency">Currency</label>
                  {errors?.currency_id && <span className="text-danger">{errors.currency_id.message}</span>}
                </div>
              </div>
              <div>
                <div className="input-group has-validation">
                  <span
                    className="input-group-text text-dark justify-content-center"
                    style={{ width: "50px" }}
                  >
                    {(propState.currencyArr?.length &&
                      propState.currencyArr.find(
                        (cur) => cur.id === Number(watch("currency_id"))
                      )?.currency_symbol) ||
                      "-"}
                  </span>
                  <div className="form-floating">
                    <input
                      type="number"
                      id="commission"
                      className={`form-control ${errors?.commission_amount ? "is-invalid" : ""}`}
                      placeholder="Commission amount"
                      {...register("commission_amount", {
                        required: 'Commission amount is required.',
                        validate: {
                          positive: value => parseFloat(value) > 0 || 'Commission must be greater than zero.',
                          maxLimit: value => parseFloat(value) < 1000000 || 'Commission must be less than 1,000,000.'
                        }
                      })}
                      min={0}
                      max={1000000}
                    />
                    <label htmlFor="commission">Commission amount</label>
                  </div>
                  {errors?.commission_amount && (
                    <span className="text-danger invalid-feedback">{errors.commission_amount?.message}</span>
                  )}
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="blue-btn rounded"
                disabled={propState.setAmtLoader}
                onClick={handleSubmit(onSubmit)}
              >
                {!propState.setAmtLoader ? (
                  "Save"
                ) : (
                  <div className="spinner-border spinner-border-sm"></div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-backdrop fade show" onClick={onClose}></div>
    </>
  )
}

export default SetCommission;