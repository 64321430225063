import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import DataTable from 'react-data-table-component';
import * as ReviewslistingActions from './reviewsActions';
import { COMPANYLIST } from '../../global/routes';
import styles from './styles.module.scss';
import { useNavigate } from 'react-router-dom';

/**
 * @author      Nandani.V.Patel
 * @date        22th Oct 2024
 * @description UI Implementation of User Reviews
 * @param
 * @response 
**/

const Reviews = (props) => {
  const { reviews, actions } = props;

  const navigate = useNavigate();

  const [firstInit, setFirstInit] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [review, setreview] = useState('');

  useEffect(() => {
    if (firstInit) {
      actions.reviewlistingRequest({ page, per_page: perPage });
      setFirstInit(false);
    }
  }, [firstInit, page, perPage]);

  useEffect(() => {
    setFirstInit(true);
  }, [page, perPage]);

  useEffect(() => {
    if (typeof reviews?.data !== 'undefined' && !reviews?.isReview) {
      setreview(reviews?.data?.data);
    }
  }, [reviews?.data, reviews?.isReview]);

  const handlePageChange = (page) => {
    setPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setPerPage(newPerPage);
    setPage(1); // Reset to first page when rows per page changes
  };

  const customStyles = {
    headCells: {
      style: {
        verticalAlign: 'middle',
        whiteSpace: 'nowrap',
        padding: '18px 10px',
        fontSize: '18px',
        fontWeight: '600'
      }
    },
    cells: {
      style: {
        verticalAlign: "middle",
        padding: "25px 15px",
        fontWeight: "500",
        fontSize: "16px"
      }
    }
  };

  const columns = [
    {
      name: 'Review site',
      selector: (row) => row?.review_site_tbl_name,
      sortable: true,
      cell: (row) => (
        <>
          <img
            src={row?.review_site_logo || ""}
            alt={row?.review_site_tbl_name}
            width="50px"
            height="50px"
          />
          <span className='ps-3'>{row?.review_site_tbl_name || ""}</span>
        </>
      ),
      minWidth: '50px',
      maxWidth: '230px',
    },
    {
      name: 'Company Name',
      selector: (row) => row?.company_tbl_name,
      sortable: true,
      cell: (row) => row?.company_tbl_name || '-',
      minWidth: '77px',
      maxWidth: '200px',
    },
    {
      name: 'Review Content',
      selector: row => row?.reviews_content || "",
      cell: (row) => (
        <div className="custom-cell"  >
          {row?.reviews_content || "-"}
        </div>
      ),
    },
    {
      name: "Date of Review",
      selector: (row) => row?.created_at,
      cell: (row) => (
        new Date(row?.created_at).toLocaleDateString('en-GB', {
          day: '2-digit',
          month: 'short',
          year: 'numeric'
        })
      ),
      sortable: true,
      width: '130px',
    },
    {
      name: 'Status',
      selector: row => row?.user_reviews_ai_check_status_name || '-',
      cell: (row) => {
        return (
          <div className="btn-area">
            <span
              className="status-btn"
              data-review-status={row?.user_reviews_ai_check_status_name.toLowerCase()}
            >
              {row?.user_reviews_ai_check_status_name}
            </span>
          </div>
        )
      },
      maxWidth: '150px',
    },
  ];

  return (
    <>
      <div className={`d-flex justify-content-between align-items-center p-3 mb-4 header-box`}>
        <h4 className={`col-md-10 header-text`}>
          My Reviews
        </h4>
        <button
          className={`btn col-md-2 add-btn ${styles.addbtn}`}
          onClick={() => window.open(COMPANYLIST, '_blank')}
        >
          Add Reviews
        </button>
      </div>
      <div style={{ width: '100%' }}>
        <DataTable
          columns={columns}
          data={review}
          // defaultSortFieldId="Date of Review"
          theme="solarized"
          customStyles={customStyles}
          fixedHeader
          pagination
          paginationServer
          paginationTotalRows={reviews?.data?.total}
          paginationRowsPerPageOptions={[10, 15, 20, 25, 30]}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handlePerPageChange}
          responsive
        />
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  reviews: state.reviews,
});

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(ReviewslistingActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Reviews);
