import { ActionTypes } from './loginActions';

/**
 * @author      Nandani.V.Patel
 * @date        09 Oct 2024
 * @description Login Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Login api response  
**/

const defaultState = {
  isLogin: false,
  data: undefined,
  keyName: "",
  keys: {}, // Stores keys
  isApiKey: false,
  error: undefined,
};

const signInReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.LOGIN_REQUEST:
      return {
        ...state,
        data: undefined,
        isLogin: true,
      };
    case ActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        data: action.data,
        isLogin: false,
      };
    case ActionTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLogin: false,
        data: undefined,
        error: action.error,
      };
    case ActionTypes.GOOGLE_LOGIN_REQUEST:
      return {
        ...state,
        googleLogindata: undefined,
        isGoogleLogin: true,
      };
    case ActionTypes.GOOGLE_LOGIN_SUCCESS:
      return {
        ...state,
        googleLogindata: action.data,
        isGoogleLogin: false,
      };
    case ActionTypes.GOOGLE_LOGIN_FAILURE:
      return {
        ...state,
        isGoogleLogin: false,
        googleLogindata: undefined,
        error: action.error,
      };
    case ActionTypes.FACEBOOK_LOGIN_REQUEST:
      return {
        ...state,
        facebookLogindata: undefined,
        isfacebookLogin: true,
      };
    case ActionTypes.FACEBOOK_LOGIN_SUCCESS:
      return {
        ...state,
        facebookLogindata: action.data,
        isfacebookLogin: false,
      };
    case ActionTypes.FACEBOOK_LOGIN_FAILURE:
      return {
        ...state,
        isfacebookLogin: false,
        facebookLogindata: undefined,
        error: action.error,
      };
    case ActionTypes.LINKEDIN_LOGIN_REQUEST:
      return {
        ...state,
        linkedinLogindata: undefined,
        isLinkedinLogin: true,
      };
    case ActionTypes.LINKEDIN_LOGIN_SUCCESS:
      return {
        ...state,
        linkedinLogindata: action.data,
        isLinkedinLogin: false,
      };
    case ActionTypes.LINKEDIN_LOGIN_FAILURE:
      return {
        ...state,
        isLinkedinLogin: false,
        linkedinLogindata: undefined,
        error: action.error,
      };
    case ActionTypes.GET_API_KEY_REQUEST:
      return {
        ...state,
        isApiKey: true,
        keyName: action.payload.key
      }
    case ActionTypes.GET_API_KEY_SUCCESS:
      console.log("state.keyName:", state.keyName);
      console.log("keys", state.keys);
      return {
        ...state,
        // keys: {
        //   ...state.keys,
        //   [state.keyName]: action.data.encrypted_value,
        // },
        isApiKey: false,
      }
    case ActionTypes.GET_API_KEY_FAILURE:
      return {
        ...state,
        isApiKey: false,
        error: action.error
      }
    default:
      return state;
  }
}

export default signInReducer;
