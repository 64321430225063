import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { userNavigationData, teamleaderNavigationData, companiesNavigationData, employeeNavigationData } from './menu';
import { tokenkey, encryptStorage, userId, roleId, roleName, rememberMeKey, identifier } from "../../utils/auth";
import { SIGNIN } from '../../global/routes';
import * as LogoutActions from '../../pages/logout/logoutActions';
import * as LoginActions from '../../pages/login/loginActions';
import * as profileActions from '../../pages/profile/profileActions';
import * as companyprofileActions from '../../pages/companyPanel/redux/actions';

/**
 * Sidebar and conditional based load sidebar based on roleid
**/

const Sidebar = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userRoleId = encryptStorage.getItem(roleId);
  let token = localStorage.getItem(tokenkey);
  const userlogout = useSelector((state) => state?.logout);
  const profileData = useSelector((state) => state.profile?.userdata?.data);

  // Redirect if userRoleId is not set
  useEffect(() => {
    if (!userRoleId) {
      navigate(SIGNIN);
    }
  }, [userRoleId]);

  useEffect(() => {
    if (userlogout?.data?.status === 'success') {
      localStorage.removeItem(tokenkey);
      encryptStorage.removeItem(roleId);
      encryptStorage.removeItem(roleName);
      encryptStorage.removeItem(userId);
      encryptStorage.getItem(rememberMeKey) !== true && encryptStorage.removeItem(identifier);

      window.location.href = SIGNIN;
    }
  }, [userlogout?.data]);

  const handleLogout = () => {
    dispatch(LogoutActions.logoutRequest({ token }));
    dispatch({ type: LoginActions.ActionTypes.LOGIN_SUCCESS, data: undefined });
    dispatch({ type: profileActions.ActionTypes.GET_USER_DETAILS_SUCCESS, data: undefined });
    dispatch({ type: companyprofileActions.ActionTypes.GET_COMPANY_DETAILS_SUCCESS, data: undefined });
  };

  const handleMobileSidebar = () => {
    const ele = document.getElementsByClassName('sidemenu-area')[0];
    ele?.classList?.remove("active-sidemenu-area");
  };

  // Navigation data mapping
  const navigationMap = {
    1: userNavigationData, // User
    2: teamleaderNavigationData, // Team leader
    3: companiesNavigationData, // Company
    // 4: employeeNavigationData, // Employee
    4: employeeNavigationData.filter((item) =>
      profileData?.employee_type?.toLowerCase() === 'target' || item.title !== 'Withdrawal'
    ), // Employee -- hide Withdrawal section if target employee
    5: teamleaderNavigationData.filter((item) => !item.hideInSubTL), // Sub-TL
  };

  // Render Navigation Items
  const renderNavigationItems = (data) =>
    data.map((item, index) => (
      !item.hideSection && (
        <li
          key={index}
          className={classNames('nav-item', { active: location.pathname === item?.href })}
        >
          {item?.action === 'logout' ? (
            <a onClick={handleLogout} className="nav-link" style={{ cursor: 'pointer' }}>
              <span className="icon">{item.icon}</span>
              <span className="menu-title">{item.title}</span>
            </a>
          ) : (
            <Link to={item.href} className="nav-link">
              <span className="icon">{item.icon}</span>
              <span className="menu-title">{item.title}</span>
            </Link>
          )}
        </li>
      )
    ));

  return (
    // className="sidemenu-area active-sidemenu-area"
    <div className="sidemenu-area">
      <div className="sidemenu-header">
        <div className="responsive-burger-menu d-block" onClick={handleMobileSidebar}>
          <i className="fa-solid fa-xmark" />
        </div>
      </div>
      <div className="sidemenu-body">
        <ul className="sidemenu-nav metisMenu h-100" id="sidemenu-nav" data-simplebar="">
          {renderNavigationItems(navigationMap?.[userRoleId] || [])}
        </ul>
      </div>
    </div>
  );
};

export default Sidebar;