import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { getUserBankDetailsRequest, withdrawalRequest } from './redux/actions';
import AddBankModal from '../../component/addBankModal';

import styles from "./styles.module.scss";

const WithdrawalForm = () => {
  const dispatch = useDispatch();
  const { register, handleSubmit, resetField, formState: { errors } } = useForm();

  const [showModal, setShowModal] = useState(false);

  const propState = useSelector(({ withdrawal }) => ({
    bankDetails: withdrawal.bankDetails,
    addBankLoader: withdrawal.isStoreBankdata,
    withdrawaldata: withdrawal.withdrawaldata,
    iswithdrawal: withdrawal.iswithdrawal,
    currencyData: withdrawal.currencyData,
    isCurrencyData: withdrawal.isCurrencyData,
    successMsg: withdrawal.successMsg
  }));

  useEffect(() => {
    dispatch(getUserBankDetailsRequest());
  }, [dispatch]);

  const onSubmit = (data) => {
    const payload = {
      user_bank_detail_id: parseInt(data.user_bank_detail_id),
      withdrawal_amount: Number(data.withdrawal_amount),
      currency_id: propState.currencyData?.currency_id
    };

    dispatch(withdrawalRequest(payload));
  };

  useEffect(() => {
    if (propState.withdrawaldata?.status === "success") {
      resetField('user_bank_detail_id');
      resetField('withdrawal_amount');
    }
  }, [propState.withdrawaldata]);

  return (
    <>
      <div className="details-form-content pt-0">
        <div className="form-content">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label>Bank Name</label>
                <select
                  {...register("user_bank_detail_id", {
                    required: 'Bank Name is required.',
                  })}
                  className={`${styles.dropdownstyle} ${'form-select'}`}
                >
                  <option selected value="">None</option>
                  {propState.bankDetails?.length > 0 && propState.bankDetails?.map((bank) => (
                    <option key={bank?.id} value={bank?.id}>
                      {bank?.bank_name}
                    </option>
                  ))}
                </select>
                {errors?.user_bank_detail_id && (
                  <span className="text-danger">{errors.user_bank_detail_id.message}</span>
                )}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="form-group">
                <label></label>
                <div style={{ padding: '0.70rem 0.75rem' }}>
                  <button
                    className={`${styles.iconStyle} option-btn d-inline-block`}
                    data-bs-toggle="tooltip"
                    data-bs-placement="top"
                    title="Add New Bank"
                    onClick={() => setShowModal(true)}
                    type="button"
                  >
                    <i className="fa-solid fa-plus"></i>
                  </button>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className='form-group'>
                <label htmlFor='withdraw_amount'>Amount</label>
                <div className="input-group">
                  <span class="input-group-text">{propState.currencyData?.currency_symbol || "-"}</span>
                  <input
                    {...register("withdrawal_amount", {
                      required: 'Amount is required.',
                      pattern: {
                        value: /^[0-9]*\.?[0-9]+$/,
                        message: 'Amount must be a valid number.'
                      },
                      validate: {
                        positive: (value) => parseFloat(value) >= 0 || 'Amount must be a positive number.',
                      }
                    })}
                    type="number"
                    className="form-control"
                    placeholder="Amount"
                    id='withdraw_amount'
                  />
                </div>
                {errors?.withdrawal_amount && (
                  <span className="text-danger">{errors.withdrawal_amount.message}</span>
                )}
              </div>
            </div>
          </div>
          <button
            type="submit"
            className="rounded"
            onClick={handleSubmit(onSubmit)}
            disabled={propState.iswithdrawal}
          >
            {propState.iswithdrawal ? <span className='spinner-border spinner-border-sm'></span> : "Request Withdrawal"}
          </button>
        </div>
      </div>
      {showModal && <AddBankModal onClose={() => setShowModal(false)} loader={propState.addBankLoader} />}
    </>
  )
}

export default WithdrawalForm;