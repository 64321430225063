import { ActionTypes } from './revenueActions';

/**
 * @description Revenue Summary Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store user Revenue api response  
**/

const defaultState = {
  data: {},
  isuserRevenue: false,
  error: "",
};

const RevenueSummaryReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.GET_REVENUE_REQUEST:
      return {
        ...state,
        isuserRevenue: true,
      };
    case ActionTypes.GET_REVENUE_SUCCESS:
      return {
        ...state,
        data: action.data,
        isuserRevenue: false,
      };
    case ActionTypes.GET_REVENUE_FAILURE:
      return {
        ...state,
        isuserRevenue: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default RevenueSummaryReducer;