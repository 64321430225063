import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import * as UserDashboardCountActions from '../dashboard/dashboardcountActions';
import StatsBlock from '../dashboard/statsBlock';

const UserDashboard = () => {
  const usercounts = useSelector((state) => state?.userdashboardcount?.dashboardusercount);
  const [firstInit, setFirstInit] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (firstInit) {
      dispatch(UserDashboardCountActions.getUserdashboardcount());
      setFirstInit(false);
    }
  }, [firstInit]);

  useEffect(() => {
    setFirstInit(true);
  }, []);

  return (
    <>
      <div className="user-dashboard dashboard-fun-fact-area">
        <div className="row">
          <StatsBlock
            icon="fa fa-user-tie"
            label={usercounts?.teamLeader?.tl_role ? `Under ${usercounts?.teamLeader?.tl_role}` : "No TL/sub-TL assigned"}
            count={`${usercounts?.teamLeader?.tl_first_name || "-"} ${usercounts?.teamLeader?.tl_last_name || ""}`}
          />
          <StatsBlock
            icon="fa-regular fa-star"
            label="Total reviews"
            count={usercounts?.review_count || 0}
          />
          <StatsBlock
            icon="fa fa-xmark"
            label="Rejected reviews"
            count={usercounts?.rejected || 0}
          />
          <StatsBlock
            icon="flaticon-coin"
            label="Wallet Balance"
            count={`${usercounts?.country?.currency_symbol || ""} ${usercounts?.totalAmount || 0}`}
            additionalClass="wallet-stats-box"
          />
        </div>
      </div>
    </>
  )
}
export default UserDashboard;
