/**
 * @description ActionTypes and ActionCreator Function for Revenue Summary.
 * @param
 * @response      
**/

export const ActionTypes = {
  GET_REVENUE_REQUEST: 'GET_REVENUE_REQUEST',
  GET_REVENUE_SUCCESS: 'GET_REVENUE_SUCCESS',
  GET_REVENUE_FAILURE: 'GET_REVENUE_FAILURE',
};

export const getRevenueRequest = (payload) => {
  return {
    type: ActionTypes.GET_REVENUE_REQUEST,
    payload,
  };
};