import { ActionTypes } from './comapnyRegisterActions';

/**
 * @author      Nandani.V.Patel
 * @date        14 Oct 2024
 * @description Registration Reducer.
 * @param       takes default and previous state value
 * @response    update a state value and store Registartion api response  
**/

const defaultState = {
  isCompanyRegistration: false,
  error: "",
  successMsg: ""
};

const comapnySignUpReducer = (state = defaultState, action) => {
  switch (action.type) {
    case ActionTypes.COMPANY_REGISTRATION_REQUEST:
      return {
        ...state,
        isCompanyRegistration: true,
      };
    case ActionTypes.COMPANY_REGISTRATION_SUCCESS:
      return {
        ...state,
        data: action.data,
        successMsg: action.data?.message,
        isCompanyRegistration: false,
      };
    case ActionTypes.COMPANY_REGISTRATION_FAILURE:
      return {
        ...state,
        isCompanyRegistration: false,
        error: action.error,
      };
    case ActionTypes.GET_COUNTRIES_REQUEST:
      return {
        ...state,
        iscountries: true,
      };
    case ActionTypes.GET_COUNTRIES_SUCCESS:
      return {
        ...state,
        countries: action.data,
        iscountries: false,
      };
    case ActionTypes.GET_COUNTRIES_FAILURE:
      return {
        ...state,
        iscountries: false,
        error: action.error,
      };
    default:
      return state;
  }
}

export default comapnySignUpReducer;