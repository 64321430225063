import { call, fork, put, takeLatest } from "redux-saga/effects";
import { ActionTypes } from "./footerActions";
import { getRequestAPI, postRequestAPI } from "../../global/api";
import { defaultErrorMsg } from "../../utils/conts";

function* fetchCmsPagesSaga() {
  try {
    const { data: resData } = yield call(getRequestAPI, { url: 'get-active-cms-pages' });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.FETCH_CMS_PAGES_SUCCESS, data: resData.data });
    } else {
      yield put({ type: ActionTypes.FETCH_CMS_PAGES_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.FETCH_CMS_PAGES_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* getPageBySlugSaga(action) {
  try {
    const { data: resData } = yield call(getRequestAPI, { url: `get-cms-pages/${action.payload.slug}` });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_PAGE_BY_SLUG_SUCCESS, data: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_PAGE_BY_SLUG_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_PAGE_BY_SLUG_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* fetchFaqsSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'get-active-faq', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.FETCH_FAQS_SUCCESS, data: resData });
    } else {
      yield put({ type: ActionTypes.FETCH_FAQS_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.FETCH_FAQS_FAILURE, error: { message: defaultErrorMsg } });
  }
}

function* watchFooterSaga() {
  yield takeLatest(ActionTypes.FETCH_CMS_PAGES_REQUEST, fetchCmsPagesSaga);
  yield takeLatest(ActionTypes.GET_PAGE_BY_SLUG_REQUEST, getPageBySlugSaga);
  yield takeLatest(ActionTypes.FETCH_FAQS_REQUEST, fetchFaqsSaga);
}

function* footerSaga() {
  yield fork(watchFooterSaga);
}

export default footerSaga;