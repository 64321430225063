import React from 'react';

/**
 * @author      Nandani.V.Patel
 * @date        25th Oct 2024
 * @description UI Implementation of Full screen Page Loader
 * @param
 * @response 
**/

const Preloader = () => {
  return (
    <div id="preloader-section">
      <div id="preloader">
        <div id="ctn-preloader" className="ctn-preloader">
          <div className="animation-preloader">
            <div className="spinner" />
          </div>
          <div className="loader-section section-left" />
          <div className="loader-section section-right" />
        </div>
      </div>
    </div>
  );
};

export default Preloader;
