/**
 * @author      Nandani.V.Patel
 * @date        09 Oct 2024
 * @description ActionTypes and ActionCreator Function for User Registration Request.
 * @param
 * @response      
**/

export const ActionTypes = {
  REGISTRATION_REQUEST: 'REGISTRATION_REQUEST',
  REGISTRATION_SUCCESS: 'REGISTRATION_SUCCESS',
  REGISTRATION_FAILURE: 'REGISTRATION_FAILURE',

  GET_SOCIAL_PLATFORMS: 'GET_SOCIAL_PLATFORMS',
  GET_SOCIAL_PLATFORMS_SUCCESS: 'GET_SOCIAL_PLATFORMS_SUCCESS',
  GET_SOCIAL_PLATFORMS_FAILURE: 'GET_SOCIAL_PLATFORMS_FAILURE',
};

export const registrationRequest = (registrationData) => {
  return {
    type: ActionTypes.REGISTRATION_REQUEST,
    payload: registrationData,
  };
};

export const getSocialPlatformsReq = () => {
  return {
    type: ActionTypes.GET_SOCIAL_PLATFORMS,
  };
};