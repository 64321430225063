import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { TEAMLEADERUSERS } from '../../global/routes';
import * as RegistrationActions from '../registration/registerActions';
import { NotificationContainer } from '../../component/notificationContainer';
import { notification } from '../../component/notificationContainer/notification';
import Breadcrumb from '../../component/breadcrumbs';
import RegistrationForm from '../../component/registrationForm';
import AddSocialLinks from '../../component/socialLinks';

import styles from './styles.module.scss';

const CreateUser = (props) => {
  const {
    register,
    handleSubmit,
    resetField,
    getValues,
    formState: { errors },
    control
  } = useForm({
    defaultValues: {
      socialPlatform: [{ platform_id: "", link: "" }], // Default row
    },
  });

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const signup = useSelector((state) => state?.signup);
  const [users, setusers] = useState('');

  const breadcrumbs = [
    { name: 'Team Members', link: TEAMLEADERUSERS },
    { name: 'User Registration', link: "" },
  ];

  useEffect(() => {
    if (signup?.data !== undefined && signup?.data?.status === 'success' && signup?.data?.status !== undefined) {
      notification(signup?.data?.message, 'success');
      dispatch({ type: RegistrationActions.ActionTypes.REGISTRATION_SUCCESS, data: undefined });
      resetField('user_first_name');
      resetField('user_last_name');
      resetField('user_email');
      resetField('password');
      resetField('user_phone_number');
      resetField('user_aadhar_card');
      resetField('user_pan_card');
      resetField('password_confirmation');
    }
    if (signup?.error?.message && signup?.isRegistration === false) {
      notification(signup?.error?.message, 'error');
      dispatch({ type: RegistrationActions.ActionTypes.REGISTRATION_FAILURE, error: undefined });
    }
  }, [signup.data, signup.error, signup.isRegistration]);

  const onSubmit = (data) => {
    const formData = {
      ...data,
      user_role: 'user',
      isteamleaderuser: 'true',
    };

    const password = getValues('password');
    const password_confirmation = getValues('password_confirmation');

    if (password !== password_confirmation) {
      notification('Password and Confirm-Password must be same.', 'error');
    } else {
      dispatch(RegistrationActions.registrationRequest(formData));
    }
  }

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleSubmit(onSubmit)();
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyPress);
    return () => document.removeEventListener('keyup', handleKeyPress);
  }, [])

  return (
    <>
      <Breadcrumb breadcrumbs={breadcrumbs} />
      <div className={`d-flex p-3 mb-4 header-box`}>
        <h4 className={`col-md-10 header-text`}>
          User Registration
        </h4>
      </div>
      <div className="my-profile-area">
        <div className="profile-form-content">
          <div className="profile-form">

            <RegistrationForm
              register={register}
              errors={errors}
              getValues={getValues}
              resetField={resetField}
              control={control}
            />

            <div className='socialLinks mb-4'>
              <h3 className="ps-0 pb-3">Connect Your Social Profiles</h3>
              <AddSocialLinks
                register={register}
                errors={errors}
                getValues={getValues}
                control={control}
              />
            </div>

            <button
              type="submit"
              className="blue-btn rounded"
              onClick={handleSubmit(onSubmit)}
              disabled={signup.isRegistration && signup.data !== 'undefiend'}
            >
              {!signup.isRegistration && signup.data !== "undefiend" ? (
                <>Register</>
              ) : (
                <>
                  <div
                    className="spinner-border "
                    style={{ width: "1.2rem", height: "1.2rem" }}
                    role="status"
                  />
                </>
              )}
            </button>
          </div>
        </div>
      </div >
      <NotificationContainer />
    </>
  )
}

export default CreateUser;