import { call, fork, put, takeLatest } from "redux-saga/effects";
import { getRequestAPI, postRequestAPI } from "../../../global/api";
import { ActionTypes } from "./actions";
import { defaultErrorMsg } from "../../../utils/conts";

function* createSubTLSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'register', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.CREATE_SUB_TL_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.CREATE_SUB_TL_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.CREATE_SUB_TL_FAILURE, error: defaultErrorMsg });
  }
}

function* getSubTlReq(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'team-leader/sub-team-leader', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_SUB_TL_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.GET_SUB_TL_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SUB_TL_FAILURE, error: defaultErrorMsg });
  }
}

function* getCurrenciesSaga(action) {
  try {
    const { data: resData } = yield call(getRequestAPI, { url: 'admin/get-currencies', data: action.payload });

    if (resData?.status === 'success' || resData?.data) {
      yield put({ type: ActionTypes.GET_CURRENCIES_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_CURRENCIES_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_CURRENCIES_FAILURE, error: defaultErrorMsg });
  }
}

function* setSubTLCommissionSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'sub-team-leader/commission', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.SET_SUBTL_COMMISSION_SUCCESS, payload: resData });
    } else {
      yield put({ type: ActionTypes.SET_SUBTL_COMMISSION_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.SET_SUBTL_COMMISSION_FAILURE, error: defaultErrorMsg });
  }
}

function* getSiteCurrencyAmountSaga(action) {
  try {
    const { data: resData } = yield call(postRequestAPI, { url: 'admin/get-amount-currency-wise', data: action.payload });

    if (resData?.status === 'success') {
      yield put({ type: ActionTypes.GET_SITE_CURRENCY_AMOUNT_SUCCESS, payload: resData.data });
    } else {
      yield put({ type: ActionTypes.GET_SITE_CURRENCY_AMOUNT_FAILURE, error: resData.message });
    }
  } catch (error) {
    yield put({ type: ActionTypes.GET_SITE_CURRENCY_AMOUNT_FAILURE, error: defaultErrorMsg });
  }
}

function* watchSubTLSaga() {
  yield takeLatest(ActionTypes.CREATE_SUB_TL, createSubTLSaga);
  yield takeLatest(ActionTypes.GET_SUB_TL, getSubTlReq);
  yield takeLatest(ActionTypes.GET_CURRENCIES, getCurrenciesSaga);
  yield takeLatest(ActionTypes.SET_SUBTL_COMMISSION, setSubTLCommissionSaga);
  yield takeLatest(ActionTypes.GET_SITE_CURRENCY_AMOUNT, getSiteCurrencyAmountSaga);
}

function* SubTLSaga() {
  yield fork(watchSubTLSaga);
}

export default SubTLSaga;