export const ActionTypes = {
  GET_LATEST_ORDER: 'GET_LATEST_ORDER',
  GET_LATEST_ORDER_SUCCESS: 'GET_LATEST_ORDER_SUCCESS',
  GET_LATEST_ORDER_FAILURE: 'GET_LATEST_ORDER_FAILURE',

  GET_REVIEW_SITES: "GET_REVIEW_SITES",
  GET_REVIEW_SITES_SUCCESS: "GET_REVIEW_SITES_SUCCESS",
  GET_REVIEW_SITES_FAILURE: "GET_REVIEW_SITES_FAILURE",

  CALC_CHECKOUT_PRICE: 'CALC_CHECKOUT_PRICE',
  CALC_CHECKOUT_PRICE_SUCCESS: 'CALC_CHECKOUT_PRICE_SUCCESS',
  CALC_CHECKOUT_PRICE_FAILURE: 'CALC_CHECKOUT_PRICE_FAILURE',

  GET_ALL_TRANSACTION_HISTORY: 'GET_ALL_TRANSACTION_HISTORY',
  GET_ALL_TRANSACTION_HISTORY_SUCCESS: 'GET_ALL_TRANSACTION_HISTORY_SUCCESS',
  GET_ALL_TRANSACTION_HISTORY_FAILURE: 'GET_ALL_TRANSACTION_HISTORY_FAILURE',

  GET_ORDERS: 'GET_ORDERS',
  GET_ORDERS_SUCCESS: 'GET_ORDERS_SUCCESS',
  GET_ORDERS_FAILURE: 'GET_ORDERS_FAILURE',

  GET_SITES_BY_ORDER: 'GET_SITES_BY_ORDER',
  GET_SITES_BY_ORDER_SUCCESS: 'GET_SITES_BY_ORDER_SUCCESS',
  GET_SITES_BY_ORDER_FAILURE: 'GET_SITES_BY_ORDER_FAILURE',

  GET_PURCHASED_SITE_USER_REVIEWS: 'GET_PURCHASED_SITE_USER_REVIEWS',
  GET_PURCHASED_SITE_USER_REVIEWS_SUCCESS: 'GET_PURCHASED_SITE_USER_REVIEWS_SUCCESS',
  GET_PURCHASED_SITE_USER_REVIEWS_FAILURE: 'GET_PURCHASED_SITE_USER_REVIEWS_FAILURE',

  UPDATE_COMPANY_PROFILE_REQUEST: 'UPDATE_COMPANY_PROFILE_REQUEST',
  UPDATE_COMPANY_PROFILE_SUCCESS: 'UPDATE_COMPANY_PROFILE_SUCCESS',
  UPDATE_COMPANY_PROFILE_FAILURE: 'UPDATE_COMPANY_PROFILE_FAILURE',

  GET_COMPANY_DETAILS_REQUEST: 'GET_COMPANY_DETAILS_REQUEST',
  GET_COMPANY_DETAILS_SUCCESS: 'GET_COMPANY_DETAILS_SUCCESS',
  GET_COMPANY_DETAILS_FAILURE: 'GET_COMPANY_DETAILS_FAILURE',

  PAYMENT_ORDER: 'PAYMENT_ORDER',
  PAYMENT_ORDER_SUCCESS: 'PAYMENT_ORDER_SUCCESS',
  PAYMENT_ORDER_FAILURE: 'PAYMENT_ORDER_FAILURE',

  // Razorpay action types
  CREATE_ORDER_REQUEST: 'CREATE_ORDER_REQUEST',
  CREATE_ORDER_SUCCESS: 'CREATE_ORDER_SUCCESS',
  CREATE_ORDER_FAILURE: 'CREATE_ORDER_FAILURE',

  VERIFY_PAYMENT_REQUEST: 'VERIFY_PAYMENT_REQUEST',
  VERIFY_PAYMENT_SUCCESS: 'VERIFY_PAYMENT_SUCCESS',
  VERIFY_PAYMENT_FAILURE: 'VERIFY_PAYMENT_FAILURE',

  RESET_PAYMENT: 'RESET_PAYMENT',

  // AI prompts action types
  GET_COMPANY_PURCHASED_SITES: 'GET_COMPANY_PURCHASED_SITES',
  GET_COMPANY_PURCHASED_SITES_SUCCESS: 'GET_COMPANY_PURCHASED_SITES_SUCCESS',
  GET_COMPANY_PURCHASED_SITES_FAILURE: 'GET_COMPANY_PURCHASED_SITES_FAILURE',

  STORE_CAMPAIGN_PROMPTS: 'STORE_CAMPAIGN_PROMPTS',
  STORE_CAMPAIGN_PROMPTS_SUCCESS: 'STORE_CAMPAIGN_PROMPTS_SUCCESS',
  STORE_CAMPAIGN_PROMPTS_FAILURE: 'STORE_CAMPAIGN_PROMPTS_FAILURE',

  GET_CAMPAIGN_PROMPTS: 'GET_CAMPAIGN_PROMPTS',
  GET_CAMPAIGN_PROMPTS_SUCCESS: 'GET_CAMPAIGN_PROMPTS_SUCCESS',
  GET_CAMPAIGN_PROMPTS_FAILURE: 'GET_CAMPAIGN_PROMPTS_FAILURE',

  RESET_MESSAGE: 'RESET_MESSAGE',
};

export const getLatestOrder = () => {
  return {
    type: ActionTypes.GET_LATEST_ORDER
  };
}

export const getReviewSites = (payload) => {
  return {
    type: ActionTypes.GET_REVIEW_SITES,
    payload
  };
}

export const calcCheckoutPrice = (payload) => {
  return {
    type: ActionTypes.CALC_CHECKOUT_PRICE,
    payload
  };
}

export const getAllTransactionHistory = (payload) => {
  return {
    type: ActionTypes.GET_ALL_TRANSACTION_HISTORY,
    payload
  };
}

export const getOrders = (payload) => {
  return {
    type: ActionTypes.GET_ORDERS,
    payload
  };
}

export const getSitesByOrder = (payload) => {
  return {
    type: ActionTypes.GET_SITES_BY_ORDER,
    payload
  };
}

export const getPurchasedSiteUserReviews = (payload) => {
  return {
    type: ActionTypes.GET_PURCHASED_SITE_USER_REVIEWS,
    payload
  };
}

export const updateCompanyProfile = (companyProfileData) => {
  return {
    type: ActionTypes.UPDATE_COMPANY_PROFILE_REQUEST,
    payload: companyProfileData,
  };
};

export const getCompanyDetailsRequest = () => {
  return {
    type: ActionTypes.GET_COMPANY_DETAILS_REQUEST
  };
};

export const paymentOrder = (payload) => {
  return {
    type: ActionTypes.PAYMENT_ORDER,
    payload
  };
}

// Razorpay actions
// create order
export const createOrderRequest = (payload) => {
  return {
    type: ActionTypes.CREATE_ORDER_REQUEST,
    payload
  }
};

// verify payment
export const verifyPaymentRequest = (paymentData) => {
  return {
    type: ActionTypes.VERIFY_PAYMENT_REQUEST,
    payload: paymentData
  }
};

// AI prompts actions
export const getCompanyPurchasedSites = (payload) => {
  return {
    type: ActionTypes.GET_COMPANY_PURCHASED_SITES,
    payload,
  };
};

export const storeCampaignPrompts = (payload) => {
  return {
    type: ActionTypes.STORE_CAMPAIGN_PROMPTS,
    payload,
  };
}

export const getCampaignPrompts = (payload) => {
  return {
    type: ActionTypes.GET_CAMPAIGN_PROMPTS,
    payload,
  };
}

export const resetMessage = () => {
  return {
    type: ActionTypes.RESET_MESSAGE,
  };
}