import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import DataTable from 'react-data-table-component';
import ImageModal from '../../component/ImageModal';
import { getWithdrawalHistoryRequest } from './redux/actions';

import styles from "./styles.module.scss";

const WithdrawHistory = () => {
  const dispatch = useDispatch();
  const [openImageModal, setOpenImageModal] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const payload = {
      per_page: rowsPerPage,
      page: currentPage
    };

    dispatch(getWithdrawalHistoryRequest(payload));
  }, [dispatch, rowsPerPage, currentPage]);

  const propState = useSelector(({ withdrawal }) => ({
    withdrawaldata: withdrawal.withdrawaldata,
    withdrawalHistory: withdrawal.withdrawalHistory,
    currencyData: withdrawal?.currencyData || {},
    loading: withdrawal.isHistoryData
  }));

  const handleReasonClick = (imageUrl) => {
    setImageSrc(imageUrl);
    setOpenImageModal(true);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handlePerPageChange = (newPerPage) => {
    setRowsPerPage(newPerPage);
    setCurrentPage(1); // Reset to first page when rows per page changes
  };

  useEffect(() => {
    if (propState.withdrawaldata?.status === 'success') {
      dispatch(getWithdrawalHistoryRequest({ page: 1, per_page: rowsPerPage }));
    }
  }, [propState.withdrawaldata, dispatch, rowsPerPage]);

  const columns = [
    {
      name: 'Bank Name',
      selector: row => row?.bank_name || '',
      cell: row => row?.bank_name || '-',
      sortable: true,
      minWidth: '160px',
    },
    // {
    //   name: 'Account Holder Name',
    //   selector: row => row?.account_holder_name || '-',
    //   sortable: true,
    //   minWidth: '170px',
    // },
    // {
    //   name: 'Account Number',
    //   selector: row => row?.account_number || '-',
    //   minWidth: '170px',
    // },
    {
      name: 'UTR Number',
      selector: row => row?.utr_number || '-',
      minWidth: '130px',
    },
    {
      name: 'Amount',
      sortable: true,
      selector: row => row?.withdrawal_amount || '-',
      cell: row => `${propState.currencyData?.currency_symbol || ""} ${row?.withdrawal_amount || '-'}`,
    },
    {
      name: 'Requested Date',
      selector: row => row?.created_at || '-',
      cell: row => new Date(row?.created_at).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }),
      sortable: true,
    },
    {
      name: 'Approved/Rejected Date',
      selector: row => row?.updated_at || '-',
      cell: row => new Date(row?.updated_at).toLocaleDateString('en-GB', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      }),
      sortable: true,
    },
    {
      name: 'Status',
      minWidth: '140px',
      selector: row => row?.status_text || '-',
      cell: (row) => (
        <div className="btn-area">
          <span
            className="status-btn"
            data-review-status={row?.status_text?.toLowerCase()}
          >
            {row?.status_text}
          </span>
        </div>
      ),
    },
    {
      name: 'Reason',
      center: 'yes',
      maxWidth: '100px',
      cell: (row) => (
        <div className="action-btn">
          <button
            className={`${styles.viewImg} option-btn d-inline-block`}
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="View"
            type="button"
            onClick={() => handleReasonClick(row?.confirmation_image_path || '/assets/images/placeholderImage/50x50.svg')}
          >
            <i className="fa-solid fa-image" />
          </button>
        </div>
      ),
    },
  ];

  return (
    <>
      <DataTable
        className='withdraw-history-table'
        columns={columns}
        data={propState.withdrawalHistory?.data || []}
        pagination
        paginationServer
        paginationPerPage={rowsPerPage}
        paginationTotalRows={propState.withdrawalHistory?.total || 0}
        paginationRowsPerPageOptions={[5, 15, 25, 50]}
        paginationComponentOptions={{
          rowsPerPageText: "Orders per page:",
        }}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handlePerPageChange}
        customStyles={{
          headCells: {
            style: {
              wordBreak: 'break-word',  // Ensures text breaks at long words
              whiteSpace: 'normal',    // Allows wrapping of text
              textAlign: 'center',     // Optional: centers the text
              overflowWrap: 'break-word', // For older browser compatibility
            }
          },

        }}
        progressPending={propState.loading}
        fixedHeader
        responsive
      />
      {openImageModal && <ImageModal onClose={() => setOpenImageModal(false)} imageSrc={imageSrc} />}
    </>
  )
}

export default WithdrawHistory;